import React, { useEffect, useRef, useState } from "react";

import _ from "lodash";
import * as yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import Select from "react-select";
import ReactQuill from "react-quill";
import DatePicker from "react-date-picker";
import { PDFObject } from "react-pdfobject";
import { useNavigate } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import {
  Accordion,
  Badge,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Ratio,
  Row,
  Spinner,
  useAccordionButton
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronRight,
  faEdit,
  faFileDownload,
  faFileSignature,
  faSave
} from "@fortawesome/free-solid-svg-icons";

import { useSetDoc } from "../../hooks/useSetDoc";
import { useGetDoc } from "../../hooks/useGetDoc";
import styles from "./SellingContract.module.css";
import ProgressStep from "../../components/ProgressStep";
import { useAuthContext } from "../../hooks/useAuthContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import { useSellingContractPdf } from "../../hooks/useSellingContractPdf";
import {
  numberOnlySchema,
  stringNumberMixedSchema,
  stringOnlySchema
} from "../../utils/InputSchema";
import {
  cat_breed,
  cat_color,
  dog_breed,
  dog_color,
  pedigree_issue_org_names_dog,
  pedigree_issue_org_names_cat
} from "../../utils/CatDogData";

import "react-quill/dist/quill.snow.css";

function SellingContract() {
  const { userData } = useUserDetailsContext();
  const { user } = useAuthContext();
  const { firebaseGetDoc, isPending: isPending5, error: error5 } = useGetDoc();
  const { firebaseSetDoc, isPending: isPending, error: error } = useSetDoc();
  const {
    error: errorPDF,
    isPending: isPendingPDF,
    pdfMakerFn
  } = useSellingContractPdf();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const data = JSON.parse(localStorage.getItem("mcData")) || false;

  const userInfo = JSON.parse(localStorage.getItem("userInfo")) || false;

  const mcDataAnimalTransfer =
    JSON.parse(localStorage.getItem("mcDataAnimalTransfer")) || false;

  const [sellingContractPage, setSellingContractPage] = useState("");
  const [sellingContractPageEdit, setSellingContractPageEdit] = useState(false);
  const [pdfURL, setPdfURL] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalSignShow, setModalSignShow] = useState(false);

  const [pdfvalues, setPdfvalues] = useState({});

  const sellingContractData =
    JSON.parse(localStorage.getItem("sellingContractData")) || false;

  const sigCanvas = useRef();
  const sigDiv = useRef();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      boxShadow: "none"
    }),
    menu: (provided) => ({ ...provided, zIndex: 3 })
  };

  //this is the config for rich text editor
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5] }],
      ["bold", "italic", "underline", "blockquote"],
      // [
      //   { list: "ordered" },
      //   { list: "bullet" },
      //   { indent: "-1" },
      //   { indent: "+1" }
      // ],
      [{ color: [] }, { background: [] }]
    ]
  };

  const SectionErrorMgs = () => {
    return (
      <Badge className="ms-2" bg="danger">
        エラー
      </Badge>
    );
  };

  let initialValues = sellingContractData || {
    medical_history: "",
    medical_history_details: "",
    store_owner: "",
    other_owner: "",
    weight_from: data.weight_from || "",
    weight_to: data.weight_to || "",
    height_from: data.height_from || "",
    height_to: data.height_to || "",
    life_expectancy_from: "",
    life_expectancy_to: "",
    infertility_castration: "",
    genetic_disease: "",
    feeding_amount: "",
    deworming_medicine_date_1: "",
    deworming_medicine_1: "",
    deworming_medicine_type_1: "",
    deworming_medicine_date_2: "",
    deworming_medicine_2: "",
    deworming_medicine_type_2: "",
    vaccine_date_1: "",
    vaccine_1: "",
    vaccine_type_1: "",
    vaccine_date_2: "",
    vaccine_2: "",
    vaccine_type_2: "",
    vaccine_date_3: "",
    vaccine_3: "",
    vaccine_type_3: "",
    voluntary_insurance: "",
    warranty: "",
    pedigree: "",
    pedigree_issue_org_name: "",
    pedigree_issue_org_name_other: "",
    pedigree_date: "",
    veterinarian: "",
    sales_person_name: "",
    signature: ""
  };
  const schema = yup.object().shape({
    medical_history: yup.string().required("この欄は必須です"),
    medical_history_details: yup.string().when(["medical_history"], {
      is: (medical_history) => medical_history === "有",
      then: stringNumberMixedSchema(6).required("この欄は必須です")
    }),
    store_owner: yup.array(),
    other_owner: yup.string().when(["store_owner"], {
      is: (store_owner) => !_.includes(store_owner, "当店"),
      then: stringNumberMixedSchema(6).required("この欄は必須です")
    }),
    weight_from: numberOnlySchema(998, 0.01, "FLOOT").required(
      "この欄は必須です"
    ),
    weight_to: numberOnlySchema(999, 0.01, "FLOOT").moreThan(
      yup.ref("weight_from"),
      ({ more }) => `${more || 1}より大きくなければなりません`
    ),
    height_from: numberOnlySchema(998).required("この欄は必須です"),
    height_to: numberOnlySchema(999).moreThan(
      yup.ref("height_from"),
      ({ more }) => `${more || 1}より大きくなければなりません`
    ),
    life_expectancy_from: numberOnlySchema(998, 0).required("この欄は必須です"),
    life_expectancy_to: numberOnlySchema(999).moreThan(
      yup.ref("life_expectancy_from"),
      ({ more }) => `${more || 1}より大きくなければなりません`
    ),
    infertility_castration: yup.string().required("この欄は必須です"),
    genetic_disease: stringNumberMixedSchema(30),
    feeding_amount: numberOnlySchema(999999).required("この欄は必須です"),
    deworming_medicine_date_1: yup.date(),
    deworming_medicine_1: yup.string(),
    deworming_medicine_type_1: yup.string(),
    deworming_medicine_date_2: yup.date(),
    deworming_medicine_2: yup.string(),
    deworming_medicine_type_2: yup.string(),
    vaccine_date_1: yup.date(),
    vaccine_1: yup.string(),
    vaccine_type_1: stringNumberMixedSchema(12),
    vaccine_date_2: yup.date(),
    vaccine_2: yup.string(),
    vaccine_type_2: stringNumberMixedSchema(12),
    vaccine_date_3: yup.date(),
    vaccine_3: yup.string(),
    vaccine_type_3: stringNumberMixedSchema(12),
    voluntary_insurance: yup.string().required("この欄は必須です"),
    warranty: yup.string().required("この欄は必須です"),
    pedigree: yup
      .string()
      .required("この欄は必須です")
      .oneOf(["血統書付き", "血統書なし"], "この欄は必須です"),
    pedigree_issue_org_name: yup.string().when(["pedigree"], {
      is: (pedigree) => pedigree === "血統書付き",
      then: yup.string().required("この欄は必須です")
    }),
    pedigree_issue_org_name_other: yup.string().when(["pedigree_issue_org_name"], {
      is: (pedigree_issue_org_name) => pedigree_issue_org_name === "その他",
      then: stringOnlySchema(14).required("この欄は必須です")
    }),
    pedigree_date: yup.date().when(["pedigree"], {
      is: (pedigree) => pedigree === "血統書付き",
      then: yup.date().required("この欄は必須です")
    }),
    sales_person_name: stringOnlySchema(20).required("この欄は必須です"),
    veterinarian: yup.string(),
    signature: yup.lazy((value) =>
      /^data/.test(value)
        ? yup
            .string()
            .trim()
            .matches(
              /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)$/i,
              "signatureは必須です"
            )
            .required()
        : yup
            .string()
            .trim()
            .url("signatureは必須です")
            .required("signatureは必須です")
    )
  });
  const handleSubmit = (values) => {
    try {
      const val = {
        ...pdfvalues,
        ...values,
        animal_breed:
          data.mcData.animal_breed !== "999"
            ? _.find(breed, ["value", data.mcData.animal_breed])?.label
            : data.mcData.animal_breed_other,
        animal_color:
          data.mcData.animal_color !== "99"
            ? _.find(color, ["value", data.mcData.animal_color])?.label
            : data.mcData.animal_color_other,
        animal_gender: mcDataAnimalTransfer.animal_gender,
        animal_note: data.mcData.animal_note,
        animal_name: mcDataAnimalTransfer.animal_name,
        birthday: moment(data.mcData.animal_birthDay).format("YYYY年MM月DD日"),
        send: moment().format("YYYY年MM月DD日"),
        mccode: data.mccode,
        sellingPrice: mcDataAnimalTransfer.price
      };

      const serializedState = JSON.stringify(val);
      localStorage.setItem("sellingContractData", serializedState);
      navigate("/mypage/transferpay");
    } catch (err) {
      return undefined;
    }
  };

  const previewPdf = async (values) => {
    const val = {
      ...pdfvalues,
      ...values,
      animal_breed:
        data.mcData.animal_breed !== "999"
          ? _.find(breed, ["value", data.mcData.animal_breed])?.label
          : data.mcData.animal_breed_other,
      animal_color:
        data.mcData.animal_color !== "99"
          ? _.find(color, ["value", data.mcData.animal_color])?.label
          : data.mcData.animal_color_other,
      animal_gender: mcDataAnimalTransfer.animal_gender,
      animal_note: data.mcData.animal_note,
      animal_name: mcDataAnimalTransfer.animal_name,
      birthday: moment(data.mcData.animal_birthDay).format("YYYY年MM月DD日"),
      send: moment().format("YYYY年MM月DD日"),
      mccode: data.mccode,
      sellingPrice: mcDataAnimalTransfer.price
    };
    setPdfvalues(val);
    const url = await pdfMakerFn(
      data,
      { ...userData, sellingContractTemplatePage1: sellingContractPage },
      userInfo,
      val
    );
    if (url) {
      setModalShow(true);
      setPdfURL(url);
    }
  };

  const getSellingContractPage1 = async () => {
    if (userData && userData.sellingContractTemplatePage1) {
      setSellingContractPage(userData.sellingContractTemplatePage1);
    } else {
      const data = await firebaseGetDoc("admin", "sellingContractTemplate");
      if (data && data.page1) {
        setSellingContractPage(data.page1);
        setToDBSellingContractPage1(data.page1);
      }
    }
  };

  const setToDBSellingContractPage1 = async (page1 = sellingContractPage) => {
    await firebaseSetDoc(
      "users",
      { sellingContractTemplatePage1: page1 },
      user.uid,
      true
    );
    // console.log({ sellingContractTemplatePage1: page1 });
    setSellingContractPageEdit(false);
  };
  useEffect(() => {
    getSellingContractPage1();
  }, []);

  const [breed, setBreed] = useState(null);
  const [color, setColor] = useState(null);
  const [pedigreeIssueres, setPedigreeIssueres] = useState(null);
  useEffect(() => {
    if (data.mcData.animal_group === "犬") {
      setBreed(dog_breed);
      setColor(dog_color);
      setPedigreeIssueres(pedigree_issue_org_names_dog);
    } else if (data.mcData.animal_group === "猫") {
      setBreed(cat_breed);
      setColor(cat_color);
      setPedigreeIssueres(pedigree_issue_org_names_cat);
    }
  }, []);

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {});

    return (
      <Button variant="primary" type="button" onClick={decoratedOnClick}>
        {children}
      </Button>
    );
  }

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "スキャン", link: "/mypage/transferstart" },
              { value: "メール", link: "#" },
              { value: "OTP", link: "#" },
              { value: "入力", link: "#" },
              { value: "確認", link: "#" },
              { value: "販売契約書", link: "#" },
              { value: "支払", link: "#" },
              { value: "終了", link: "#" }
            ]}
            activeNo={6}
          />
        </Row>

        <Row className="justify-content-center mb-4">
          <Col xl={10} md={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched
              }) => (
                <>
                  <Form noValidate onSubmit={handleSubmit}>
                    <Accordion defaultActiveKey="1">
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          1. 販売契約書(保証内容)
                        </Accordion.Header>
                        <Accordion.Body>
                          {/* <Row>
                            <Col className="text-center mb-1">
                              {sellingContractPage &&
                                !sellingContractPageEdit && (
                                  <Button
                                    variant="warning"
                                    className="me-1"
                                    onClick={() =>
                                      setSellingContractPageEdit(true)
                                    }>
                                    編集 <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                )}
                              {sellingContractPage &&
                                sellingContractPageEdit && (
                                  <Button
                                    variant="warning"
                                    className="me-1"
                                    onClick={() =>
                                      setToDBSellingContractPage1()
                                    }>
                                    保存 <FontAwesomeIcon icon={faSave} />
                                  </Button>
                                )}
                              <CustomToggle eventKey="2">
                                <b>
                                  次へ{" "}
                                  <FontAwesomeIcon
                                    icon={faCircleChevronRight}
                                  />
                                </b>
                              </CustomToggle>
                            </Col>
                          </Row> */}
                          {sellingContractPage && !sellingContractPageEdit && (
                            <div className="ql-snow ">
                              <div
                                className="ql-editor ql-container"
                                dangerouslySetInnerHTML={{
                                  __html: sellingContractPage
                                }}></div>
                            </div>
                          )}
                          {sellingContractPage && sellingContractPageEdit && (
                            <ReactQuill
                              theme="snow"
                              modules={modules}
                              value={sellingContractPage}
                              onChange={(value, _, __, editor) => {
                                setSellingContractPage(value);
                                // console.log(value)
                                // if (editor.getLength() === 1) {
                                //   setFieldValue("sellPlatformDescription", "");
                                // } else {
                                //   setFieldValue("sellPlatformDescription", value);
                                // }
                              }}
                              onBlur={() => {
                                // setFieldTouched("sellPlatformDescription", true);
                              }}
                              // className={
                              //   // touched.sellPlatformDescription &&
                              //   // errors.sellPlatformDescription
                              //   //   ? "border border-danger"
                              //   //   : ""
                              // }
                            />
                          )}

                          <Row>
                            <Col className="text-center my-1">
                              {/* {sellingContractPage &&
                                !sellingContractPageEdit && (
                                  <Button
                                    variant="warning"
                                    className="me-1"
                                    onClick={() =>
                                      setSellingContractPageEdit(true)
                                    }>
                                    編集 <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                )}
                              {sellingContractPage &&
                                sellingContractPageEdit && (
                                  <Button
                                    variant="warning"
                                    className="me-1"
                                    onClick={() =>
                                      setToDBSellingContractPage1()
                                    }>
                                    保存 <FontAwesomeIcon icon={faSave} />
                                  </Button>
                                )} */}
                              <CustomToggle eventKey="2">
                                <b>
                                  次へ{" "}
                                  <FontAwesomeIcon
                                    icon={faCircleChevronRight}
                                  />
                                </b>
                              </CustomToggle>
                            </Col>
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          2.
                          動物（犬猫）の特性および状態の概要［規則第8条の2第2項一〜十八号（除く七〜十号）］{" "}
                          {((touched.medical_history &&
                            errors.medical_history) ||
                            (touched.medical_history_details &&
                              errors.medical_history_details) ||
                            (touched.store_owner && errors.store_owner) ||
                            (touched.other_owner && errors.other_owner) ||
                            (touched.weight_from && errors.weight_from) ||
                            (touched.weight_to && errors.weight_to) ||
                            (touched.height_from && errors.height_from) ||
                            (touched.height_to && errors.height_to) ||
                            (touched.life_expectancy_from &&
                              errors.life_expectancy_from) ||
                            (touched.life_expectancy_to &&
                              errors.life_expectancy_to) ||
                            (touched.infertility_castration &&
                              errors.infertility_castration) ||
                            (touched.genetic_disease &&
                              errors.genetic_disease) ||
                            (touched.feeding_amount && errors.feeding_amount) ||
                            (touched.deworming_medicine_date_1 &&
                              errors.deworming_medicine_date_1) ||
                            (touched.deworming_medicine_1 &&
                              errors.deworming_medicine_1) ||
                            (touched.deworming_medicine_type_1 &&
                              errors.deworming_medicine_type_1) ||
                            (touched.deworming_medicine_date_2 &&
                              errors.deworming_medicine_date_2) ||
                            (touched.deworming_medicine_2 &&
                              errors.deworming_medicine_2) ||
                            (touched.deworming_medicine_type_2 &&
                              errors.deworming_medicine_type_2) ||
                            (touched.vaccine_date_1 && errors.vaccine_date_1) ||
                            (touched.vaccine_1 && errors.vaccine_1) ||
                            (touched.vaccine_type_1 && errors.vaccine_type_1) ||
                            (touched.vaccine_date_2 && errors.vaccine_date_2) ||
                            (touched.vaccine_2 && errors.vaccine_2) ||
                            (touched.vaccine_type_2 && errors.vaccine_type_2) ||
                            (touched.vaccine_date_3 && errors.vaccine_date_3) ||
                            (touched.vaccine_3 && errors.vaccine_3) ||
                            (touched.vaccine_type_3 &&
                              errors.vaccine_type_3)) && (
                            <>
                              <SectionErrorMgs />
                            </>
                          )}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Row className="px-lg-3">
                            <Col
                              lg={3}
                              className={`bg-secondary bg-opacity-10 ${
                                width < 576 && `border`
                              } ${
                                width >= 576 &&
                                `border-bottom border-start border-top`
                              } border-secondary p-2`}>
                              病歴<span className="text-danger">※</span>
                            </Col>
                            <Col
                              lg={9}
                              className={`${
                                width < 576 && `border-start border-end`
                              } ${
                                width >= 576 && `border`
                              } p-1 border-secondary`}>
                              <Form.Check
                                inline
                                name="medical_history"
                                id="medical_history1"
                                label="無"
                                value="無"
                                type="radio"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={
                                  values.medical_history === "無" ? true : false
                                }
                                isInvalid={
                                  touched.medical_history &&
                                  errors.medical_history
                                }
                                isValid={
                                  touched.medical_history &&
                                  !errors.medical_history
                                }
                              />
                              <Form.Check
                                inline
                                name="medical_history"
                                id="medical_history2"
                                label="有"
                                value="有"
                                type="radio"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={
                                  values.medical_history === "有" ? true : false
                                }
                                isInvalid={
                                  touched.medical_history &&
                                  errors.medical_history
                                }
                                isValid={
                                  touched.medical_history &&
                                  !errors.medical_history
                                }
                              />

                              {values.medical_history === "有" && (
                                <>
                                  {"( "}
                                  <Form.Group className="d-inline-block">
                                    <Form.Control
                                      type="text"
                                      placeholder="半角"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.medical_history_details}
                                      name="medical_history_details"
                                      // maxLength={10}
                                      required
                                      isInvalid={
                                        touched.medical_history_details &&
                                        errors.medical_history_details
                                      }
                                      isValid={
                                        touched.medical_history_details &&
                                        !errors.medical_history_details
                                      }
                                    />
                                  </Form.Group>
                                  {" )"}
                                </>
                              )}

                              {touched.medical_history &&
                                errors.medical_history && (
                                  <p className="text-danger mb-0">
                                    <small> {errors.medical_history}</small>
                                  </p>
                                )}

                              {touched.medical_history_details &&
                                errors.medical_history_details && (
                                  <p className="text-danger mb-0">
                                    <small>
                                      {errors.medical_history_details}
                                    </small>
                                  </p>
                                )}
                            </Col>
                          </Row>

                          <Row className="px-lg-3">
                            <Col
                              lg={3}
                              className={`bg-secondary bg-opacity-10 ${
                                width < 576 && `border`
                              } ${
                                width >= 576 && `border-bottom border-start `
                              } border-secondary p-2`}>
                              現所有者<span className="text-danger">※</span>
                            </Col>
                            <Col
                              lg={9}
                              className={`${
                                width < 576 && `border-start border-end`
                              } ${
                                width >= 576 &&
                                `border-start border-bottom border-end`
                              } p-1 border-secondary`}>
                              <Form.Check
                                inline
                                name="store_owner"
                                id="store_owner1"
                                label="当店"
                                value="当店"
                                type="checkbox"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={_.includes(values.store_owner, "当店")}
                                isInvalid={
                                  touched.store_owner && errors.store_owner
                                }
                                isValid={
                                  touched.store_owner && !errors.store_owner
                                }
                              />
                              {!_.includes(values.store_owner, "当店") && (
                                <>
                                  {"/ "}
                                  <Form.Group className="d-inline-block">
                                    <Form.Control
                                      type="text"
                                      placeholder="半角"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.other_owner}
                                      name="other_owner"
                                      // maxLength={10}
                                      required
                                      isInvalid={
                                        touched.other_owner &&
                                        errors.other_owner
                                      }
                                      isValid={
                                        touched.other_owner &&
                                        !errors.other_owner
                                      }
                                    />
                                  </Form.Group>
                                </>
                              )}

                              {touched.store_owner && errors.store_owner && (
                                <p className="text-danger mb-0">
                                  <small>{errors.store_owner}</small>
                                </p>
                              )}

                              {touched.other_owner && errors.other_owner && (
                                <p className="text-danger mb-0">
                                  <small>{errors.other_owner}</small>
                                </p>
                              )}
                            </Col>
                          </Row>

                          <Row className="px-lg-3">
                            <Col
                              lg={3}
                              className={`bg-secondary bg-opacity-10 ${
                                width < 576 && `border`
                              } ${
                                width >= 576 && `border-bottom border-start `
                              } border-secondary p-2`}>
                              標準体重<span className="text-danger">※</span>
                            </Col>
                            <Col
                              lg={9}
                              className={`${
                                width < 576 && `border-start border-end`
                              } ${
                                width >= 576 &&
                                `border-start border-bottom border-end`
                              } p-1  border-secondary`}>
                              <InputGroup hasValidation>
                                <Form.Control
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.weight_from}
                                  name="weight_from"
                                  maxLength={4}
                                  required
                                  isInvalid={
                                    touched.weight_from && errors.weight_from
                                  }
                                  isValid={
                                    touched.weight_from && !errors.weight_from
                                  }
                                />

                                <InputGroup.Text>kg ~</InputGroup.Text>

                                <Form.Control
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.weight_to}
                                  name="weight_to"
                                  maxLength={4}
                                  isInvalid={
                                    touched.weight_to && errors.weight_to
                                  }
                                  isValid={
                                    touched.weight_to && !errors.weight_to
                                  }
                                />
                                <InputGroup.Text>kg</InputGroup.Text>

                                <Form.Control.Feedback type="invalid">
                                  <p className="mb-0">
                                    {errors.weight_from &&
                                      `ハイフンの前には: ${errors.weight_from}`}
                                  </p>
                                  <p className="mb-0">
                                    {errors.weight_to &&
                                      `ハイフンの後には: ${errors.weight_to}`}
                                  </p>
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Col>
                          </Row>

                          <Row className="px-lg-3">
                            <Col
                              lg={3}
                              className={`bg-secondary bg-opacity-10 ${
                                width < 576 && `border`
                              } ${
                                width >= 576 && `border-bottom border-start `
                              } border-secondary p-2`}>
                              標準体高<span className="text-danger">※</span>
                            </Col>
                            <Col
                              lg={9}
                              className={`${
                                width < 576 && `border-start border-end`
                              } ${
                                width >= 576 &&
                                `border-start border-bottom border-end`
                              } p-1  border-secondary`}>
                              <InputGroup hasValidation>
                                <Form.Control
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.height_from}
                                  name="height_from"
                                  maxLength={4}
                                  required
                                  isInvalid={
                                    touched.height_from && errors.height_from
                                  }
                                  isValid={
                                    touched.height_from && !errors.height_from
                                  }
                                />

                                <InputGroup.Text>cm ~</InputGroup.Text>

                                <Form.Control
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.height_to}
                                  name="height_to"
                                  maxLength={4}
                                  isInvalid={
                                    touched.height_to && errors.height_to
                                  }
                                  isValid={
                                    touched.height_to && !errors.height_to
                                  }
                                />
                                <InputGroup.Text>cm</InputGroup.Text>

                                <Form.Control.Feedback type="invalid">
                                  <p className="mb-0">
                                    {errors.height_from &&
                                      `ハイフンの前には: ${errors.height_from}`}
                                  </p>
                                  <p className="mb-0">
                                    {errors.height_to &&
                                      `ハイフンの後には: ${errors.height_to}`}
                                  </p>
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Col>
                          </Row>

                          <Row className="px-lg-3">
                            <Col
                              lg={3}
                              className={`bg-secondary bg-opacity-10 ${
                                width < 576 && `border`
                              } ${
                                width >= 576 && `border-bottom border-start `
                              } border-secondary p-2`}>
                              平均寿命<span className="text-danger">※</span>
                            </Col>
                            <Col
                              lg={9}
                              className={`${
                                width < 576 && `border-start border-end`
                              } ${
                                width >= 576 &&
                                `border-start border-bottom border-end`
                              } p-1  border-secondary`}>
                              <InputGroup hasValidation>
                                <Form.Control
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.life_expectancy_from}
                                  name="life_expectancy_from"
                                  maxLength={4}
                                  required
                                  isInvalid={
                                    touched.life_expectancy_from &&
                                    errors.life_expectancy_from
                                  }
                                  isValid={
                                    touched.life_expectancy_from &&
                                    !errors.life_expectancy_from
                                  }
                                />

                                <InputGroup.Text>年 ~</InputGroup.Text>

                                <Form.Control
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.life_expectancy_to}
                                  name="life_expectancy_to"
                                  maxLength={4}
                                  isInvalid={
                                    touched.life_expectancy_to &&
                                    errors.life_expectancy_to
                                  }
                                  isValid={
                                    touched.life_expectancy_to &&
                                    !errors.life_expectancy_to
                                  }
                                />
                                <InputGroup.Text>年</InputGroup.Text>

                                <Form.Control.Feedback type="invalid">
                                  <p className="mb-0">
                                    {errors.life_expectancy_from &&
                                      `ハイフンの前には: ${errors.life_expectancy_from}`}
                                  </p>
                                  <p className="mb-0">
                                    {errors.life_expectancy_to &&
                                      `ハイフンの後には: ${errors.life_expectancy_to}`}
                                  </p>
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row className="px-lg-3">
                            <Col
                              lg={3}
                              className={`bg-secondary bg-opacity-10 ${
                                width < 576 && `border`
                              } ${
                                width >= 576 && `border-bottom border-start `
                              } border-secondary p-2`}>
                              不妊・去勢措置
                              <span className="text-danger">※</span>
                            </Col>
                            <Col
                              lg={9}
                              className={`${
                                width < 576 && `border-start border-end`
                              } ${
                                width >= 576 &&
                                `border-start border-bottom border-end`
                              } p-1 border-secondary`}>
                              <Form.Check
                                inline
                                name="infertility_castration"
                                id="infertility_castration1"
                                label="未実施"
                                value="未実施"
                                type="radio"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={
                                  values.infertility_castration === "未実施"
                                    ? true
                                    : false
                                }
                                isInvalid={
                                  touched.infertility_castration &&
                                  errors.infertility_castration
                                }
                                isValid={
                                  touched.infertility_castration &&
                                  !errors.infertility_castration
                                }
                              />
                              <Form.Check
                                inline
                                name="infertility_castration"
                                id="infertility_castration2"
                                label="実施済み"
                                value="実施済み"
                                type="radio"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={
                                  values.infertility_castration === "実施済み"
                                    ? true
                                    : false
                                }
                                isInvalid={
                                  touched.infertility_castration &&
                                  errors.infertility_castration
                                }
                                isValid={
                                  touched.infertility_castration &&
                                  !errors.infertility_castration
                                }
                              />

                              {touched.infertility_castration && (
                                <p className="text-danger mb-0">
                                  <small>
                                    {" "}
                                    {errors.infertility_castration}
                                  </small>
                                </p>
                              )}
                            </Col>
                          </Row>
                          <Row className="px-lg-3">
                            <Col
                              lg={3}
                              className={`bg-secondary bg-opacity-10 ${
                                width < 576 && `border`
                              } ${
                                width >= 576 && `border-bottom border-start `
                              } border-secondary p-2`}>
                              親および同腹子に係る遺伝性疾患の発生状況などの事項
                            </Col>
                            <Col
                              lg={9}
                              className={`${
                                width < 576 && `border-start border-end`
                              } ${
                                width >= 576 &&
                                `border-start border-bottom border-end`
                              } p-1  border-secondary`}>
                              <InputGroup hasValidation>
                                <Form.Control
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.genetic_disease}
                                  name="genetic_disease"
                                  required
                                  isInvalid={
                                    touched.genetic_disease &&
                                    errors.genetic_disease
                                  }
                                  isValid={
                                    touched.genetic_disease &&
                                    !errors.genetic_disease
                                  }
                                />

                                <Form.Control.Feedback type="invalid">
                                  {errors.genetic_disease}
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row className="px-lg-3">
                            <Col
                              lg={3}
                              className={`bg-secondary bg-opacity-10 ${
                                width < 576 && `border`
                              } ${
                                width >= 576 && `border-bottom border-start `
                              } border-secondary p-2`}>
                              給餌<span className="text-danger">※</span>
                            </Col>
                            <Col
                              lg={9}
                              className={`${
                                width < 576 && `border-start border-end`
                              } ${
                                width >= 576 &&
                                `border-start border-bottom border-end`
                              } p-1  border-secondary`}>
                              <InputGroup hasValidation>
                                <Form.Control
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.feeding_amount}
                                  name="feeding_amount"
                                  required
                                  isInvalid={
                                    touched.feeding_amount &&
                                    errors.feeding_amount
                                  }
                                  isValid={
                                    touched.feeding_amount &&
                                    !errors.feeding_amount
                                  }
                                />
                                <InputGroup.Text>g</InputGroup.Text>

                                <Form.Control.Feedback type="invalid">
                                  {errors.feeding_amount}
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Col>
                          </Row>

                          <Row className="px-lg-3">
                            <Col
                              lg={3}
                              className={`bg-secondary bg-opacity-10 ${
                                width < 576 && `border`
                              } ${
                                width >= 576 && `border-bottom border-start `
                              } border-secondary p-2`}>
                              虫下し <br />
                              (回中・条虫)
                            </Col>
                            <Col
                              lg={9}
                              className={`${
                                width < 576 && `border-start border-end`
                              } ${
                                width >= 576 &&
                                `border-start border-bottom border-end`
                              } p-1  border-secondary`}>
                              <Row>
                                <Col>
                                  <InputGroup hasValidation>
                                    <InputGroup.Text>第1回</InputGroup.Text>
                                    <DatePicker
                                      calendarIcon={false}
                                      className={`form-control ${
                                        touched.deworming_medicine_date_1 &&
                                        errors.deworming_medicine_date_1
                                          ? "is-invalid"
                                          : ""
                                      } ${
                                        touched.deworming_medicine_date_1 &&
                                        !errors.deworming_medicine_date_1
                                          ? "is-valid"
                                          : ""
                                      }`}
                                      format="MM/dd"
                                      locale="ja-JP"
                                      dayPlaceholder=" 日 "
                                      monthPlaceholder=" 月 "
                                      onChange={(val) => {
                                        const isValidDate = val && !isNaN(val);
                                        setFieldValue("deworming_medicine_date_1", isValidDate ? val : "");
                                      }}
                                      onFocus={() => {
                                        setFieldTouched(
                                          "deworming_medicine_date_1",
                                          true
                                        );
                                      }}
                                      value={values.deworming_medicine_date_1}
                                      name="deworming_medicine_date_1"
                                    />

                                    <Form.Control.Feedback type="invalid">
                                      {errors.deworming_medicine_date_1}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>

                                <Col sm={3} className="p-sm-0">
                                  <div className="form-control text-center">
                                    <Form.Check
                                      inline
                                      name="deworming_medicine_1"
                                      id="deworming_medicine_11"
                                      label="済"
                                      value="済"
                                      type="radio"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      checked={
                                        values.deworming_medicine_1 === "済"
                                          ? true
                                          : false
                                      }
                                      isInvalid={
                                        touched.deworming_medicine_1 &&
                                        errors.deworming_medicine_1
                                      }
                                      isValid={
                                        touched.deworming_medicine_1 &&
                                        !errors.deworming_medicine_1
                                      }
                                    />
                                    <Form.Check
                                      inline
                                      className="me-0"
                                      name="deworming_medicine_1"
                                      id="deworming_medicine_12"
                                      label="未"
                                      value="未"
                                      type="radio"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      checked={
                                        values.deworming_medicine_1 === "未"
                                          ? true
                                          : false
                                      }
                                      isInvalid={
                                        touched.deworming_medicine_1 &&
                                        errors.deworming_medicine_1
                                      }
                                      isValid={
                                        touched.deworming_medicine_1 &&
                                        !errors.deworming_medicine_1
                                      }
                                    />
                                  </div>
                                </Col>

                                <Col sm={3}>
                                  <div className="form-control text-center">
                                    <Form.Check
                                      inline
                                      name="deworming_medicine_type_1"
                                      id="deworming_medicine_type_11"
                                      label="＋"
                                      value="+"
                                      type="radio"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      checked={
                                        values.deworming_medicine_type_1 === "+"
                                          ? true
                                          : false
                                      }
                                      isInvalid={
                                        touched.deworming_medicine_type_1 &&
                                        errors.deworming_medicine_type_1
                                      }
                                      isValid={
                                        touched.deworming_medicine_type_1 &&
                                        !errors.deworming_medicine_type_1
                                      }
                                    />
                                    <Form.Check
                                      className="me-0"
                                      inline
                                      name="deworming_medicine_type_1"
                                      id="deworming_medicine_type_12"
                                      label="ー"
                                      value="-"
                                      type="radio"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      checked={
                                        values.deworming_medicine_type_1 === "-"
                                          ? true
                                          : false
                                      }
                                      isInvalid={
                                        touched.deworming_medicine_type_1 &&
                                        errors.deworming_medicine_type_1
                                      }
                                      isValid={
                                        touched.deworming_medicine_type_1 &&
                                        !errors.deworming_medicine_type_1
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="pt-1">
                                <Col>
                                  <InputGroup hasValidation>
                                    <InputGroup.Text>第2回</InputGroup.Text>
                                    <DatePicker
                                      calendarIcon={false}
                                      className={`form-control ${
                                        touched.deworming_medicine_date_2 &&
                                        errors.deworming_medicine_date_2
                                          ? "is-invalid"
                                          : ""
                                      } ${
                                        touched.deworming_medicine_date_2 &&
                                        !errors.deworming_medicine_date_2
                                          ? "is-valid"
                                          : ""
                                      }`}
                                      format="MM/dd"
                                      locale="ja-JP"
                                      dayPlaceholder=" 日 "
                                      monthPlaceholder=" 月 "
                                      onChange={(val) => {
                                        const isValidDate = val && !isNaN(val);
                                        setFieldValue("deworming_medicine_date_2", isValidDate ? val : "");
                                      }}
                                      onFocus={() => {
                                        setFieldTouched(
                                          "deworming_medicine_date_2",
                                          true
                                        );
                                      }}
                                      value={values.deworming_medicine_date_2}
                                      name="deworming_medicine_date_2"
                                    />

                                    <Form.Control.Feedback type="invalid">
                                      {errors.deworming_medicine_date_2}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>

                                <Col sm={3} className="p-sm-0">
                                  <div className="form-control text-center">
                                    <Form.Check
                                      inline
                                      name="deworming_medicine_2"
                                      id="deworming_medicine_21"
                                      label="済"
                                      value="済"
                                      type="radio"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      checked={
                                        values.deworming_medicine_2 === "済"
                                          ? true
                                          : false
                                      }
                                      isInvalid={
                                        touched.deworming_medicine_2 &&
                                        errors.deworming_medicine_2
                                      }
                                      isValid={
                                        touched.deworming_medicine_2 &&
                                        !errors.deworming_medicine_2
                                      }
                                    />
                                    <Form.Check
                                      inline
                                      className="me-0"
                                      name="deworming_medicine_2"
                                      id="deworming_medicine_21"
                                      label="未"
                                      value="未"
                                      type="radio"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      checked={
                                        values.deworming_medicine_2 === "未"
                                          ? true
                                          : false
                                      }
                                      isInvalid={
                                        touched.deworming_medicine_2 &&
                                        errors.deworming_medicine_2
                                      }
                                      isValid={
                                        touched.deworming_medicine_2 &&
                                        !errors.deworming_medicine_2
                                      }
                                    />
                                  </div>
                                </Col>

                                <Col sm={3}>
                                  <div className="form-control text-center">
                                    <Form.Check
                                      inline
                                      name="deworming_medicine_type_2"
                                      id="deworming_medicine_type_21"
                                      label="＋"
                                      value="+"
                                      type="radio"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      checked={
                                        values.deworming_medicine_type_2 === "+"
                                          ? true
                                          : false
                                      }
                                      isInvalid={
                                        touched.deworming_medicine_type_2 &&
                                        errors.deworming_medicine_type_2
                                      }
                                      isValid={
                                        touched.deworming_medicine_type_2 &&
                                        !errors.deworming_medicine_type_2
                                      }
                                    />
                                    <Form.Check
                                      className="me-0"
                                      inline
                                      name="deworming_medicine_type_2"
                                      id="deworming_medicine_type_21"
                                      label="ー"
                                      value="-"
                                      type="radio"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      checked={
                                        values.deworming_medicine_type_2 === "-"
                                          ? true
                                          : false
                                      }
                                      isInvalid={
                                        touched.deworming_medicine_type_2 &&
                                        errors.deworming_medicine_type_2
                                      }
                                      isValid={
                                        touched.deworming_medicine_type_2 &&
                                        !errors.deworming_medicine_type_2
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row className="px-lg-3">
                            <Col
                              lg={3}
                              className={`bg-secondary bg-opacity-10 ${
                                width < 576 && `border`
                              } ${
                                width >= 576 && `border-bottom border-start `
                              } border-secondary p-2`}>
                              混合ワクチン
                            </Col>
                            <Col
                              lg={9}
                              className={`${
                                width < 576 && `border-start border-end`
                              } ${
                                width >= 576 &&
                                `border-start border-bottom border-end`
                              } p-1  border-secondary`}>
                              <Row>
                                <Col>
                                  <InputGroup hasValidation>
                                    <InputGroup.Text>第1回</InputGroup.Text>
                                    <DatePicker
                                      calendarIcon={false}
                                      className={`form-control ${
                                        touched.vaccine_date_1 &&
                                        errors.vaccine_date_1
                                          ? "is-invalid"
                                          : ""
                                      } ${
                                        touched.vaccine_date_1 &&
                                        !errors.vaccine_date_1
                                          ? "is-valid"
                                          : ""
                                      }`}
                                      format="MM/dd"
                                      locale="ja-JP"
                                      dayPlaceholder=" 日 "
                                      monthPlaceholder=" 月 "
                                      onChange={(val) => {
                                        const isValidDate = val && !isNaN(val);
                                        setFieldValue("vaccine_date_1", isValidDate ? val : "");
                                      }}
                                      onFocus={() => {
                                        setFieldTouched("vaccine_date_1", true);
                                      }}
                                      value={values.vaccine_date_1}
                                      name="vaccine_date_1"
                                    />

                                    <Form.Control.Feedback type="invalid">
                                      {errors.vaccine_date_1}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>

                                <Col sm={3} className="p-sm-0">
                                  <div className="form-control text-center">
                                    <Form.Check
                                      inline
                                      name="vaccine_1"
                                      id="vaccine_11"
                                      label="済"
                                      value="済"
                                      type="radio"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      checked={
                                        values.vaccine_1 === "済" ? true : false
                                      }
                                      isInvalid={
                                        touched.vaccine_1 && errors.vaccine_1
                                      }
                                      isValid={
                                        touched.vaccine_1 && !errors.vaccine_1
                                      }
                                    />
                                    <Form.Check
                                      inline
                                      className="me-0"
                                      name="vaccine_1"
                                      id="vaccine_12"
                                      label="未"
                                      value="未"
                                      type="radio"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      checked={
                                        values.vaccine_1 === "未" ? true : false
                                      }
                                      isInvalid={
                                        touched.vaccine_1 && errors.vaccine_1
                                      }
                                      isValid={
                                        touched.vaccine_1 && !errors.vaccine_1
                                      }
                                    />
                                  </div>
                                </Col>

                                <Col sm={3}>
                                  <Form.Control
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.vaccine_type_1}
                                    name="vaccine_type_1"
                                    required
                                    isInvalid={
                                      touched.vaccine_type_1 &&
                                      errors.vaccine_type_1
                                    }
                                    isValid={
                                      touched.vaccine_type_1 &&
                                      !errors.vaccine_type_1
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.vaccine_type_1}
                                  </Form.Control.Feedback>
                                </Col>
                              </Row>

                              <Row className="pt-1">
                                <Col>
                                  <InputGroup hasValidation>
                                    <InputGroup.Text>第2回</InputGroup.Text>
                                    <DatePicker
                                      calendarIcon={false}
                                      className={`form-control ${
                                        touched.vaccine_date_2 &&
                                        errors.vaccine_date_2
                                          ? "is-invalid"
                                          : ""
                                      } ${
                                        touched.vaccine_date_2 &&
                                        !errors.vaccine_date_2
                                          ? "is-valid"
                                          : ""
                                      }`}
                                      format="MM/dd"
                                      locale="ja-JP"
                                      dayPlaceholder=" 日 "
                                      monthPlaceholder=" 月 "
                                      onChange={(val) => {
                                        const isValidDate = val && !isNaN(val);
                                        setFieldValue("vaccine_date_2", isValidDate ? val : "");
                                      }}
                                      onFocus={() => {
                                        setFieldTouched("vaccine_date_2", true);
                                      }}
                                      value={values.vaccine_date_2}
                                      name="vaccine_date_2"
                                    />

                                    <Form.Control.Feedback type="invalid">
                                      {errors.vaccine_date_2}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>

                                <Col sm={3} className="p-sm-0">
                                  <div className="form-control text-center">
                                    <Form.Check
                                      inline
                                      name="vaccine_2"
                                      id="vaccine_21"
                                      label="済"
                                      value="済"
                                      type="radio"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      checked={
                                        values.vaccine_2 === "済" ? true : false
                                      }
                                      isInvalid={
                                        touched.vaccine_2 && errors.vaccine_2
                                      }
                                      isValid={
                                        touched.vaccine_2 && !errors.vaccine_2
                                      }
                                    />
                                    <Form.Check
                                      inline
                                      className="me-0"
                                      name="vaccine_2"
                                      id="vaccine_21"
                                      label="未"
                                      value="未"
                                      type="radio"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      checked={
                                        values.vaccine_2 === "未" ? true : false
                                      }
                                      isInvalid={
                                        touched.vaccine_2 && errors.vaccine_2
                                      }
                                      isValid={
                                        touched.vaccine_2 && !errors.vaccine_2
                                      }
                                    />
                                  </div>
                                </Col>

                                <Col sm={3}>
                                  <Form.Control
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.vaccine_type_2}
                                    name="vaccine_type_2"
                                    required
                                    isInvalid={
                                      touched.vaccine_type_2 &&
                                      errors.vaccine_type_2
                                    }
                                    isValid={
                                      touched.vaccine_type_2 &&
                                      !errors.vaccine_type_2
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.vaccine_type_2}
                                  </Form.Control.Feedback>
                                </Col>
                              </Row>

                              <Row className="pt-1">
                                <Col>
                                  <InputGroup hasValidation>
                                    <InputGroup.Text>第3回</InputGroup.Text>
                                    <DatePicker
                                      calendarIcon={false}
                                      className={`form-control ${
                                        touched.vaccine_date_3 &&
                                        errors.vaccine_date_3
                                          ? "is-invalid"
                                          : ""
                                      } ${
                                        touched.vaccine_date_3 &&
                                        !errors.vaccine_date_3
                                          ? "is-valid"
                                          : ""
                                      }`}
                                      format="MM/dd"
                                      locale="ja-JP"
                                      dayPlaceholder=" 日 "
                                      monthPlaceholder=" 月 "
                                      onChange={(val) => {
                                        const isValidDate = val && !isNaN(val);
                                        setFieldValue("vaccine_date_3", isValidDate ? val : "");
                                      }}
                                      onFocus={() => {
                                        setFieldTouched("vaccine_date_3", true);
                                      }}
                                      value={values.vaccine_date_3}
                                      name="vaccine_date_3"
                                    />

                                    <Form.Control.Feedback type="invalid">
                                      {errors.vaccine_date_3}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Col>

                                <Col sm={3} className="p-sm-0">
                                  <div className="form-control text-center">
                                    <Form.Check
                                      inline
                                      name="vaccine_3"
                                      id="vaccine_31"
                                      label="済"
                                      value="済"
                                      type="radio"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      checked={
                                        values.vaccine_3 === "済" ? true : false
                                      }
                                      isInvalid={
                                        touched.vaccine_3 && errors.vaccine_3
                                      }
                                      isValid={
                                        touched.vaccine_3 && !errors.vaccine_3
                                      }
                                    />
                                    <Form.Check
                                      inline
                                      className="me-0"
                                      name="vaccine_3"
                                      id="vaccine_31"
                                      label="未"
                                      value="未"
                                      type="radio"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      checked={
                                        values.vaccine_3 === "未" ? true : false
                                      }
                                      isInvalid={
                                        touched.vaccine_3 && errors.vaccine_3
                                      }
                                      isValid={
                                        touched.vaccine_3 && !errors.vaccine_3
                                      }
                                    />
                                  </div>
                                </Col>

                                <Col sm={3}>
                                  <Form.Control
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.vaccine_type_3}
                                    name="vaccine_type_3"
                                    required
                                    isInvalid={
                                      touched.vaccine_type_3 &&
                                      errors.vaccine_type_3
                                    }
                                    isValid={
                                      touched.vaccine_type_3 &&
                                      !errors.vaccine_type_3
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.vaccine_type_3}
                                  </Form.Control.Feedback>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <div className="text-center mt-4">
                            <CustomToggle eventKey="3">
                              <b>
                                次へ{" "}
                                <FontAwesomeIcon icon={faCircleChevronRight} />
                              </b>
                            </CustomToggle>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          3. 販売日・血統書など{" "}
                          {((touched.voluntary_insurance &&
                            errors.voluntary_insurance) ||
                            (touched.warranty && errors.warranty) ||
                            (touched.pedigree && errors.pedigree) ||
                            (touched.pedigree_issue_org_name &&
                              errors.pedigree_issue_org_name) ||
                            (touched.pedigree_date && errors.pedigree_date) ||
                            (touched.veterinarian && errors.veterinarian)) && (
                            <>
                              <SectionErrorMgs />
                            </>
                          )}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Row className="px-lg-3">
                            <Col
                              lg={3}
                              className={`bg-secondary bg-opacity-10 ${
                                width < 576 && `border`
                              } ${
                                width >= 576 &&
                                `border-bottom border-start border-top`
                              } border-secondary p-2`}>
                              任意保険<span className="text-danger">※</span>
                            </Col>
                            <Col
                              lg={9}
                              className={`${
                                width < 576 && `border-start border-end`
                              } ${
                                width >= 576 && `border`
                              } p-1 border-secondary`}>
                              <Form.Check
                                inline
                                name="voluntary_insurance"
                                id="voluntary_insurance1"
                                label="加入する"
                                value="加入する"
                                type="radio"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={
                                  values.voluntary_insurance === "加入する"
                                    ? true
                                    : false
                                }
                                isInvalid={
                                  touched.voluntary_insurance &&
                                  errors.voluntary_insurance
                                }
                                isValid={
                                  touched.voluntary_insurance &&
                                  !errors.voluntary_insurance
                                }
                              />
                              <Form.Check
                                inline
                                name="voluntary_insurance"
                                id="voluntary_insurance2"
                                label="加入しない"
                                value="加入しない"
                                type="radio"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={
                                  values.voluntary_insurance === "加入しない"
                                    ? true
                                    : false
                                }
                                isInvalid={
                                  touched.voluntary_insurance &&
                                  errors.voluntary_insurance
                                }
                                isValid={
                                  touched.voluntary_insurance &&
                                  !errors.voluntary_insurance
                                }
                              />
                              {touched.voluntary_insurance &&
                                errors.voluntary_insurance && (
                                  <p className="text-danger mb-0">
                                    <small> {errors.voluntary_insurance}</small>
                                  </p>
                                )}
                            </Col>
                          </Row>

                          <Row className="px-lg-3">
                            <Col
                              lg={3}
                              className={`bg-secondary bg-opacity-10 ${
                                width < 576 && `border`
                              } ${
                                width >= 576 && `border-bottom border-start `
                              } border-secondary p-2`}>
                              代犬・猫保証<span className="text-danger">※</span>
                            </Col>
                            <Col
                              lg={9}
                              className={`${
                                width < 576 && `border-start border-end`
                              } ${
                                width >= 576 &&
                                `border-start border-bottom border-end`
                              } p-1 border-secondary`}>
                              <Form.Check
                                inline
                                name="warranty"
                                id="warranty1"
                                label="加入する"
                                value="加入する"
                                type="radio"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={
                                  values.warranty === "加入する" ? true : false
                                }
                                isInvalid={touched.warranty && errors.warranty}
                                isValid={touched.warranty && !errors.warranty}
                              />
                              <Form.Check
                                inline
                                name="warranty"
                                id="warranty2"
                                label="加入しない"
                                value="加入しない"
                                type="radio"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={
                                  values.warranty === "加入しない"
                                    ? true
                                    : false
                                }
                                isInvalid={touched.warranty && errors.warranty}
                                isValid={touched.warranty && !errors.warranty}
                              />
                              {touched.warranty && errors.warranty && (
                                <p className="text-danger mb-0">
                                  <small> {errors.warranty}</small>
                                </p>
                              )}
                            </Col>
                          </Row>

                          <Row className="px-lg-3">
                            <Col
                              lg={3}
                              className={`bg-secondary bg-opacity-10 ${
                                width < 576 && `border`
                              } ${
                                width >= 576 && `border-bottom border-start `
                              } border-secondary p-2`}>
                              血統書<span className="text-danger">※</span>
                            </Col>
                            <Col
                              lg={9}
                              className={`${
                                width < 576 && `border-start border-end`
                              } ${
                                width >= 576 &&
                                `border-start border-bottom border-end`
                              } p-1 border-secondary`}>
                              <Row>
                                <Col xs={12}>
                                  <Form.Check
                                    inline
                                    name="pedigree"
                                    id="pedigree1"
                                    label="血統書付き"
                                    value="血統書付き"
                                    type="radio"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    checked={
                                      values.pedigree === "血統書付き"
                                        ? true
                                        : false
                                    }
                                    isInvalid={
                                      touched.pedigree && errors.pedigree
                                    }
                                    isValid={
                                      touched.pedigree && !errors.pedigree
                                    }
                                  />
                                  <Form.Check
                                    inline
                                    className="me-0"
                                    name="pedigree"
                                    id="pedigree2"
                                    label="血統書なし"
                                    value="血統書なし"
                                    type="radio"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    checked={
                                      values.pedigree === "血統書なし"
                                        ? true
                                        : false
                                    }
                                    isInvalid={
                                      touched.pedigree && errors.pedigree
                                    }
                                    isValid={
                                      touched.pedigree && !errors.pedigree
                                    }
                                  />

                                  {touched.pedigree && errors.pedigree && (
                                    <p className="text-danger mb-0">
                                      <small> {errors.pedigree}</small>
                                    </p>
                                  )}
                                </Col>
                                {values.pedigree === "血統書付き" && (
                                  <>
                                    <Col
                                      xs={12}
                                      className="my-1 position-relative"
                                      style={{ zIndex: 5 }}>
                                      <InputGroup hasValidation>
                                        <InputGroup.Text>
                                          発行団体名
                                        </InputGroup.Text>

                                        <Select
                                          className={`form-control py-0 ps-0  ${
                                            touched.pedigree_issue_org_name
                                              ? ""
                                              : "pe-0"
                                          } ${
                                            touched.pedigree_issue_org_name &&
                                            errors.pedigree_issue_org_name
                                              ? "is-invalid"
                                              : ""
                                          } ${
                                            touched.pedigree_issue_org_name &&
                                            !errors.pedigree_issue_org_name
                                              ? "is-valid"
                                              : ""
                                          }`}
                                          styles={customStyles}
                                          options={pedigreeIssueres}
                                          value={
                                            pedigreeIssueres &&
                                            pedigreeIssueres.map((item) => {
                                              if (
                                                item.value ==
                                                values.pedigree_issue_org_name
                                              ) {
                                                return item;
                                              }
                                            })
                                          }
                                          name="pedigree_issue_org_name"
                                          onChange={(e) => {
                                            setFieldValue(
                                              "pedigree_issue_org_name",
                                              e.value ? e.value : ""
                                            );
                                          }}
                                          onBlur={() => {
                                            setFieldTouched(
                                              "pedigree_issue_org_name",
                                              true
                                            );
                                          }}
                                          placeholder="▼選択してください"
                                        />
                                      </InputGroup>
                                      {values.pedigree_issue_org_name ===
                                        "その他" && (
                                        <>
                                          <Form.Control
                                            className="mt-1"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={
                                              values.pedigree_issue_org_name_other
                                            }
                                            placeholder="発行団体名を入力してください"
                                            name="pedigree_issue_org_name_other"
                                            required
                                            isInvalid={
                                              touched.pedigree_issue_org_name_other &&
                                              errors.pedigree_issue_org_name_other
                                            }
                                            isValid={
                                              touched.pedigree_issue_org_name_other &&
                                              !errors.pedigree_issue_org_name_other
                                            }
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            {
                                              errors.pedigree_issue_org_name_other
                                            }
                                          </Form.Control.Feedback>
                                        </>
                                      )}

                                      {touched.pedigree_issue_org_name &&
                                        errors.pedigree_issue_org_name && (
                                          <small className="text-danger">
                                            {errors.pedigree_issue_org_name}
                                            <br />
                                          </small>
                                        )}

                                      {/* </InputGroup> */}
                                    </Col>
                                    <Col xs={12}>
                                      <InputGroup hasValidation>
                                        <InputGroup.Text>
                                          送付日
                                        </InputGroup.Text>
                                        <DatePicker
                                          className={`form-control ${
                                            touched.pedigree_date &&
                                            errors.pedigree_date
                                              ? "is-invalid"
                                              : ""
                                          } ${
                                            touched.pedigree_date &&
                                            !errors.pedigree_date
                                              ? "is-valid"
                                              : ""
                                          }`}
                                          format="yyyy/MM/dd"
                                          locale="ja-JP"
                                          dayPlaceholder=" 日 "
                                          monthPlaceholder=" 月 "
                                          yearPlaceholder="年 "
                                          onChange={(val) => {
                                            const isValidDate = val && !isNaN(val);
                                            setFieldValue("pedigree_date", isValidDate ? val : "");
                                          }}
                                          onFocus={() => {
                                            setFieldTouched(
                                              "pedigree_date",
                                              true
                                            );
                                          }}
                                          value={values.pedigree_date}
                                          name="pedigree_date"
                                        />
                                        <InputGroup.Text>
                                          (予定)
                                        </InputGroup.Text>
                                        <Form.Control.Feedback type="invalid">
                                          {errors.pedigree_date}
                                        </Form.Control.Feedback>
                                      </InputGroup>
                                    </Col>
                                  </>
                                )}
                              </Row>
                            </Col>
                          </Row>

                          <Row className="px-lg-3">
                            <Col
                              lg={3}
                              className={`bg-secondary bg-opacity-10 ${
                                width < 576 && `border`
                              } ${
                                width >= 576 && `border-bottom border-start `
                              } border-secondary p-2`}>
                              指定獣医師<span className="text-danger">※</span>
                            </Col>
                            <Col
                              lg={9}
                              className={`${
                                width < 576 && `border-start border-end`
                              } ${
                                width >= 576 &&
                                `border-start border-bottom border-end`
                              } p-1 border-secondary`}>
                              <Form.Control
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.veterinarian}
                                name="veterinarian"
                                required
                                isInvalid={
                                  touched.veterinarian && errors.veterinarian
                                }
                                isValid={
                                  touched.veterinarian && !errors.veterinarian
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.veterinarian}
                              </Form.Control.Feedback>
                            </Col>
                          </Row>
                          <div className="text-center mt-4">
                            <CustomToggle eventKey="4">
                              <b>
                                次へ{" "}
                                <FontAwesomeIcon icon={faCircleChevronRight} />
                              </b>
                            </CustomToggle>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="4">
                        <Accordion.Header>
                          4. お客様のご確認{" "}
                          {touched.sales_person_name &&
                            errors.sales_person_name && (
                              <>
                                <SectionErrorMgs />
                              </>
                            )}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Row className="px-lg-3">
                            <Col
                              lg={3}
                              className={`bg-secondary bg-opacity-10 ${
                                width < 576 && `border`
                              } ${
                                width >= 576 &&
                                `border-bottom border-start border-top`
                              } border-secondary p-2`}>
                              販売・説明
                              <span className="text-nowrap">
                                担当者名<span className="text-danger">※</span>
                              </span>
                            </Col>
                            <Col
                              lg={9}
                              className={`${
                                width < 576 && `border-start border-end`
                              } ${
                                width >= 576 && `border`
                              } p-1  border-secondary`}>
                              <Form.Group>
                                <Form.Control
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.sales_person_name}
                                  name="sales_person_name"
                                  required
                                  isInvalid={
                                    touched.sales_person_name &&
                                    errors.sales_person_name
                                  }
                                  isValid={
                                    touched.sales_person_name &&
                                    !errors.sales_person_name
                                  }
                                />

                                <Form.Control.Feedback type="invalid">
                                  {errors.sales_person_name}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row className="px-lg-3">
                            <Col
                              lg={3}
                              className={`bg-secondary bg-opacity-10 ${
                                width < 576 && `border`
                              } ${
                                width >= 576 && `border-bottom border-start `
                              } border-secondary p-2`}>
                              署名<span className="text-danger">※</span>
                              {values && values.signature && (
                                <Button
                                  variant="secondary"
                                  onClick={() => {
                                    setModalSignShow(true);
                                  }}>
                                  もう一度記入する
                                </Button>
                              )}
                            </Col>
                            <Col
                              lg={9}
                              className={`${
                                width < 576 &&
                                `border-start border-bottom border-end`
                              } ${
                                width >= 576 &&
                                `border-start border-bottom border-end`
                              } p-1 border-secondary`}>
                              {values && values.signature ? (
                                <img
                                  src={values.signature}
                                  alt=""
                                  className={`w-100 ${styles.sigCanvas}`}
                                />
                              ) : (
                                <>
                                  <Button
                                    variant="light"
                                    onClick={() => {
                                      setModalSignShow(true);
                                    }}
                                    className={`${
                                      touched.signature && !errors.signature
                                        ? "form-control is-valid"
                                        : "form-control is-invalid"
                                    } ${styles.wUnset}`}>
                                    記入する{" "}
                                    <FontAwesomeIcon icon={faFileSignature} />
                                  </Button>

                                  {touched.signature && errors.signature && (
                                    <p className="text-danger mb-0">
                                      <small> {errors.signature}</small>
                                    </p>
                                  )}
                                </>
                              )}
                            </Col>
                          </Row>

                          <div className="text-center mt-4">
                            {!isPendingPDF && (
                              <Button
                                className="mt-1"
                                variant="primary"
                                type="button"
                                onClick={() => previewPdf(values)}>
                                <b>
                                  プレビュー{" "}
                                  <FontAwesomeIcon icon={faFileDownload} />
                                </b>
                              </Button>
                            )}

                            {isPendingPDF && (
                              <Button
                                className="mt-1"
                                variant="primary"
                                type="button"
                                disabled>
                                <b>
                                  プレビュー{" "}
                                  <FontAwesomeIcon icon={faFileDownload} />
                                </b>
                                {" "}<Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </Button>
                            )}

                            <Button
                              className="ms-2 mt-1"
                              variant="primary"
                              type="submit">
                              <b>
                                お支払{" "}
                                <FontAwesomeIcon icon={faCircleChevronRight} />
                              </b>
                            </Button>

                            {/* <Button
                            onClick={() => {
                              setModalShow(false);
                              setModalSignShow(true);
                            }}
                          >
                            ok and sign
                          </Button> */}
                          </div>

                          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                          <div id="my-container"></div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Form>

                  <Modal
                    show={modalSignShow}
                    onHide={() => {
                      setModalSignShow(false);
                    }}
                    dialogClassName={`m-0 bottom-0 position-fixed w-100 ${styles.wUnsetMax}`}
                    aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                        署名
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-0">
                      <div
                        ref={sigDiv}
                        className="mx-auto"
                        style={{ maxWidth: 660, height: "auto" }}>
                        <Ratio aspectRatio={0.2247}>
                          <SignatureCanvas
                            ref={sigCanvas}
                            canvasProps={{
                              width: width < 660 ? width : 660,
                              height:
                                width < 660 ? width * 0.2247 : 660 * 0.2247,
                              className: `${styles.sigCanvas} border`
                            }}
                          />
                        </Ratio>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        onClick={() => {
                          // console.log()
                          // setPdfvalues({
                          //   ...pdfvalues,
                          //   signature: sigCanvas.current.toDataURL("image/png")
                          // });
                          setFieldTouched("signature", true);
                          setFieldValue(
                            "signature",
                            sigCanvas.current.toDataURL("image/png")
                          );
                          setModalSignShow(false);
                        }}>
                        保存
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setModalSignShow(false);
                        }}>
                        閉じる
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>

      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        fullscreen={true}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            PDFプレビュー
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {pdfURL && (
            <PDFObject
              url={pdfURL}
              style={{ height: "100%" }}
              height="100%"
              pdfOpenParams={{}}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setModalShow(false);
              setModalSignShow(true);
            }}>
            記入し直す
          </Button>
          <Button
            onClick={() => {
              setModalShow(false);
            }}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        className="ql-snow "
        style={{ position: "absolute", left: "-9999999px" }}>
        <div className="ql-editor ql-container">
          <div id="reportDiv"></div>
        </div>
      </div>
    </>
  );
}

export default SellingContract;
