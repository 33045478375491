import React, { useEffect, useState } from "react";

import * as yup from "yup";
import { Formik } from "formik";
import valid from "card-validator";
import Cards from "react-credit-cards-2";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { useUpdateCustomer } from "../../hooks/komoju/useUpdateCustomer";
import { convertToFullYear, formatCreditCardNumber, formatExpirationDate } from "./Util";

import "react-credit-cards-2/dist/es/styles-compiled.css";

function CardRegistration({
  komoju_customer_id = null,
  returnUrl = null,
  isFinish = () => {},
  cancel = null,
  cancelFn = () => {}
}) {
  const [expiry, setExpiry] = useState("");
  const [focus, setFocus] = useState("");
  const [number, setNumber] = useState("");

  const navigate = useNavigate();
  const { updateCardInfo, isPending, isConfirmed, error, data } =
    useUpdateCustomer();
  const handleInputFocus = ({ target }) => {
    setFocus(target.name);
  };
  let initialValues = {
    cvc: "",
    expiry: "",
    name: "",
    number: "",
  };
  const schema = yup.object().shape({
    number: yup
      .string()
      .required("この欄は必須です")
      .test(
        "test-number",
        "カード番号が無効です", //validation message
        (value) => valid.number(value && value.replace(/ /g, "")).isValid
      ),

    name: yup.string().required("この欄は必須です"),

    expiry: yup
      .string()
      .required("この欄は必須です")
      .test(
        "test-expiry",
        "カードの有効期限が切れています",
        (value) => valid.expirationDate(value).isValid
      ),

    cvc: yup.string().required("この欄は必須です").min(3, "CVCは無効です"),
  });

  const handleCheckBox = ({ target }) => {
    if (target.name === "number") {
      setNumber(formatCreditCardNumber(target.value));
    } else if (target.name === "expiry") {
      setExpiry(formatExpirationDate(target.value));
    }

    // this.setState({ [target.name]: target.value });
  };

  useEffect(() => {
    if(isConfirmed) {
      isFinish(true)
    }
    if (returnUrl && isConfirmed) {
      if (data && data.id) {
        navigate(returnUrl);
      }
    }
  }, [isConfirmed, data]);

  const handelSubmit = (values) => {
    const patch_data = {
      payment_details: {
        name: values.name,
        number: values.number.replace(/ /g, ""),
        type: "credit_card",
        month: valid.expirationDate(values.expiry).month,
        year: convertToFullYear(
          valid.expirationDate(values.expiry).year
        ).toString(),
        verification_value: values.cvc.toString(),
      },
      metadata: {
        name: values.name,
      },
    };
    if (komoju_customer_id) {
      // console.log(komoju_customer_id, patch_data);
      updateCardInfo(komoju_customer_id, patch_data);
    } else {
      updateCardInfo(null, patch_data);
    }
  };
  return (
    <>
      <Container className="pt-3">
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handelSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Row className="justify-content-md-center">
              <Col lg={5} md={6}>
                <Row>
                  <Col
                    xs={12}
                    className="position-relative"
                    style={{ zIndex: 1 }}
                  >
                    <Cards
                      locale={{ valid: "有効期限" }}
                      cvc={values.cvc}
                      expiry={values.expiry}
                      focused={focus}
                      name={values.name}
                      number={values.number}
                    />
                  </Col>
                  <Col xs={12}>
                    <Card className="" style={{ marginTop: "-2rem" }}>
                      <Card.Body>
                        <br />
                        <br />
                        {((data && data.err) || error) && (
                          <Alert
                            variant="danger"
                            dismissible
                            className="text-center"
                          >
                            <p className="m-0">
                              {(data && data.err && data.err.message) || error}
                            </p>
                          </Alert>
                        )}

                        <Form noValidate onSubmit={handleSubmit}>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              カード番号 <span className="text-danger">※</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="•••• •••• •••• ••••"
                              onChange={(e) => {
                                handleChange(e);
                                handleCheckBox(e);
                              }}
                              onBlur={handleBlur}
                              onFocus={handleInputFocus}
                              value={number}
                              name="number"
                              required
                              isInvalid={touched.number && errors.number}
                              isValid={touched.number && !errors.number}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.number}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label>
                              カード名義 <span className="text-danger">※</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="半角"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onFocus={handleInputFocus}
                              value={values.name}
                              name="name"
                              isInvalid={touched.name && errors.name}
                              isValid={touched.name && !errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.name}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Row>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>
                                  有効期限{" "}
                                  <span className="text-danger">※</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="MM/YY"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleCheckBox(e);
                                  }}
                                  onBlur={handleBlur}
                                  onFocus={handleInputFocus}
                                  value={expiry}
                                  name="expiry"
                                  isInvalid={touched.expiry && errors.expiry}
                                  isValid={touched.expiry && !errors.expiry}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.expiry}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>
                                  CVC <span className="text-danger">※</span>
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="半角"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  onFocus={(e) => setFocus(e.target.name)}
                                  value={values.cvc}
                                  name="cvc"
                                  isInvalid={touched.cvc && errors.cvc}
                                  isValid={touched.cvc && !errors.cvc}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.cvc}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>

                          <div className="text-center">
                          {cancel && (
                              <Button
                                className="mb-2 me-1"
                                variant="danger"
                                onClick={cancelFn}
                              >
                              <FontAwesomeIcon icon={faTimesCircle} /> 取り消し
                            </Button>
                            )}
                            {!isPending && (
                              <Button
                                className="mb-2 ms-1"
                                variant="primary"
                                type="submit"
                              >
                                登録{" "}
                                <FontAwesomeIcon icon={faCircleArrowRight} />
                              </Button>
                            )}

                            {isPending && (
                              <Button
                                className="mb-2 ms-1"
                                variant="primary"
                                disabled
                              >
                                登録{" "}
                                <FontAwesomeIcon icon={faCircleArrowRight} />
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </Button>
                            )}
                          </div>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Formik>
      </Container>
    </>
  );
}

export default CardRegistration;
