import React from "react";
import moment from "moment";
import { Timestamp } from "firebase/firestore";
import { Badge, Button, ButtonGroup } from "react-bootstrap";
import { useUserDetailsContext } from "../context/UserDetailsContext";
import { useNavigate } from "react-router-dom";

export default function BreederMCInstallationListItem({ doc, modalFun }) {
  const navigate = useNavigate();
  const { userData } = useUserDetailsContext();

  const handleCertificate = () => {
    localStorage.setItem("mccode", JSON.stringify({ mccode: doc.mccode }));
    let initialValues = {
      mcdate: doc.mcdate,
      vet_institute: doc.vet_institute,
      vet_lastName: doc.vet_lastName,
      vet_firstName: doc.vet_firstName,
      vet_location_zip3: doc.vet_location_zip3,
      vet_location_zip4: doc.vet_location_zip4,
      vet_location_prefecture: doc.vet_location_prefecture,
      vet_location_addressLine1: doc.vet_location_addressLine1,
      vet_location_addressLine2: doc.vet_location_addressLine2,
      vet_location_addressLine3: doc.vet_location_addressLine3,
      vet_phone: doc.vet_phone,
      certificateDownloadLink: doc.certificateDownloadLink,

      ownership: "2",
      owner_type: userData.type,
      lastName: userData.type === "1" ? userData.lastName : "",
      firstName: userData.type === "1" ? userData.firstName : "",
      lastKana: userData.type === "1" ? userData.lastKana : "",
      firstKana: userData.type === "1" ? userData.firstKana : "",
      corporation_name: userData.type === "2" ? userData.corporation_name : "",
      corporation_kana_name:
        userData.type === "2" ? userData.corporation_kana_name : "",
      corporation_representative_firstName:
        userData.type === "2"
          ? userData.corporation_representative_firstName
          : "",
      corporation_representative_lastName:
        userData.type === "2"
          ? userData.corporation_representative_lastName
          : "",
      owner_location_zip3: userData.zip3,
      owner_location_zip4: userData.zip4,
      owner_location_prefecture: userData.prefecture,
      owner_location_addressLine1: userData.addressLine1,
      owner_location_addressLine2: userData.addressLine2,
      owner_location_addressLine3: userData.addressLine3,
      owner_fixed_phone: userData.fixed_phone,
      owner_phone: userData.phone,
      owner_email: userData.email,

      mccode: doc.mccode,
      animal_name: "",
      animal_group: "",
      animal_gender: "",
      animal_breed: "",
      animal_breed_other: "",
      animal_color: "",
      animal_color_other: "",
      animal_birthDay: "",
      animalAddressCheck: [],
      animal_location_zip3: "",
      animal_location_zip4: "",
      animal_location_prefecture: "",
      animal_location_addressLine1: "",
      animal_location_addressLine2: "",
      animal_location_addressLine3: "",
      animal_note: "",
      animal_handler_business_type: userData.animal_handler_business_type,
      animal_handle_1: [...userData.animal_handle_1],
      animal_handle_2: [...userData.animal_handle_2],
      animal_handler_business_type1_slot1:
        userData.animal_handler_business_type1_slot1
          ? userData.animal_handler_business_type1_slot1
          : "",
      animal_handler_business_type1_slot2:
        userData.animal_handler_business_type1_slot2
          ? userData.animal_handler_business_type1_slot2
          : "",
      animal_handler_business_type1_slot3:
        userData.animal_handler_business_type1_slot3
          ? userData.animal_handler_business_type1_slot3
          : "",
      animal_handler_business_type1_slot4:
        userData.animal_handler_business_type1_slot4
          ? userData.animal_handler_business_type1_slot4
          : "",
      animal_handler_business_type1_slot5:
        userData.animal_handler_business_type1_slot5
          ? userData.animal_handler_business_type1_slot5
          : "",
      animal_handler_business_type1_slot6:
        userData.animal_handler_business_type1_slot6
          ? userData.animal_handler_business_type1_slot6
          : "",
      animal_handler_business_type1_slot7:
        userData.animal_handler_business_type1_slot7
          ? userData.animal_handler_business_type1_slot7
          : "",
      animal_mother_time: "",
      animal_mother_mccode: "",
      animal_mother_mccode_not_reason: "",
    };

    localStorage.setItem("mcData", JSON.stringify(initialValues));
    localStorage.setItem(
      "mcinstal",
      JSON.stringify({
        status: true,
        mcinstallId: doc.id,
      })
    );

    navigate("/mypage/mcinput");
  };
  return (
    <>
      <tr>
        <td className="align-middle">
          <small>
            <Badge bg="secondary" className="text-white">
              {moment(
                new Timestamp(
                  doc.createdAt.seconds,
                  doc.createdAt.nanoseconds
                ).toDate()
              ).format("YYYY/MM/DD HH:mm")}
            </Badge>
          </small>
          <br />
          {doc.mccode}
        </td>
        <td className=" align-middle">
          {doc.vet_institute} <br />
          <b>
            {doc.vet_lastName} {doc.vet_firstName}
          </b>
        </td>

        <td className="text-center">
          <ButtonGroup className="me-2" aria-label="First group">
            <Button variant="secondary" onClick={() => modalFun(doc)}>
              詳細
            </Button>
            <Button variant="primary" onClick={handleCertificate}>
              進む
            </Button>
            <Button variant="danger" onClick={() => {}}>
              削除
            </Button>
          </ButtonGroup>
        </td>
      </tr>
    </>
  );
}
