import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Container,
  Form,
  Spinner,
  Table,
} from "react-bootstrap";
import sjcl from "sjcl";
import { Link } from "react-router-dom";
import { useCollectionDocs } from "../../hooks/useCollectionDocs";
import UserListItem from "../../components/UserListItem";

function UserList({ group }) {
  const { documents, isPending, lastDoc, isLast, getMultipleDocs } =
    useCollectionDocs();

  const [users, setUsers] = useState(false);
  
  const key = process.env.REACT_APP_SJCL_KEY;
  const users_data = localStorage.getItem(`A_U_${group}`);
  const users_data_ex = localStorage.getItem(`A_U_${group}_Ex`);
  const users_data_up = JSON.parse(localStorage.getItem(`A_U_${group}_Up`));

  useEffect(() => {
    if (documents) {
      // console.log(documents)

      let newUsers = users;
      documents.forEach((doc) => {
        newUsers = { ...newUsers, [doc.id]: doc };
      });
      setUsers(newUsers);
      // console.log(newUsers)
      const stringified = JSON.stringify(newUsers);

      const data = sjcl.encrypt(key, stringified);

      localStorage.setItem(`A_U_${group}`, data);
      localStorage.setItem(`A_U_${group}_Ex`, moment().format("YYYYMMDD"));


    }
  }, [documents]);


  useEffect(() => {
    let d_users_data;
    if (
      users_data &&
      users_data_ex &&
      parseInt(users_data_ex) === parseInt(moment().format("YYYYMMDD"))
    ) {
      d_users_data = JSON.parse(sjcl.decrypt(key, users_data));
      // console.log(d_users_data)
      // if (users_data_up) {
      //   let new_d_users_data = users || d_users_data;
      //   users_data_up.forEach(async (mccode) => {
      //     let data_Up = await getUpdatedMCDatabyMCCode(mccode);
      //     if (new_d_users_data[data_Up.id]) {
      //       new_d_users_data = { ...new_d_users_data, [data_Up.id]: data_Up };
      //     } else {
      //       new_d_users_data = { [data_Up.id]: data_Up, ...new_d_users_data };
      //     }
      //     setUsers(new_d_users_data);
      //     const stringified = JSON.stringify(new_d_users_data);
      //     const data = sjcl.encrypt(key, stringified);
      //     localStorage.setItem("MY_DATA_1", data);
      //   });
      //   localStorage.removeItem("MY_DATA_1_Up");
      // } else {
        setUsers(d_users_data);
      // }
      // console.log("+++++++++local+++++++++");
    } else {
      // console.log("---------server--------");
      setUsers([]);
      loadMore();
    }
  }, [group]);

  // useEffect(() => {
  //   setUsers([]);
  //   loadMore();
  // }, [group]);

  const loadMore = () => {
    getMultipleDocs({
      _collection: `users`,
      _query: {
        1: `group,==,${group}`
      },
      orderByField: "createdAt",
      orderByOption: "desc",
    });
  };

  let items = [];
  for (let i = 0; i < 10; i++) {
    items.push(
      <tr key={i} className="placeholder-glow">
        <td className="align-middle">
          <span className="placeholder col-8"></span>
        </td>
        <td className="text-center align-middle">
          <span className="placeholder col-8"></span>
        </td>
        <td className="text-center align-middle">
          <span className="placeholder col-2"></span>
        </td>
        <td className="text-center">
          <ButtonGroup className="me-2 col-5" aria-label="First group">
            <Button
              variant="secondary"
              className="placeholder"
              disabled
            ></Button>{" "}
            <Button variant="danger" className="placeholder" disabled></Button>{" "}
            <Button variant="success" className="placeholder" disabled></Button>
          </ButtonGroup>
        </td>
      </tr>
    );
  }
  return (
    <>
      <Container>
        {Object.values(users).length > 0 && (
          <>
            <Table striped bordered responsive="sm">
              <thead>
                <tr>
                  <th>名前</th>
                  <th className="text-center">ペット数</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Object.values(users) &&
                  Object.values(users).map((doc, i) => {
                    return <UserListItem doc={doc} key={i} />;
                  })}

                {isPending && items}
              </tbody>
            </Table>
            
          </>
        )}

        {!Object.values(users).length && !isPending && (
          <Alert variant="warning" className="text-center">
            You have not registered any microchip yet
          </Alert>
        )}
      </Container>
    </>
  );
}

export default UserList;
