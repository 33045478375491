import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

// import styles from "./Plans.module.css";
import CardDiv from "../../components/CardDiv";
import { useGetDoc } from "../../hooks/useGetDoc";
import ProgressStep from "../../components/ProgressStep";
import { useAuthContext } from "../../hooks/useAuthContext";
import CardRegistration from "../creditcard/CardRegistration";
// import { useUserDetailsContext } from "../../context/UserDetailsContext";
import { usePlanStart } from "../../hooks/usePlanStart";

function PlanPay({ komoju_customer_id, planid, planShowFn }) {
  const [changeCardDiv, setChangeCardDiv] = useState(false);
  const [plan, setPlan] = useState({});

  const { user } = useAuthContext();
  // const { userData } = useUserDetailsContext();
  const navigate = useNavigate();
  const {
    planStart,
    isPending: planPending,
    error: planError,
  } = usePlanStart();
  const { firebaseGetDoc, isPending, error } = useGetDoc();

  useEffect(() => {
    getPlanDetails();
  }, []);

  // get plan details function
  const getPlanDetails = async () => {
    const data = await firebaseGetDoc("plans", planid);
    if (data) {
      setPlan(data);
    }
  };

  // subscription to plan
  const planStartHandler = async () => {
    try {
      const planWithAid = JSON.parse(localStorage.getItem("aidItem")) || false;
      const res = await planStart({
        userID: user.uid,
        plan: planid,
        planWithAid
      });

      //redirect to success page
      if (res) {
        // console.log("redirect to success page");
        planShowFn(false);
        localStorage.removeItem("aidItem");
        navigate("/mypage/plan/thankyou");
      }
    } catch (error) {}
  };

  return (
    <>
      <div className={`h-100`}>
        <Container className="position-relative">
          <Row className="justify-content-center mb-4 mt-5 mt-md-0">
            <ProgressStep
              props={[
                { value: "互助制度の選択", link: "#" },
                { value: "プラン選択", link: "#" },
                { value: "支払", link: "#" },
                { value: "終了", link: "#" },
              ]}
              activeNo={3}
              varient={"white"}
            />
          </Row>
          <Row>
            <Col>
              <div className={`text-center p-3`}>
                {plan && plan.name && plan.monthly ? (
                  <>
                    <h4>{plan.name}</h4>
                    <p>
                      <span className="fs-2">¥</span>
                      <span className="fs-1">
                        <strong>
                          {parseInt(plan.monthly).toLocaleString()}
                        </strong>
                      </span>
                      <span className="fs-4">/月</span>
                    </p>
                  </>
                ) : (
                  <>
                    <br />
                    <Spinner
                      as="span"
                      animation="border"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />
                    <br />
                    <br />
                  </>
                )}
              </div>
            </Col>
            <Col lg={12} className="">
              {komoju_customer_id && !changeCardDiv && (
                <Row className="justify-content-center mb-4">
                  <Col lg={5} md={6}>
                    <Card>
                      <Card.Header>
                        <h5 className=" mb-0 text-center">支払い方法</h5>
                      </Card.Header>
                      <Card.Body>
                        <CardDiv komoju_customer_id={komoju_customer_id} />
                        <div className="text-center">
                          <Button
                            className="mt-3"
                            variant="warning"
                            onClick={() => setChangeCardDiv(!changeCardDiv)}>
                            編集 <FontAwesomeIcon icon={faEdit} />
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
              {(!komoju_customer_id || changeCardDiv) && (
                <>
                  <CardRegistration
                    komoju_customer_id={komoju_customer_id}
                    returnUrl={null}
                    isFinish={(status) => setChangeCardDiv(!status)}
                    cancel={changeCardDiv}
                    cancelFn={() => setChangeCardDiv(!changeCardDiv)}
                  />
                </>
              )}

              <div className="text-center mt-4">
                <Link className="btn btn-secondary m-2" to="/mypage/plan">
                  <FontAwesomeIcon icon={faCircleChevronLeft} /> 戻る
                </Link>
                {komoju_customer_id &&
                  !changeCardDiv &&
                  !(isPending || planPending) && (
                    <Button
                      className="btn btn-primary m-2"
                      onClick={planStartHandler}>
                      お支払 <FontAwesomeIcon icon={faCircleChevronRight} />
                    </Button>
                  )}

                {(isPending || planPending) && (
                  <Button className="btn btn-primary m-2" disabled>
                    お支払 <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Button>
                )}

                {(error || planError) && (
                  <Alert variant="danger" className="text-center">
                    {error || planError}
                  </Alert>
                )}
              </div>
            </Col>
          </Row>
        </Container>
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
}

export default PlanPay;
