import {
  faEgg,
  faGaugeHigh,
  faKey,
  faList,
  faMicrochip,
  faNewspaper,
  faPager,
  faPaw,
  faShop,
  faSignOut,
  faTag,
  faUserTie
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Helmet, HelmetData } from "react-helmet-async";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams
} from "react-router-dom";

import styles from "./Admin.module.css";
import MyPageHeader from "../../components/MyPageHeader";
import SideBar from "../../components/SideBar";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import AdminDashboard from "./AdminDashboard";
import ChangePassword from "../../components/ChangePassword";
import Logout from "../../components/Logout";
import UserList from "./UserList";
import Userdetails from "./Userdetails";
import TransferList from "./TransferList";
import CSVFileUpload from "./CSVFileUpload";
import ProcessCSV from "./ProcessCSV";
import CreateNews from "./CreateNews";
import NewsCheck from "./NewsCheck";
import NewsComplete from "./NewsComplete";
import NewsList from "./NewsList";
import MCListAdmin from "./MCListAdmin";
import UpdateMETA from "./UpdateMETA";
import SidebarBanner from "./SidebarBanner";
import RecommendedMc from "./Recommended/RecommendedMc";
import AddDetails from "./Recommended/AddDetails";
import PetDetails from "./Recommended/PetDetails";
import RecommendedConfirm from "./Recommended/RecommendedConfirm";
import ManageRecommendedMC from "./Recommended/ManageRecommendedMC";
import ManageMCs from "./Recommended/ManageMCs";

export default function AdminMyPage() {
  const [searchParams] = useSearchParams();
  const { userData } = useUserDetailsContext();
  const [sideBarShow, setSideBarShow] = useState(false);
  // const [activeMenu, setActiveMenu] = useState("dashboard");
  // const [pageTitle, setPageTitle] = useState("ダッシュボード");

  const [activeMenu, setActiveMenu] = useState("transferlist");
  const [pageTitle, setPageTitle] = useState("譲渡一覧");

  const menus = [
    // { link: "dashboard", icon: faGaugeHigh, text: "ダッシュボード" },
    { link: "transferlist", icon: faList, text: "譲渡一覧" },
    { link: "mclist", icon: faMicrochip, text: "登録情報一覧" },
    {
      link: "breederpetlist",
      icon: faPaw,
      text: "<small class='fs-10'>ブリーダー/ペットショップ</small >一覧",
      titleText: "ブリーダー/ペットショップ一覧"
    },
    { link: "ownerlist", icon: faShop, text: "飼い主一覧" },
    { link: "vetlist", icon: faUserTie, text: "獣医師一覧" },
    // { link: "cardinfo", icon: faCreditCard, text: "カード情報" },
    // {
    //   link: "paymenthistory",
    //   icon: faMoneyCheckDollar,
    //   text: "決済履歴・詳細",
    // },
    { link: "newslist", icon: faNewspaper, text: "お知らせ一覧" },
    { link: "changepassword", icon: faKey, text: "パスワード変更" },
    { link: "recommended-mc", icon: faMicrochip, text: "MC おすすめ" },
    { link: "manage-recommended-mc", icon: faMicrochip, text: "推奨MCの管理" },
    { link: "updateMETA", icon: faTag, text: "ページ情報設定" },
    { link: "sidebarBanner", icon: faPager, text: "広告バナー" },
    { link: "logout", icon: faSignOut, text: "ログアウト" }
  ];
  const sideBarShowHandlerFn = () => {
    setSideBarShow(!sideBarShow);
  };

  let location = useLocation();

  useEffect(() => {
    const loc = location.pathname.replace(/\/mypage\//g, "");
    if (loc && loc !== "/mypage") {
      setActiveMenu(loc);
      menus.map((menu) => {
        if (menu.link === loc) {
          setPageTitle(menu.titleText || menu.text);
        } else if (
          loc === "mcinput" ||
          loc === "mcinputcheck" ||
          loc === "mcpay" ||
          loc === "mcregistrationcomplete"
        ) {
          setPageTitle("MC・ペット情報登録");
          setActiveMenu("mcregistration");
        } else if (
          loc === "personalinfocheck" ||
          loc === "personalinfoconfirm"
        ) {
          setPageTitle("プロフィール編集");
          setActiveMenu("personalinfo");
        } else if (loc === "changecardinfo") {
          setPageTitle("カード情報");
          setActiveMenu("cardinfo");
        } else if (
          loc === "transferstart" ||
          loc === "transfercheck" ||
          loc === "transferinput" ||
          loc === "transferotp" ||
          loc === "transferconfirm" ||
          loc === "transferpay" ||
          loc === "transfercomplete"
        ) {
          setPageTitle("譲渡先");
          setActiveMenu("transfer");
        }
        return true;
      });
    }
  }, [location]);
  const helmetData = new HelmetData({});
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>{pageTitle}</title>
      </Helmet>
      <MyPageHeader
        sideBarShow={sideBarShow}
        sideBarShowHandler={sideBarShowHandlerFn}
        pageTitle={pageTitle}
      />
      <SideBar
        sideBarShow={sideBarShow}
        sideBarShowHandler={sideBarShowHandlerFn}
        activeMenu={activeMenu}
        menus={menus}
      />
      <div className={styles.mainBody}>
        <Routes>
          <Route path="/" element={<TransferList />} />
          <Route path="dashboard" element={<AdminDashboard />} />

          <Route path="transferlist" element={<TransferList />} />

          <Route path="mclist" element={<MCListAdmin />} />

          <Route
            path="breederpetlist"
            element={<UserList group={"breederpet"} />}
          />
          <Route path="ownerlist" element={<UserList group={"owner"} />} />
          <Route path="vetlist" element={<UserList group={"vet"} />} />
          <Route path="userdetails/:uid" element={<Userdetails />} />

          <Route path="newslist" element={<NewsList />} />
          <Route path="createnews" element={<CreateNews />} />
          <Route path="newsCheck" element={<NewsCheck />} />
          <Route path="newsComplete" element={<NewsComplete />} />

          <Route path="newsEdit" element={<CreateNews edit={true} />} />
          <Route path="newsEditCheck" element={<NewsCheck edit={true} />} />

          <Route path="changepassword" element={<ChangePassword />} />
          <Route path="updateMETA" element={<UpdateMETA />} />
          <Route path="recommended-mc" element={<RecommendedMc />} />
          <Route path="recommended-mc/pet-details" element={<PetDetails />} />
          <Route
            path="recommended-mc/add-pet-ad-details"
            element={<AddDetails />}
          />
          <Route
            path="recommended-mc/confirm"
            element={<RecommendedConfirm />}
          />
          <Route
            path="manage-recommended-mc"
            element={<ManageMCs />}
          />
          <Route path="sidebarBanner" element={<SidebarBanner />} />
          <Route path="logout" element={<Logout />} />
          <Route path="csvupload" element={<CSVFileUpload />} />
          <Route path="processcsv" element={<ProcessCSV />} />

          {/* <Route path="*" element={<Navigate to="/404" />} /> */}
        </Routes>
      </div>
    </>
  );
}
