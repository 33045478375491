import React, { useEffect, useRef, useState } from "react";

import _ from "lodash";
import * as yup from "yup";
import { Formik } from "formik";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronRight,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";

import ProgressStep from "../../../components/ProgressStep";
import AddressInput from "../../../components/AddressInput";
import getCroppedImgPercent from "../../../utils/CropperPercent";
import { useUserDetailsContext } from "../../../context/UserDetailsContext";
import {
  addressLine1Schema,
  addressLine2Schema,
  addressLine3Schema,
  numberOnlySchema,
  prefectureSchema,
  zip3Schema,
  zip4Schema
} from "../../../utils/InputSchema";

import "react-quill/dist/quill.snow.css";
import "react-image-crop/dist/ReactCrop.css";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function AddToListInput() {
  const navigate = useNavigate();
  const { userData } = useUserDetailsContext();

  const [crop, setCrop] = useState();
  const [show, setShow] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [img1, setImg1] = useState("");
  const [img2, setImg2] = useState("");
  const [img3, setImg3] = useState("");
  const [img4, setImg4] = useState("");
  const [img5, setImg5] = useState("");
  const [img6, setImg6] = useState("");
  const [imgid, setImgid] = useState("");
  const [imageToDelete, setImageToDelete] = useState([]);
  const [completedCrop, setCompletedCrop] = useState();
  const [zipError, setZipError] = useState(true);

  const imgRef = useRef(null);

  function cropImage(e, imgId) {
    if (e.target.files && e.target.files.length > 0) {
      setShow(true);
      setImgid(imgId);
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1));
  }

  async function onDownloadCropClick(imgId) {
    await getCroppedImgPercent(imgSrc, crop).then((url) => {
      // console.log(url);

      setShow(false);
      if (imgId == 1) {
        setImg1(url);
      } else if (imgId == 2) {
        setImg2(url);
      } else if (imgId == 3) {
        setImg3(url);
      } else if (imgId == 4) {
        setImg4(url);
      } else if (imgId == 5) {
        setImg5(url);
      } else if (imgId == 6) {
        setImg6(url);
      }
    });
  }

  //this is the config for rich text editor
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" }
      ],
      [{ color: [] }, { background: [] }]
    ]
  };

  // form config
  let initialValues = {
    product_type: "",
    puppy_type: "",
    guarantee: "",
    prPoint: "",
    sellPlatformPrice: "",
    weight_from: "",
    weight_to: "",
    height_from: "",
    height_to: "",
    sellPlatformDescription: "",
    image1: "",
    sellPlatform_zip3: "",
    sellPlatform_zip4: "",
    sellPlatform_prefecture: "",
    sellPlatform_addressLine1: "",
    sellPlatform_addressLine2: "",
    sellPlatform_addressLine3: ""
  };

  // load local values
  const [mcData, setMcData] = useState(
    JSON.parse(localStorage.getItem("mcData")) || false
  );
  const addtoShopData =
    JSON.parse(localStorage.getItem("addtoShopData")) || false;
  const addtoShopImages =
    JSON.parse(localStorage.getItem("addtoShopImages")) || false;

  if (mcData && !addtoShopData) {
    initialValues = {
      puppy_type: mcData.puppy_type || "",
      product_type: mcData.product_type || "",
      guarantee: mcData.guarantee || "",
      prPoint: mcData.prPoint || "",
      sellPlatformPrice: mcData.sellPlatformPrice || "",
      weight_from: mcData.weight_from || "",
      weight_to: mcData.weight_to || "",
      height_from: mcData.height_from || "",
      height_to: mcData.height_to || "",
      sellPlatformDescription: mcData.sellPlatformDescription || "",
      image1: "",
      sellPlatform_zip3:
        mcData.sellPlatform_zip3 ||
        mcData.mcData.animal_location_zip3 ||
        userData.zip3,
      sellPlatform_zip4:
        mcData.sellPlatform_zip4 ||
        mcData.mcData.animal_location_zip4 ||
        userData.zip4,
      sellPlatform_prefecture:
        mcData.sellPlatform_prefecture ||
        mcData.mcData.animal_location_prefecture ||
        userData.prefecture,
      sellPlatform_addressLine1:
        mcData.sellPlatform_addressLine1 ||
        mcData.mcData.animal_location_addressLine1 ||
        userData.addressLine1,
      sellPlatform_addressLine2:
        mcData.sellPlatform_addressLine2 ||
        mcData.mcData.animal_location_addressLine2 ||
        userData.addressLine2,
      sellPlatform_addressLine3:
        mcData.sellPlatform_addressLine3 ||
        mcData.mcData.animal_location_addressLine ||
        userData.addressLine3
    };
  }

  if (addtoShopData) {
    initialValues = {
      ...addtoShopData,
      image1: ""
    };
  }
  useEffect(() => {
    // guard for link access
    if (!mcData) {
      navigate("/mypage/addtolist");
      return;
    }
    if (addtoShopImages) {
      Object.values(addtoShopImages).forEach((url, imgId) => {
        if (imgId == 0) {
          setImg1(url);
        } else if (imgId == 1) {
          setImg2(url);
        } else if (imgId == 2) {
          setImg3(url);
        } else if (imgId == 3) {
          setImg4(url);
        } else if (imgId == 4) {
          setImg5(url);
        } else if (imgId == 5) {
          setImg6(url);
        }
      });
    }
  }, []);

  useEffect(() => {
    // console.log(mcData, imageToDelete);
  }, [mcData]);

  function noWhitespace() {
    return this.transform((value, originalValue) =>
      /\s/.test(originalValue) ? NaN : value
    );
  }

  yup.addMethod(yup.number, "noWhitespace", noWhitespace);
  const schema = yup.object().shape({
    guarantee: yup.string(),
    prPoint: yup.string(),
    puppy_type: yup.string(),
    product_type: yup.string(),
    sellPlatformPrice: yup
      .number()
      .typeError("半角整数値でご入力ください")
      .positive("半角整数値でご入力ください")
      .min(1, "1以上の値を選択して下さい")
      .integer("半角整数値でご入力ください")
      .noWhitespace()
      .when("product_type", {
        is: (product_type) => product_type === "子犬",
        then: yup
          .number()
          .typeError("半角整数値でご入力ください")
          .positive("半角整数値でご入力ください")
          .required("この欄は必須です")
          .min(1, "1以上の値を選択して下さい")
          .integer("半角整数値でご入力ください")
          .noWhitespace()
      }),
    weight_from: numberOnlySchema(998, 0.01, "FLOOT").required(
      "この欄は必須です"
    ),
    weight_to: numberOnlySchema(999, 0.01, "FLOOT").moreThan(
      yup.ref("weight_from"),
      ({ more }) => `${more || 1}より大きくなければなりません`
    ),
    height_from: numberOnlySchema(998).required("この欄は必須です"),
    height_to: numberOnlySchema(999).moreThan(
      yup.ref("height_from"),
      ({ more }) => `${more || 1}より大きくなければなりません`
    ),
    sellPlatformDescription: yup
      .string()
      .required("この欄は必須です")
      .max(1000, "最大文字数に達しました"),
    image1: yup.string().when("images", {
      is: () => img1 == "" && !(mcData.images && mcData.images[0]),
      then: yup.string().required("この欄は必須です")
    }),
    sellPlatform_zip3: zip3Schema(zipError),
    sellPlatform_zip4: zip4Schema(zipError),
    sellPlatform_prefecture: prefectureSchema(),
    sellPlatform_addressLine1: addressLine1Schema(),
    sellPlatform_addressLine2: addressLine2Schema(),
    sellPlatform_addressLine3: addressLine3Schema()
  });

  const handleSubmit = (values) => {
    try {
      const serializedState = JSON.stringify(values);
      localStorage.setItem("addtoShopData", serializedState);
      localStorage.setItem("mcData", JSON.stringify(mcData));
      localStorage.setItem("imageToDelete", JSON.stringify(imageToDelete));
      localStorage.setItem(
        "addtoShopImages",
        JSON.stringify({ img1, img2, img3, img4, img5, img6 })
      );
      navigate("/mypage/addtolistconfirm");
    } catch (err) {
      return undefined;
    }
  };

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "スキャン", link: "/mypage/addtolist" },
              { value: "入力", link: "#" },
              { value: "確認", link: "#" },
              { value: "終了", link: "#" }
            ]}
            activeNo={2}
          />
        </Row>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldTouched,
            setFieldValue
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="justify-content-center">
                <Col xl={8} lg={10} sm={12} className="mb-3">
                  <Form.Check
                    inline
                    name="puppy_type"
                    id="puppy_type"
                    label="子犬"
                    value="子犬"
                    type="checkbox"
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.checked) {
                        setFieldValue("puppy_type", "子犬");
                      }else{
                        setFieldValue("puppy_type", "");
                      }
                    }}
                    onBlur={handleBlur}
                    checked={values.puppy_type === "子犬" ? true : false}
                    isInvalid={touched.puppy_type && errors.puppy_type}
                    isValid={touched.puppy_type && !errors.puppy_type}
                  />
                  <Form.Check
                    inline
                    name="product_type"
                    id="product_type"
                    label="里親"
                    value="里親"
                    type="checkbox"
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.checked) {
                        setFieldValue("product_type", "里親");
                      }else{
                        setFieldValue("product_type", "");
                      }
                    }}
                    onBlur={handleBlur}
                    checked={values.product_type === "里親" ? true : false}
                    isInvalid={touched.product_type && errors.product_type}
                    isValid={touched.product_type && !errors.product_type}
                  />

                  {touched.product_type && errors.product_type && (
                    <div className="text-danger">{errors.product_type}</div>
                  )}
                </Col>
                <Col xl={8} lg={10} sm={12} className="mb-3">
                  <Form.Label>生体保証</Form.Label>
                  <InputGroup className="mb-3" hasValidation>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.guarantee}
                      name="guarantee"
                      autoComplete="off"
                      isInvalid={touched.guarantee && errors.guarantee}
                      isValid={touched.guarantee && !errors.guarantee}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.guarantee}
                    </Form.Control.Feedback>
                  </InputGroup>

                  <Form.Label>アピールポイント</Form.Label>
                  <InputGroup className="mb-3" hasValidation>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.prPoint}
                      name="prPoint"
                      autoComplete="off"
                      isInvalid={touched.prPoint && errors.prPoint}
                      isValid={touched.prPoint && !errors.prPoint}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.prPoint}
                    </Form.Control.Feedback>
                  </InputGroup>

                  <Form.Label>
                    価格
                    {values.product_type !== "里親" && (
                      <span className="text-danger">※</span>
                    )}
                  </Form.Label>
                  <InputGroup className="mb-3" hasValidation>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        handleChange(e);
                        const trimmedValue = e.target.value.trim();
                        setFieldValue(e.target.name, trimmedValue);
                      }}
                      onBlur={handleBlur}
                      value={values.sellPlatformPrice}
                      name="sellPlatformPrice"
                      autoComplete="off"
                      required
                      isInvalid={
                        touched.sellPlatformPrice && errors.sellPlatformPrice
                      }
                      isValid={
                        touched.sellPlatformPrice && !errors.sellPlatformPrice
                      }
                    />
                    <InputGroup.Text>円 (税込)</InputGroup.Text>
                    <Form.Control.Feedback type="invalid">
                      {errors.sellPlatformPrice}
                    </Form.Control.Feedback>
                  </InputGroup>

                  <label htmlFor="" className="mb-2">
                    標準体重
                    <span className="text-danger">※</span>
                  </label>
                  <InputGroup hasValidation className="mb-3">
                    <Form.Control
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.weight_from}
                      name="weight_from"
                      maxLength={4}
                      required
                      isInvalid={touched.weight_from && errors.weight_from}
                      isValid={touched.weight_from && !errors.weight_from}
                    />

                    <InputGroup.Text>kg ~</InputGroup.Text>

                    <Form.Control
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.weight_to}
                      name="weight_to"
                      maxLength={4}
                      isInvalid={touched.weight_to && errors.weight_to}
                      isValid={touched.weight_to && !errors.weight_to}
                    />
                    <InputGroup.Text>kg</InputGroup.Text>

                    <Form.Control.Feedback type="invalid">
                      <p className="mb-0">
                        {errors.weight_from &&
                          `ハイフンの前には: ${errors.weight_from}`}
                      </p>
                      <p className="mb-0">
                        {errors.weight_to &&
                          `ハイフンの後には: ${errors.weight_to}`}
                      </p>
                    </Form.Control.Feedback>
                  </InputGroup>

                  <label htmlFor="" className="mb-2">
                    標準体高
                    <span className="text-danger">※</span>
                  </label>
                  <InputGroup hasValidation className="mb-3">
                    <Form.Control
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.height_from}
                      name="height_from"
                      maxLength={4}
                      required
                      isInvalid={touched.height_from && errors.height_from}
                      isValid={touched.height_from && !errors.height_from}
                    />

                    <InputGroup.Text>cm ~</InputGroup.Text>

                    <Form.Control
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.height_to}
                      name="height_to"
                      maxLength={4}
                      isInvalid={touched.height_to && errors.height_to}
                      isValid={touched.height_to && !errors.height_to}
                    />
                    <InputGroup.Text>cm</InputGroup.Text>

                    <Form.Control.Feedback type="invalid">
                      <p className="mb-0">
                        {errors.height_from &&
                          `ハイフンの前には: ${errors.height_from}`}
                      </p>
                      <p className="mb-0">
                        {errors.height_to &&
                          `ハイフンの後には: ${errors.height_to}`}
                      </p>
                    </Form.Control.Feedback>
                  </InputGroup>
                  {/* </Col> */}
                  {/* </Row> */}
                </Col>
                <Col
                  xl={8}
                  lg={10}
                  sm={12}
                  className="mb-3 bg-primary bg-opacity-10 py-3 border border-primary rounded ">
                  <h4 className="text-center">
                    <Badge bg="dark bg-opacity-50">見学地域</Badge>
                  </h4>
                  <AddressInput
                    prefix="sellPlatform_"
                    setZipError={setZipError}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xl={8} lg={10} sm={12} className="mb-2">
                  <label className="mb-2">
                    子犬紹介文
                    <span className="text-danger">※</span>
                  </label>
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    value={values.sellPlatformDescription}
                    onChange={(value, _, __, editor) => {
                      // console.log(value)
                      if (editor.getLength() === 1) {
                        setFieldValue("sellPlatformDescription", "");
                      } else {
                        setFieldValue("sellPlatformDescription", value);
                      }
                    }}
                    onBlur={() => {
                      setFieldTouched("sellPlatformDescription", true);
                    }}
                    className={
                      touched.sellPlatformDescription &&
                      errors.sellPlatformDescription
                        ? "border border-danger"
                        : ""
                    }
                  />
                  {touched.sellPlatformDescription &&
                    errors.sellPlatformDescription && (
                      <small className="text-danger">
                        {errors.sellPlatformDescription}
                      </small>
                    )}
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xl={8} lg={10} sm={12}>
                  <Row className="justify-content-center">
                    <Col md={6} sm={12}>
                      <Form.Group controlId="formFile" className="mb-3 img-1">
                        <Form.Label>
                          写真1
                          <span className="text-danger">※</span>
                        </Form.Label>
                        {img1 == "" && !(mcData.images && mcData.images[0]) ? (
                          <>
                            <Form.Control
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                //setFieldValue("image1", "");
                                cropImage(e, 1);
                              }}
                              onBlur={handleBlur}
                              value={img1}
                              name="image1"
                              autoComplete="off"
                              required
                              isInvalid={touched.image1 && errors.image1}
                              isValid={touched.image1 && !errors.image1}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.image1}
                            </Form.Control.Feedback>
                          </>
                        ) : img1 ? (
                          <div className="position-relative">
                            <img src={img1} className="w-100 shadow rounded" />
                            <Button
                              variant="danger"
                              className="position-absolute top-0 end-0 me-1 mt-1"
                              onClick={() => {
                                setImg1("");
                                setFieldValue("image1", "");
                                setFieldTouched("image1", false);
                              }}>
                              <FontAwesomeIcon
                                icon={faTrashAlt}></FontAwesomeIcon>{" "}
                              削除
                            </Button>
                          </div>
                        ) : (
                          <div className="position-relative">
                            <img
                              src={mcData.images && mcData.images[0]}
                              className="w-100 shadow rounded"
                            />
                            <Button
                              variant="danger"
                              className="position-absolute top-0 end-0 me-1 mt-1"
                              onClick={() => {
                                //set for delete
                                setImageToDelete([
                                  ...imageToDelete,
                                  mcData.images[0]
                                ]);
                                //update the mcData
                                _.pullAt(mcData.images, [0]);
                                setMcData({
                                  ...mcData
                                });
                                setFieldValue("image1", "");
                                setFieldTouched("image1", false);
                              }}>
                              <FontAwesomeIcon
                                icon={faTrashAlt}></FontAwesomeIcon>{" "}
                              削除
                            </Button>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group controlId="formFile" className="mb-3 img-1">
                        <Form.Label>写真2</Form.Label>
                        {img2 == "" && !(mcData.images && mcData.images[1]) ? (
                          <Form.Control
                            type="file"
                            accept="image/*"
                            value=""
                            onChange={(e) => {
                              cropImage(e, 2);
                            }}
                          />
                        ) : img2 ? (
                          <div className="position-relative">
                            <img src={img2} className="w-100 shadow rounded" />
                            <Button
                              variant="danger"
                              className="position-absolute top-0 end-0 me-1 mt-1"
                              onClick={() => setImg2("")}>
                              <FontAwesomeIcon
                                icon={faTrashAlt}></FontAwesomeIcon>{" "}
                              削除
                            </Button>
                          </div>
                        ) : (
                          <div className="position-relative">
                            <img
                              src={mcData.images && mcData.images[1]}
                              className="w-100 shadow rounded"
                            />
                            <Button
                              variant="danger"
                              className="position-absolute top-0 end-0 me-1 mt-1"
                              onClick={() => {
                                //set for delete
                                setImageToDelete([
                                  ...imageToDelete,
                                  mcData.images[1]
                                ]);
                                //update the mcData
                                _.pullAt(mcData.images, [1]);
                                setMcData({
                                  ...mcData
                                });
                              }}>
                              <FontAwesomeIcon
                                icon={faTrashAlt}></FontAwesomeIcon>{" "}
                              削除
                            </Button>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group controlId="formFile" className="mb-3 img-1">
                        <Form.Label>写真3</Form.Label>
                        {img3 == "" && !(mcData.images && mcData.images[2]) ? (
                          <Form.Control
                            type="file"
                            accept="image/*"
                            value=""
                            onChange={(e) => {
                              cropImage(e, 3);
                            }}
                          />
                        ) : img3 ? (
                          <div className="position-relative">
                            <img src={img3} className="w-100 shadow rounded" />
                            <Button
                              variant="danger"
                              className="position-absolute top-0 end-0 me-1 mt-1"
                              onClick={() => setImg3("")}>
                              <FontAwesomeIcon
                                icon={faTrashAlt}></FontAwesomeIcon>{" "}
                              削除
                            </Button>
                          </div>
                        ) : (
                          <div className="position-relative">
                            <img
                              src={mcData.images && mcData.images[2]}
                              className="w-100 shadow rounded"
                            />
                            <Button
                              variant="danger"
                              className="position-absolute top-0 end-0 me-1 mt-1"
                              onClick={() => {
                                //set for delete
                                setImageToDelete([
                                  ...imageToDelete,
                                  mcData.images[2]
                                ]);
                                //update the mcData
                                _.pullAt(mcData.images, [2]);
                                setMcData({
                                  ...mcData
                                });
                              }}>
                              <FontAwesomeIcon
                                icon={faTrashAlt}></FontAwesomeIcon>{" "}
                              削除
                            </Button>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group controlId="formFile" className="mb-3 img-1">
                        <Form.Label>写真4</Form.Label>
                        {img4 == "" && !(mcData.images && mcData.images[3]) ? (
                          <Form.Control
                            type="file"
                            accept="image/*"
                            value=""
                            onChange={(e) => {
                              cropImage(e, 4);
                            }}
                          />
                        ) : img4 ? (
                          <div className="position-relative">
                            <img src={img4} className="w-100 shadow rounded" />
                            <Button
                              variant="danger"
                              className="position-absolute top-0 end-0 me-1 mt-1"
                              onClick={() => setImg4("")}>
                              <FontAwesomeIcon
                                icon={faTrashAlt}></FontAwesomeIcon>{" "}
                              削除
                            </Button>
                          </div>
                        ) : (
                          <div className="position-relative">
                            <img
                              src={mcData.images && mcData.images[3]}
                              className="w-100 shadow rounded"
                            />
                            <Button
                              variant="danger"
                              className="position-absolute top-0 end-0 me-1 mt-1"
                              onClick={() => {
                                //set for delete
                                setImageToDelete([
                                  ...imageToDelete,
                                  mcData.images[3]
                                ]);
                                //update the mcData
                                _.pullAt(mcData.images, [3]);
                                setMcData({
                                  ...mcData
                                });
                              }}>
                              <FontAwesomeIcon
                                icon={faTrashAlt}></FontAwesomeIcon>{" "}
                              削除
                            </Button>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group controlId="formFile" className="mb-3 img-1">
                        <Form.Label>写真5</Form.Label>
                        {img5 == "" && !(mcData.images && mcData.images[4]) ? (
                          <Form.Control
                            type="file"
                            accept="image/*"
                            value=""
                            onChange={(e) => {
                              cropImage(e, 5);
                            }}
                          />
                        ) : img5 ? (
                          <div className="position-relative">
                            <img src={img5} className="w-100 shadow rounded" />
                            <Button
                              variant="danger"
                              className="position-absolute top-0 end-0 me-1 mt-1"
                              onClick={() => setImg5("")}>
                              <FontAwesomeIcon
                                icon={faTrashAlt}></FontAwesomeIcon>{" "}
                              削除
                            </Button>
                          </div>
                        ) : (
                          <div className="position-relative">
                            <img
                              src={mcData.images && mcData.images[4]}
                              className="w-100 shadow rounded"
                            />
                            <Button
                              variant="danger"
                              className="position-absolute top-0 end-0 me-1 mt-1"
                              onClick={() => {
                                //set for delete
                                setImageToDelete([
                                  ...imageToDelete,
                                  mcData.images[4]
                                ]);
                                //update the mcData
                                _.pullAt(mcData.images, [4]);
                                setMcData({
                                  ...mcData
                                });
                              }}>
                              <FontAwesomeIcon
                                icon={faTrashAlt}></FontAwesomeIcon>{" "}
                              削除
                            </Button>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group controlId="formFile" className="mb-3 img-1">
                        <Form.Label>写真6</Form.Label>
                        {img6 == "" && !(mcData.images && mcData.images[5]) ? (
                          <Form.Control
                            type="file"
                            accept="image/*"
                            value=""
                            onChange={(e) => {
                              cropImage(e, 6);
                            }}
                          />
                        ) : img6 ? (
                          <div className="position-relative">
                            <img src={img6} className="w-100 shadow rounded" />
                            <Button
                              variant="danger"
                              className="position-absolute top-0 end-0 me-1 mt-1"
                              onClick={() => setImg6("")}>
                              <FontAwesomeIcon
                                icon={faTrashAlt}></FontAwesomeIcon>{" "}
                              削除
                            </Button>
                          </div>
                        ) : (
                          <div className="position-relative">
                            <img
                              src={mcData.images && mcData.images[5]}
                              className="w-100 shadow rounded"
                            />
                            <Button
                              variant="danger"
                              className="position-absolute top-0 end-0 me-1 mt-1"
                              onClick={() => {
                                //set for delete
                                setImageToDelete([
                                  ...imageToDelete,
                                  mcData.images[5]
                                ]);
                                //update the mcData
                                _.pullAt(mcData.images, [5]);
                                setMcData({
                                  ...mcData
                                });
                              }}>
                              <FontAwesomeIcon
                                icon={faTrashAlt}></FontAwesomeIcon>{" "}
                              削除
                            </Button>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <div className="text-center mt-4">
                      <Button className="mb-3" variant="primary" type="submit">
                        <b>
                          次へ <FontAwesomeIcon icon={faCircleChevronRight} />
                        </b>
                      </Button>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            画像を編集
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={4 / 3}>
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            閉じる
          </Button>
          {!!completedCrop && (
            <Button
              onClick={() => {
                onDownloadCropClick(imgid);
              }}>
              切り抜き
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddToListInput;
