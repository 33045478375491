import React, { useRef, useState } from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import { useFormikContext } from "formik";
import { Col, Form, InputGroup, Row, Button, Modal } from "react-bootstrap";
import _ from "lodash";
import AddressInput from "../../components/AddressInput";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import getCroppedImgPercent from "../../utils/CropperPercent";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-image-crop/dist/ReactCrop.css";
import { useAuthContext } from "../../hooks/useAuthContext";

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

function SignupInput({ zipError, ownership, edit = false }) {
  const cropImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setShow(true);
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 16 / 9));
  };

  const onDownloadCropClick = async () => {
    await getCroppedImgPercent(imgSrc, crop).then((url) => {
      setShow(false);
      setImg(url);
      localStorage.setItem("signUpInputImg", url);
    });
  };

  const [show, setShow] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const { width } = useWindowDimensions();
  const {
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    values,
    touched,
    errors
  } = useFormikContext();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordC, setShowPasswordC] = useState(false);
  const [completedCrop, setCompletedCrop] = useState();
  const [img, setImg] = useState(values.img || "");
  const imgRef = useRef(null);
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" }
      ],
      [{ color: [] }, { background: [] }]
    ]
  };

  const { user } = useAuthContext();
  return (
    <>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-top border-start `
          } border-secondary p-2`}
        >
          個人または法人 <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-top border-start border-bottom border-end`
          } p-1 border-end border-secondary`}
        >
          <Form.Check
            inline
            id="type1"
            name="type"
            label="個人"
            type="radio"
            value="1"
            onChange={handleChange}
            onBlur={handleBlur}
            checked={values.type === "1" ? true : false}
            isInvalid={touched.type && errors.type}
            isValid={touched.type && !errors.type}
          />
          <Form.Check
            inline
            id="type2"
            name="type"
            label="法人"
            type="radio"
            value="2"
            onChange={handleChange}
            onBlur={handleBlur}
            checked={values.type === "2" ? true : false}
            isInvalid={touched.type && errors.type}
            isValid={touched.type && !errors.type}
          />
          {touched.type && errors.type && (
            <div className="text-danger">{errors.type}</div>
          )}
        </Col>
      </Row>
      {values.type === "1" && (
        <>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              氏名 <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              <Row>
                <Col lg={6} className="pe-sm-0 pb-sm-0 pb-1">
                  <Form.Control
                    type="text"
                    placeholder="姓"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    name="lastName"
                    maxLength={14}
                    isInvalid={touched.lastName && errors.lastName}
                    isValid={touched.lastName && !errors.lastName}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lastName}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="ps-sm-1">
                  <Form.Control
                    type="text"
                    placeholder="名"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    name="firstName"
                    maxLength={14}
                    isInvalid={touched.firstName && errors.firstName}
                    isValid={touched.firstName && !errors.firstName}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              氏名（フリガナ） <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              <Row>
                <Col lg={6} className="pe-sm-0 pb-sm-0 pb-1">
                  <Form.Control
                    type="text"
                    placeholder="セイ"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastKana}
                    name="lastKana"
                    maxLength={14}
                    isInvalid={touched.lastKana && errors.lastKana}
                    isValid={touched.lastKana && !errors.lastKana}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lastKana}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="ps-sm-1">
                  <Form.Control
                    type="text"
                    placeholder="メイ"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstKana}
                    name="firstKana"
                    maxLength={14}
                    isInvalid={touched.firstKana && errors.firstKana}
                    isValid={touched.firstKana && !errors.firstKana}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstKana}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
      {values.type === "2" && (
        <>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              法人名 <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              <Form.Control
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.corporation_name}
                name="corporation_name"
                maxLength={30}
                isInvalid={touched.corporation_name && errors.corporation_name}
                isValid={touched.corporation_name && !errors.corporation_name}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.corporation_name}
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              法人名(フリガナ) <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              <Form.Control
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.corporation_kana_name}
                name="corporation_kana_name"
                maxLength={30}
                isInvalid={
                  touched.corporation_kana_name && errors.corporation_kana_name
                }
                isValid={
                  touched.corporation_kana_name && !errors.corporation_kana_name
                }
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.corporation_kana_name}
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              代表者の氏名 <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              <Row>
                <Col lg={6} className="pe-sm-0 pb-sm-0 pb-1">
                  <Form.Control
                    type="text"
                    placeholder="姓"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.corporation_representative_lastName}
                    name="corporation_representative_lastName"
                    maxLength={14}
                    isInvalid={
                      touched.corporation_representative_lastName &&
                      errors.corporation_representative_lastName
                    }
                    isValid={
                      touched.corporation_representative_lastName &&
                      !errors.corporation_representative_lastName
                    }
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.corporation_representative_lastName}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="ps-sm-1">
                  <Form.Control
                    type="text"
                    placeholder="名"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.corporation_representative_firstName}
                    name="corporation_representative_firstName"
                    maxLength={14}
                    isInvalid={
                      touched.corporation_representative_firstName &&
                      errors.corporation_representative_firstName
                    }
                    isValid={
                      touched.corporation_representative_firstName &&
                      !errors.corporation_representative_firstName
                    }
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.corporation_representative_firstName}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              ご担当者様の氏名 <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              <Row>
                <Col lg={6} className="pe-sm-0 pb-sm-0 pb-1">
                  <Form.Control
                    type="text"
                    placeholder="姓"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.corporation_personincharge_lastName}
                    name="corporation_personincharge_lastName"
                    maxLength={14}
                    isInvalid={
                      touched.corporation_personincharge_lastName &&
                      errors.corporation_personincharge_lastName
                    }
                    isValid={
                      touched.corporation_personincharge_lastName &&
                      !errors.corporation_personincharge_lastName
                    }
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.corporation_personincharge_lastName}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="ps-sm-1">
                  <Form.Control
                    type="text"
                    placeholder="名"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.corporation_personincharge_firstName}
                    name="corporation_personincharge_firstName"
                    maxLength={14}
                    isInvalid={
                      touched.corporation_personincharge_firstName &&
                      errors.corporation_personincharge_firstName
                    }
                    isValid={
                      touched.corporation_personincharge_firstName &&
                      !errors.corporation_personincharge_firstName
                    }
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.corporation_personincharge_firstName}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          住所<span className="text-danger">※</span>
          <br />
          (法人にあっては、事務所の所在地)
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <AddressInput prefix="" setZipError={zipError} />
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          性別 <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <div>
            <Form.Check
              inline
              name="gender"
              id="gender1"
              label="男性"
              value="男性"
              type="radio"
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.gender === "男性" ? true : false}
              isInvalid={touched.gender && errors.gender}
              isValid={touched.gender && !errors.gender}
            />
            <Form.Check
              inline
              name="gender"
              id="gender2"
              label="女性"
              value="女性"
              type="radio"
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.gender === "女性" ? true : false}
              isInvalid={touched.gender && errors.gender}
              isValid={touched.gender && !errors.gender}
            />

            <Form.Check
              inline
              name="gender"
              id="gender3"
              label="指定なし"
              value="指定なし"
              type="radio"
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.gender === "指定なし" ? true : false}
              isInvalid={touched.gender && errors.gender}
              isValid={touched.gender && !errors.gender}
            />
          </div>

          {touched.gender && errors.gender && (
            <div className="text-danger">{errors.gender}</div>
          )}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          生年月日
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <DatePicker
            className={`form-control ${
              touched.birthDay && errors.birthDay ? "is-invalid" : ""
            } ${touched.birthDay && !errors.birthDay ? "is-valid" : ""}`}
            format="yyyy/MM/dd"
            locale="ja-JP"
            dayPlaceholder=" 日 "
            monthPlaceholder=" 月 "
            yearPlaceholder="年 "
            onChange={(val) => {
              setFieldValue("birthDay", val);
            }}
            onFocus={() => {
              setFieldTouched("birthDay", true);
            }}
            value={values.birthDay}
            name="birthDay"
          />
          <Form.Control.Feedback type="invalid">
            {errors.birthDay}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          電話番号
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <Form.Control
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.fixed_phone}
            name="fixed_phone"
            maxLength={14}
            isInvalid={touched.fixed_phone && errors.fixed_phone}
            isValid={touched.fixed_phone && !errors.fixed_phone}
            placeholder="例: 03-6794-0967"
          />
          <Form.Control.Feedback type="invalid">
            {errors.fixed_phone}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          携帯電話番号 <span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <Form.Control
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone}
            name="phone"
            maxLength={14}
            isInvalid={touched.phone && errors.phone}
            isValid={touched.phone && !errors.phone}
            placeholder="例: 090-0000-0000"
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.phone}
          </Form.Control.Feedback>
        </Col>
      </Row>
      {!edit && (
        <>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              Eメールアドレス <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              <Form.Control
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                name="email"
                maxLength={100}
                isInvalid={touched.email && errors.email}
                isValid={touched.email && !errors.email}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              Eメールアドレス(確認用) <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              <Form.Control
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.emailConfirm}
                name="emailConfirm"
                maxLength={150}
                required
                isInvalid={touched.emailConfirm && errors.emailConfirm}
                isValid={touched.emailConfirm && !errors.emailConfirm}
              />
              <Form.Control.Feedback type="invalid">
                {errors.emailConfirm}
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              ユーザーネーム <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              <Form.Control
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lookforusername}
                name="lookforusername"
                maxLength={100}
                isInvalid={touched.lookforusername && errors.lookforusername}
                isValid={touched.lookforusername && !errors.lookforusername}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.lookforusername}
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              パスワード <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              <InputGroup>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="半角英数字・記号・8文字以上"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  name="password"
                  maxLength={150}
                  required
                  isInvalid={touched.password && errors.password}
                  isValid={touched.password && !errors.password}
                />
                <InputGroup.Text
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={!showPassword ? faEyeSlash : faEye} />
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              パスワード(確認用) <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              <InputGroup>
                <Form.Control
                  type={showPasswordC ? "text" : "password"}
                  placeholder="半角英数字・記号・8文字以上"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.passwordConfirm}
                  name="passwordConfirm"
                  maxLength={150}
                  isInvalid={touched.passwordConfirm && errors.passwordConfirm}
                  isValid={touched.passwordConfirm && !errors.passwordConfirm}
                />

                <InputGroup.Text
                  onClick={() => setShowPasswordC(!showPasswordC)}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={!showPasswordC ? faEyeSlash : faEye} />
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.passwordConfirm}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Row>
        </>
      )}

      {ownership === "2" && (
        <>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              動物取扱業選択
              <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              <Form.Check
                inline
                name="animal_handler_business_type"
                id="animal_handler_business_type1"
                label="第一種動物取扱業者"
                value="1"
                type="radio"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={
                  values.animal_handler_business_type === "1" ? true : false
                }
                isInvalid={
                  touched.animal_handler_business_type &&
                  errors.animal_handler_business_type
                }
                isValid={
                  touched.animal_handler_business_type &&
                  !errors.animal_handler_business_type
                }
              />
              <Form.Check
                inline
                name="animal_handler_business_type"
                id="animal_handler_business_type2"
                label="第二種動物取扱業者"
                value="2"
                type="radio"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={
                  values.animal_handler_business_type === "2" ? true : false
                }
                isInvalid={
                  touched.animal_handler_business_type &&
                  errors.animal_handler_business_type
                }
                isValid={
                  touched.animal_handler_business_type &&
                  !errors.animal_handler_business_type
                }
              />
              {touched.animal_handler_business_type &&
                errors.animal_handler_business_type && (
                  <div className="text-danger">
                    {errors.animal_handler_business_type}
                  </div>
                )}
            </Col>
          </Row>

          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              動物取扱業の情報 <span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-end border-bottom`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 border-secondary`}
            >
              {values.animal_handler_business_type === "1" && (
                <>
                  <Row
                    style={{
                      backgroundColor: "#E7F1FF",
                      width: "100%",
                      margin: "0 auto"
                    }}
                  >
                    <Col lg={3} sm={4} xs={12} className="py-2">
                      業種
                    </Col>

                    <Col
                      lg={9}
                      sm={8}
                      xs={12}
                      className={`py-2 ${
                        width < 576 && `border-top border-primary`
                      } ${width >= 576 && `border-start  border-primary`}`}
                    >
                      犬猫の所在地の都道府県又は政令指定都市から発行された
                      <span className="text-nowrap">登録番号</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} sm={4} xs={12} className="py-sm-2">
                      <Form.Check
                        id="animal_handle_11"
                        name="animal_handle_1"
                        label="販売"
                        type="checkbox"
                        value="1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={_.includes(values.animal_handle_1, "1")}
                        isInvalid={
                          touched.animal_handle_1 && errors.animal_handle_1
                        }
                        isValid={
                          touched.animal_handle_1 && !errors.animal_handle_1
                        }
                      />
                    </Col>

                    <Col lg={9} sm={8} xs={12}>
                      <Form.Group className="mb-1">
                        <Form.Control
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={
                            _.includes(values.animal_handle_1, "1")
                              ? values.animal_handler_business_type1_slot1
                              : ""
                          }
                          name="animal_handler_business_type1_slot1"
                          maxLength={20}
                          isInvalid={
                            touched.animal_handler_business_type1_slot1 &&
                            errors.animal_handler_business_type1_slot1
                          }
                          isValid={
                            _.includes(values.animal_handle_1, "1") &&
                            touched.animal_handler_business_type1_slot1 &&
                            !errors.animal_handler_business_type1_slot1
                          }
                          disabled={!_.includes(values.animal_handle_1, "1")}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.animal_handler_business_type1_slot1}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr className="m-0 mb-1" />
                  <Row>
                    <Col lg={3} sm={4} xs={12} className="py-sm-2">
                      <Form.Check
                        id="animal_handle_12"
                        name="animal_handle_1"
                        label="保管"
                        type="checkbox"
                        value="2"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={_.includes(values.animal_handle_1, "2")}
                        isInvalid={
                          touched.animal_handle_1 && errors.animal_handle_1
                        }
                        isValid={
                          touched.animal_handle_1 && !errors.animal_handle_1
                        }
                      />
                    </Col>

                    <Col lg={9} sm={8} xs={12}>
                      <Form.Group className="mb-1">
                        <Form.Control
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={
                            _.includes(values.animal_handle_1, "2")
                              ? values.animal_handler_business_type1_slot2
                              : ""
                          }
                          name="animal_handler_business_type1_slot2"
                          maxLength={20}
                          isInvalid={
                            touched.animal_handler_business_type1_slot2 &&
                            errors.animal_handler_business_type1_slot2
                          }
                          isValid={
                            _.includes(values.animal_handle_1, "2") &&
                            touched.animal_handler_business_type1_slot2 &&
                            !errors.animal_handler_business_type1_slot2
                          }
                          disabled={!_.includes(values.animal_handle_1, "2")}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.animal_handler_business_type1_slot2}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr className="m-0 mb-1" />
                  <Row>
                    <Col lg={3} sm={4} xs={12} className="py-sm-2">
                      <Form.Check
                        id="animal_handle_13"
                        name="animal_handle_1"
                        label="貸出し"
                        type="checkbox"
                        value="3"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={_.includes(values.animal_handle_1, "3")}
                        isInvalid={
                          touched.animal_handle_1 && errors.animal_handle_1
                        }
                        isValid={
                          touched.animal_handle_1 && !errors.animal_handle_1
                        }
                      />
                    </Col>

                    <Col lg={9} sm={8} xs={12}>
                      <Form.Group className="mb-1">
                        <Form.Control
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={
                            _.includes(values.animal_handle_1, "3")
                              ? values.animal_handler_business_type1_slot3
                              : ""
                          }
                          name="animal_handler_business_type1_slot3"
                          maxLength={20}
                          isInvalid={
                            touched.animal_handler_business_type1_slot3 &&
                            errors.animal_handler_business_type1_slot3
                          }
                          isValid={
                            _.includes(values.animal_handle_1, "3") &&
                            touched.animal_handler_business_type1_slot3 &&
                            !errors.animal_handler_business_type1_slot3
                          }
                          disabled={!_.includes(values.animal_handle_1, "3")}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.animal_handler_business_type1_slot3}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr className="m-0 mb-1" />
                  <Row>
                    <Col lg={3} sm={4} xs={12} className="py-sm-2">
                      <Form.Check
                        id="animal_handle_14"
                        name="animal_handle_1"
                        label="訓練"
                        type="checkbox"
                        value="4"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={_.includes(values.animal_handle_1, "4")}
                        isInvalid={
                          touched.animal_handle_1 && errors.animal_handle_1
                        }
                        isValid={
                          touched.animal_handle_1 && !errors.animal_handle_1
                        }
                      />
                    </Col>

                    <Col lg={9} sm={8} xs={12}>
                      <Form.Group className="mb-1">
                        <Form.Control
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={
                            _.includes(values.animal_handle_1, "4")
                              ? values.animal_handler_business_type1_slot4
                              : ""
                          }
                          name="animal_handler_business_type1_slot4"
                          maxLength={20}
                          isInvalid={
                            touched.animal_handler_business_type1_slot4 &&
                            errors.animal_handler_business_type1_slot4
                          }
                          isValid={
                            _.includes(values.animal_handle_1, "4") &&
                            touched.animal_handler_business_type1_slot4 &&
                            !errors.animal_handler_business_type1_slot4
                          }
                          disabled={!_.includes(values.animal_handle_1, "4")}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.animal_handler_business_type1_slot4}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr className="m-0 mb-1" />
                  <Row>
                    <Col lg={3} sm={4} xs={12} className="py-sm-2">
                      <Form.Check
                        id="animal_handle_15"
                        name="animal_handle_1"
                        label="展示"
                        type="checkbox"
                        value="5"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={_.includes(values.animal_handle_1, "5")}
                        isInvalid={
                          touched.animal_handle_1 && errors.animal_handle_1
                        }
                        isValid={
                          touched.animal_handle_1 && !errors.animal_handle_1
                        }
                      />
                    </Col>

                    <Col lg={9} sm={8} xs={12}>
                      <Form.Group className="mb-1">
                        <Form.Control
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={
                            _.includes(values.animal_handle_1, "5")
                              ? values.animal_handler_business_type1_slot5
                              : ""
                          }
                          name="animal_handler_business_type1_slot5"
                          maxLength={150}
                          isInvalid={
                            touched.animal_handler_business_type1_slot5 &&
                            errors.animal_handler_business_type1_slot5
                          }
                          isValid={
                            _.includes(values.animal_handle_1, "5") &&
                            touched.animal_handler_business_type1_slot5 &&
                            !errors.animal_handler_business_type1_slot5
                          }
                          disabled={!_.includes(values.animal_handle_1, "5")}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.animal_handler_business_type1_slot5}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr className="m-0 mb-1" />
                  <Row>
                    <Col lg={3} sm={4} xs={12} className="pt-sm-2">
                      <Form.Check
                        id="animal_handle_16"
                        name="animal_handle_1"
                        label="競りあっせん業"
                        type="checkbox"
                        value="6"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={_.includes(values.animal_handle_1, "6")}
                        isInvalid={
                          touched.animal_handle_1 && errors.animal_handle_1
                        }
                        isValid={
                          touched.animal_handle_1 && !errors.animal_handle_1
                        }
                      />
                    </Col>

                    <Col lg={9} sm={8} xs={12} className="mb-1">
                      <Form.Group>
                        <Form.Control
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={
                            _.includes(values.animal_handle_1, "6")
                              ? values.animal_handler_business_type1_slot6
                              : ""
                          }
                          name="animal_handler_business_type1_slot6"
                          maxLength={150}
                          isInvalid={
                            touched.animal_handler_business_type1_slot6 &&
                            errors.animal_handler_business_type1_slot6
                          }
                          isValid={
                            _.includes(values.animal_handle_1, "6") &&
                            touched.animal_handler_business_type1_slot6 &&
                            !errors.animal_handler_business_type1_slot6
                          }
                          disabled={!_.includes(values.animal_handle_1, "6")}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.animal_handler_business_type1_slot6}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr className="m-0 mb-1" />
                  <Row>
                    <Col lg={3} sm={4} xs={12} className="pt-sm-2">
                      <Form.Check
                        id="animal_handle_17"
                        name="animal_handle_1"
                        label="譲受飼養業"
                        type="checkbox"
                        value="7"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={_.includes(values.animal_handle_1, "7")}
                        isInvalid={
                          touched.animal_handle_1 && errors.animal_handle_1
                        }
                        isValid={
                          touched.animal_handle_1 && !errors.animal_handle_1
                        }
                      />
                    </Col>

                    <Col lg={9} sm={8} xs={12}>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={
                            _.includes(values.animal_handle_1, "7")
                              ? values.animal_handler_business_type1_slot7
                              : ""
                          }
                          name="animal_handler_business_type1_slot7"
                          maxLength={150}
                          isInvalid={
                            touched.animal_handler_business_type1_slot7 &&
                            errors.animal_handler_business_type1_slot7
                          }
                          isValid={
                            _.includes(values.animal_handle_1, "7") &&
                            touched.animal_handler_business_type1_slot7 &&
                            !errors.animal_handler_business_type1_slot7
                          }
                          disabled={!_.includes(values.animal_handle_1, "7")}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.animal_handler_business_type1_slot7}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  {touched.animal_handle_1 && errors.animal_handle_1 && (
                    <>
                      <hr className="m-0 mb-1 mt-1" />
                      <div className="text-danger">
                        {errors.animal_handle_1}
                      </div>
                    </>
                  )}
                </>
              )}

              {values.animal_handler_business_type === "2" && (
                <>
                  <Row>
                    <Col lg={4} xs={12}>
                      <Form.Check
                        id="animal_handle_21"
                        name="animal_handle_2"
                        label="譲渡し"
                        type="checkbox"
                        value="1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={_.includes(values.animal_handle_2, "1")}
                        isInvalid={
                          touched.animal_handle_2 && errors.animal_handle_2
                        }
                        isValid={
                          touched.animal_handle_2 && !errors.animal_handle_2
                        }
                      />
                    </Col>
                    <Col lg={4} xs={12}>
                      <Form.Check
                        id="animal_handle_22"
                        name="animal_handle_2"
                        label="保管"
                        type="checkbox"
                        value="2"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={_.includes(values.animal_handle_2, "2")}
                        isInvalid={
                          touched.animal_handle_2 && errors.animal_handle_2
                        }
                        isValid={
                          touched.animal_handle_2 && !errors.animal_handle_2
                        }
                      />
                    </Col>
                    <Col lg={4} xs={12}>
                      <Form.Check
                        id="animal_handle_23"
                        name="animal_handle_2"
                        label="貸出し"
                        type="checkbox"
                        value="3"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={_.includes(values.animal_handle_2, "3")}
                        isInvalid={
                          touched.animal_handle_2 && errors.animal_handle_2
                        }
                        isValid={
                          touched.animal_handle_2 && !errors.animal_handle_2
                        }
                      />
                    </Col>
                    <Col lg={4} xs={12}>
                      <Form.Check
                        id="animal_handle_24"
                        name="animal_handle_2"
                        label="訓練"
                        type="checkbox"
                        value="4"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={_.includes(values.animal_handle_2, "4")}
                        isInvalid={
                          touched.animal_handle_2 && errors.animal_handle_2
                        }
                        isValid={
                          touched.animal_handle_2 && !errors.animal_handle_2
                        }
                      />
                    </Col>
                    <Col lg={4} xs={12}>
                      <Form.Check
                        id="animal_handle_25"
                        name="animal_handle_2"
                        label="展示"
                        type="checkbox"
                        value="5"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={_.includes(values.animal_handle_2, "5")}
                        isInvalid={
                          touched.animal_handle_2 && errors.animal_handle_2
                        }
                        isValid={
                          touched.animal_handle_2 && !errors.animal_handle_2
                        }
                      />
                    </Col>
                  </Row>
                  {touched.animal_handle_2 && errors.animal_handle_2 && (
                    <div className="text-danger">{errors.animal_handle_2}</div>
                  )}
                </>
              )}
            </Col>
          </Row>

          {user && (
            <>
              <Row className="px-lg-3">
                <Col
                  lg={3}
                  className={`bg-secondary bg-opacity-10 ${
                    width < 576 && `border`
                  } ${
                    width >= 576 && `border-bottom border-start `
                  } border-secondary p-2`}
                >
                  写真
                </Col>

                <Col
                  lg={9}
                  className={`${
                    width < 576 && `border-start border-end border-bottom`
                  } ${
                    width >= 576 && `border-start border-bottom border-end`
                  } p-1 border-secondary`}
                >
                  {img == "" ? (
                    <Form.Control
                      type="file"
                      value={""}
                      accept="image/*"
                      onChange={(e) => {
                        cropImage(e);
                      }}
                    />
                  ) : (
                    <div className="position-relative">
                      <img src={img} className="w-100 shadow rounded" />
                      <Button
                        variant="danger"
                        className="position-absolute top-0 end-0 me-1 mt-1"
                        onClick={() => {
                          localStorage.setItem("signUpInputImg", "");
                          setImg("");
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>{" "}
                        削除
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="px-lg-3">
                <Col
                  lg={3}
                  className={`bg-secondary bg-opacity-10 ${
                    width < 576 && `border`
                  } ${
                    width >= 576 && `border-bottom border-start `
                  } border-secondary p-2`}
                >
                  犬舎紹介文 <span className="text-danger">※</span>
                </Col>

                <Col
                  lg={9}
                  className={`${
                    width < 576 && `border-start border-end border-bottom`
                  } ${
                    width >= 576 && `border-start border-bottom border-end`
                  } p-1 border-secondary`}
                >
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    value={values.about}
                    onChange={(value, _, __, editor) => {
                      // console.log(value)
                      if (editor.getLength() === 1) {
                        setFieldValue("about", "");
                      } else {
                        setFieldValue("about", value);
                      }
                    }}
                    onBlur={() => {
                      setFieldTouched("about", true);
                    }}
                    className={
                      touched.about && errors.about
                        ? "border border-danger"
                        : ""
                    }
                  />

                  {touched.about && errors.about && (
                    <div className="text-danger">{errors.about}</div>
                  )}
                </Col>
              </Row>
            </>
          )}
        </>
      )}

      <Modal
        onHide={() => setShow(false)}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            MC番号
            <span className="text-nowrap">#</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!!imgSrc && (
            <div className="d-flex justify-content-center align-items-center">
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={16 / 9}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  onLoad={onImageLoad}
                  className="w-100"
                />
              </ReactCrop>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShow(false)}>閉じる</Button>
          {!!completedCrop && (
            <Button
              onClick={() => {
                onDownloadCropClick();
              }}
            >
              Crop
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SignupInput;
