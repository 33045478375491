import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadString
} from "firebase/storage";

import { storage } from "../../../firebase/config";
import ProgressStep from "../../../components/ProgressStep";
import {
  dog_breed,
  cat_breed,
  dog_color,
  cat_color
} from "../../../utils/CatDogData";
import { getRandomString } from "../../../utils/Utils";
import { useSetDoc } from "../../../hooks/useSetDoc";
import { useAuthContext } from "../../../hooks/useAuthContext";
import "react-quill/dist/quill.snow.css";
import { serverTimestamp } from "firebase/firestore";

function AddToListConfirm() {
  const { user } = useAuthContext();
  const { firebaseSetDoc, firebaseSetDocQuery, isPending, error } = useSetDoc();
  const navigate = useNavigate();

  const addtoShopData =
    JSON.parse(localStorage.getItem("addtoShopData")) || false;
  const addtoShopImages =
    JSON.parse(localStorage.getItem("addtoShopImages")) || false;
  const mcData = JSON.parse(localStorage.getItem("mcData")) || false;
  const imageToDelete =
    JSON.parse(localStorage.getItem("imageToDelete")) || false;

  const [breed, setBreed] = useState(null);
  const [color, setColor] = useState(null);

  useEffect(() => {
    if (!(addtoShopData || addtoShopImages || mcData)) {
      navigate("/mypage/addtolist");
      return;
    }

    if (mcData.mcData.animal_group === "犬") {
      setBreed(dog_breed);
      setColor(dog_color);
    } else if (mcData.mcData.animal_group === "猫") {
      setBreed(cat_breed);
      setColor(cat_color);
    }
  }, []);

  async function uploadToStorage(url) {
    const imgRef = ref(storage, `koinu/image/pet/${getRandomString(23)}`);
    await uploadString(imgRef, url, "data_url");
    return await getDownloadURL(imgRef);
  }

  const submitHandler = async () => {
    const aminal_doc_id = mcData.mcData.mccode
      ? mcData.mcData.mccode.toString()
      : mcData.mcData.animalID.toString();
    // set sellPlatform data
    let setData = {
      ...addtoShopData,
      images: mcData.images || [],
      sellPlatform: true, // set sellPlatform true
      sellPlatformUpdateAt: serverTimestamp()
    };
    if (!mcData.sellPlatformAt) {
      setData = {
        ...setData,
        sellPlatformAt: serverTimestamp()
      };
    }
    delete setData["image1"];

    await Promise.all(
      Object.values(addtoShopImages).map(async (img, i) => {
        if (img != "") {
          //upload the images in firestore and get download link
          const downloadLink = await uploadToStorage(img);
          setData.images.push(downloadLink);
        }
      })
    );

    //upload values in microchip collection
    await firebaseSetDoc("microchip", setData, aminal_doc_id, true).then(
      async () => {
        //upload values in user/microchip collection
        await firebaseSetDocQuery(
          `users/${user.uid}/microchip`,
          setData,
          [["mccode", "==", aminal_doc_id]],
          true
        );
      }
    );

    //save update trigger
    const updateList = JSON.parse(localStorage.getItem("MY_DATA_1_Up")) || [];
    updateList.push(aminal_doc_id);
    localStorage.setItem("MY_DATA_1_Up", JSON.stringify(updateList));

    //delete pending images
    if (imageToDelete) {
      Object.values(imageToDelete).map(async (img, i) => {
        if (img != "") {
          // Create a reference to the file to delete
          const desertRef = ref(storage, img);
          // Delete the file
          await deleteObject(desertRef);
        }
      });
    }

    //clear local storage
    localStorage.removeItem("mcData");
    localStorage.removeItem("mccode");
    localStorage.removeItem("addtoShopData");
    localStorage.removeItem("addtoShopImages");
    localStorage.removeItem("imageToDelete");

    //redirect to success page
    navigate("/mypage/addtolistcomplete");
  };
  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "スキャン", link: "/mypage/addtolist" },
              { value: "入力", link: "/mypage/addtolistinput" },
              { value: "確認", link: "#" },
              { value: "終了", link: "#" }
            ]}
            activeNo={3}
          />
        </Row>
        {addtoShopData && addtoShopImages && mcData && (
          <Row className="justify-content-center">
            <Col xl={8} lg={10} sm={12}>
              <Table striped bordered responsive="sm">
                <tbody>
                  {mcData.mcData?.mccode && (
                    <tr>
                      <td>MC番号</td>
                      <td>{mcData.mcData.mccode}</td>
                    </tr>
                  )}

                  <tr>
                    <td>ペットID</td>
                    <td>{mcData.mcData.animalID}</td>
                  </tr>
                  {addtoShopData.sellPlatformPrice && (
                    <tr>
                      <td>価格</td>
                      <td>
                        {parseInt(
                          addtoShopData.sellPlatformPrice
                        ).toLocaleString("en-US")}
                        円 (税込)
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>見学地域</td>
                    <td>
                      <p className="mb-0">
                        〒{addtoShopData.sellPlatform_zip3}-
                        {addtoShopData.sellPlatform_zip4}
                      </p>
                      <p className="mb-0">
                        {addtoShopData.sellPlatform_prefecture}{" "}
                        {addtoShopData.sellPlatform_addressLine1}
                      </p>
                      <p className="mb-0">
                        {addtoShopData.sellPlatform_addressLine2}
                      </p>
                      <p className="mb-0">
                        {addtoShopData.sellPlatform_addressLine3}
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td>誕生日</td>
                    <td>{mcData.mcData.animal_birthDay}</td>
                  </tr>

                  <tr>
                    <td>性別</td>
                    <td>{mcData.mcData.animal_gender}</td>
                  </tr>

                  <tr>
                    <td>犬種</td>
                    <td>
                      {breed &&
                        mcData &&
                        breed.map((item) => {
                          if (item.value == mcData.mcData.animal_breed) {
                            return item.label;
                          }
                        })}
                      {mcData && mcData.mcData.animal_breed === "999" && (
                        <>{mcData.mcData.animal_breed_other}</>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>毛色</td>
                    <td>
                      {color &&
                        mcData &&
                        color.map((item) => {
                          if (item.value == mcData.mcData.animal_color) {
                            return item.label;
                          }
                        })}
                      {mcData && mcData.mcData.animal_color === "99" && (
                        <>{mcData.mcData.animal_color_other}</>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="text-nowrap">生体保証</td>
                    <td>{addtoShopData.guarantee}</td>
                  </tr>

                  <tr>
                    <td className="text-nowrap">アピールポイント</td>
                    <td>{addtoShopData.prPoint}</td>
                  </tr>

                  <tr>
                    <td className="text-nowrap">成犬時予想</td>
                    <td>
                      体重: {addtoShopData.weight_from}~
                      {addtoShopData.weight_to}kg　体高:{" "}
                      {addtoShopData.height_from}~{addtoShopData.height_to}cm
                    </td>
                  </tr>
                  <tr>
                    <td>子犬紹介文</td>
                    <td className="ql-snow">
                      <div
                        className=" ql-editor"
                        dangerouslySetInnerHTML={{
                          __html: addtoShopData.sellPlatformDescription
                        }}></div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Row>
                <Col sm={12}>写真</Col>

                {mcData.images &&
                  Object.values(mcData.images).map(
                    (img, i) =>
                      img && (
                        <Col md={6} sm={12} key={i} className="mb-3">
                          <img src={img} className="w-100 shadow rounded" />
                        </Col>
                      )
                  )}

                {addtoShopImages &&
                  Object.values(addtoShopImages).map(
                    (img, i) =>
                      img && (
                        <Col md={6} sm={12} key={i} className="mb-3">
                          <img src={img} className="w-100 shadow rounded" />
                        </Col>
                      )
                  )}
              </Row>
            </Col>
            <div className="text-center mt-4">
              <Link
                className="btn btn-secondary m-2"
                to={`/mypage/addtolistinput`}>
                <FontAwesomeIcon icon={faCircleChevronLeft} /> 修正する
              </Link>

              {!isPending && (
                <Button className="btn btn-primary m-2" onClick={submitHandler}>
                  保存 <FontAwesomeIcon icon={faCircleChevronRight} />
                </Button>
              )}

              {isPending && (
                <Button className="btn btn-primary m-2" disabled>
                  保存 <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              )}
            </div>
          </Row>
        )}
      </Container>
    </>
  );
}

export default AddToListConfirm;
