import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  InputGroup,
  Row,
  Form,
  Spinner
} from "react-bootstrap";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useUpdateDoc } from "../../../hooks/useUpdateDoc";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useUserDetailsContext } from "../../../context/UserDetailsContext";
import { useToast } from "../../../hooks/useToast";

function ContactQuestion() {
  const { width } = useWindowDimensions();
  const [questions, setQuestions] = useState([]);
  const [totalCheckboxLabels, setTotalCheckboxLabels] = useState(0);

  const createSection = () => {
    setQuestions([
      ...questions,
      {
        sectionTitle: "",
        checkboxLabels: [""]
      }
    ]);
  };

  const { firebaseUpdateDoc, isPending } = useUpdateDoc();
  const { user } = useAuthContext();
  const { userData } = useUserDetailsContext();
  const toast = useToast();

  // save the questions to the firebase users collection by user id
  const saveQuestions = async (e) => {
    e.preventDefault();
    // console.log(questions);
    await firebaseUpdateDoc("users", { contactQuestions: questions }, user.uid);
    toast("success", "完了しました！");
  };

  useEffect(() => {
    setQuestions(userData.contactQuestions);
  }, [userData]);

  useEffect(() => {
    const totalCheckboxLabels = questions.reduce((total, currentSection) => {
      return total + currentSection.checkboxLabels.length;
    }, 0);

    setTotalCheckboxLabels(totalCheckboxLabels);
  }, [questions]);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col sm={10} xl={8}>
          <h2 className="text-center">
            見学希望・お問い合わせに関する確認事項
          </h2>
          <Form onSubmit={saveQuestions}>
            {questions.map((question, index) => {
              return (
                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 &&
                      `${
                        index === questions.length - 1 ? "border-bottom" : ""
                      } border-top border-start `
                    } border-secondary p-2`}>
                    <InputGroup className="p-1" key={"labeltitle" + index}>
                      <Form.Control
                        required
                        maxLength={100}
                        type="text"
                        placeholder="タイトル"
                        value={question.sectionTitle}
                        onChange={(e) => {
                          const newQuestions = [...questions];
                          newQuestions[index].sectionTitle = e.target.value;
                          setQuestions(newQuestions);
                        }}
                      />

                      <Button
                        variant="outline-danger"
                        className="ml-2"
                        onClick={() => {
                          const newQuestions = [...questions];
                          newQuestions.splice(index, 1);
                          setQuestions(newQuestions);
                        }}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </InputGroup>
                  </Col>
                  <Col
                    lg={9}
                    className={`${width < 576 && `border-start border-end`} ${
                      width >= 576 && `border-top border-start border-end`
                    } ${
                      index === questions.length - 1 ? "border-bottom" : ""
                    } p-1 border-end border-secondary`}>
                    {question.checkboxLabels.map((label, labelIndex) => {
                      return (
                        <InputGroup className="p-1" key={labelIndex}>
                          <InputGroup.Checkbox aria-label="Checkbox for following text input" />
                          <Form.Control
                            required
                            maxLength={300}
                            type="text"
                            placeholder="確認事項"
                            value={label}
                            onChange={(e) => {
                              const newQuestions = [...questions];
                              newQuestions[index].checkboxLabels[labelIndex] =
                                e.target.value;
                              setQuestions(newQuestions);
                            }}
                          />
                          {question.checkboxLabels.length > 1 && (
                            <Button
                              variant="outline-danger"
                              className="ml-2"
                              onClick={() => {
                                const newQuestions = [...questions];
                                newQuestions[index].checkboxLabels.splice(
                                  labelIndex,
                                  1
                                );
                                setQuestions(newQuestions);
                              }}>
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          )}
                        </InputGroup>
                      );
                    })}
                    {/* disable button is all sum of the label count is same or more that */}
                    {totalCheckboxLabels < 10 && (
                      <div className="p-1">
                        <Button
                          variant="outline-light"
                          className="w-100 "
                          style={{
                            color: "black",
                            border: "1px dashed black"
                          }}
                          onClick={() => {
                            const newQuestions = [...questions];
                            newQuestions[index].checkboxLabels.push("");
                            setQuestions(newQuestions);
                          }}>
                          + 追加する
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              );
            })}

            {/* also check if last question has at least 1 checkboxLabels and is not empty */}
            {questions &&
              questions.length > 0 &&
              questions[questions.length - 1].checkboxLabels.length > 0 &&
              questions[questions.length - 1].checkboxLabels[0] !== "" &&
              totalCheckboxLabels < 10 && (
                <div className="px-1 pt-2">
                  <Button
                    variant="outline-light"
                    className="w-100"
                    style={{ color: "black", border: "1px dashed black" }}
                    onClick={createSection}>
                    + 新しく項目を追加する
                  </Button>
                </div>
              )}

            <div className="text-center mt-4">
              {!isPending && (
                <Button className="mb-2" variant="primary" type="submit">
                  保存
                </Button>
              )}

              {isPending && (
                <Button className="mb-2" variant="primary" disabled>
                  保存
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              )}
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactQuestion;
