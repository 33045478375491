import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import moment from "moment";
import { Timestamp } from "firebase/firestore";

function PaymentListItem({ doc, modalFun, hideOther = false }) {
  // console.log(doc);
  return (
    <tr key={doc.id}>
      <td className="align-middle">
        <h5 className="mb-0">{doc.payment_case}</h5>
        {doc.createdAt && (
          <small>
            {moment(
              new Timestamp(
                doc.createdAt.seconds,
                doc.createdAt.nanoseconds
              ).toDate()
            ).format("YYYY/MM/DD HH:mm")}
          </small>
        )}
      </td>
      <td className="text-end align-middle">
        <h4 className="mb-0 text-nowrap"> {(parseInt(doc.amount)).toLocaleString()}円</h4>
      </td>
      <td className="text-center  align-middle">
        <ButtonGroup className="me-2" aria-label="First group">
          <Button
            variant="secondary"
            onClick={() => {
              modalFun(doc);
              hideOther();
            }}
          >
            詳細
          </Button>{" "}
        </ButtonGroup>
      </td>
    </tr>
  );
}

export default PaymentListItem;
