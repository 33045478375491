import React, { useEffect, useState } from "react";

import moment from "moment";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Badge, Button, ButtonGroup, Container, Spinner } from "react-bootstrap";

import { Timestamp } from "firebase/firestore";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import NewsDetailsModal from "./NewsDetailsModal";
import { useCollectionDocs } from "../../hooks/useCollectionDocs";
import { customPaginationOptions } from "../../utils/Utils";

export default function NewsList() {
  const { documents, isPending, getMultipleDocs } = useCollectionDocs();
  const [datas, setDatas] = useState(false);
  const [showNewsDetailsModal, setShowNewsDetailsModal] = useState(false);
  const [newsModalContent, setNewsModalContent] = useState(null);

  const NewsModalHandler = (props) => {
    setShowNewsDetailsModal(true);
    setNewsModalContent(props);
  };

  const columns = [
    {
      name: "タイトル",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "タイプ",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "日付",
      selector: (row) => moment(
        new Timestamp(row.dateAt.seconds, row.dateAt.nanoseconds).toDate()
      ).format("YYYY/MM/DD"),
      sortable: true,
    },
    
    {
      name: "",
      cell: (row) => (
        <>
          <ButtonGroup>
            <Button variant="secondary" onClick={() => NewsModalHandler(row)}>
              詳細
            </Button>
            <Button variant="primary" onClick={() => editNews(row)}>
              編集
            </Button>
            <Button variant="danger">削除</Button>
          </ButtonGroup>
        </>
      ),
    },
  ];

  const loadMore = () => {
    getMultipleDocs({
      _collection: `news`,
      _query: false,
      orderByField: "dateAt",
      orderByOption: "desc",
    });
  };



  useEffect(() => {
    loadMore();
  }, []);

  useEffect(() => {
    if (documents) {
      setDatas(documents);
    }
    // console.log(documents);
  }, [documents]);

  const navigate = useNavigate();
  const editNews = (doc) => {
    localStorage.setItem("newsDataEdit", JSON.stringify(doc));
    localStorage.setItem("newsEditId", JSON.stringify(doc.id));
    navigate(`/mypage/newsedit`);
  };

  return (
    <>
      <Container>
        <div className="text-end my-2">
          <Link to="/mypage/createnews" className="btn btn-secondary">
            <FontAwesomeIcon icon={faAdd} /> お知らせを追加
          </Link>
        </div>
        {datas && (
          <DataTable
            columns={columns}
            progressPending={isPending}
            progressComponent={<Spinner
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
            />}
            data={datas}
            pagination
            paginationComponentOptions={customPaginationOptions}
          />
        )}
      </Container>

      <NewsDetailsModal
        data={newsModalContent}
        show={showNewsDetailsModal}
        onHide={() => setShowNewsDetailsModal(false)}
      />
    </>
  );
}
