import { Timestamp } from "firebase/firestore";
import moment from "moment";

export function getRandomString(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  let result = " ";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result.trim();
}

export const mcChart = (analytics_array, start, end, type) => {
  let startDate = moment(start);
  let endDate = moment(end);
  let startYear = moment(start).format("YYYY");
  let endYear = moment(end).format("YYYY");
  let diff = endDate.diff(startDate, "days");

  let cartData = [];
  let totalData = 0;
  for (let year = startYear; year <= endYear; year++) {
    // console.log({...analytics_array[year]})
    for (let day = 0; day <= diff; day++) {
      // const { } =
      let mmdd = moment(moment(start).add(day, "days")).format("MMDD");
      let data =
        analytics_array[year][mmdd] && analytics_array[year][mmdd][type]
          ? analytics_array[year][mmdd][type]
          : 0;
      totalData += data;
      cartData.push(data);
    }
  }

  return { cartData, totalData };
};

export const json2array = (jsonData) => {
  let analytics_array = [];

  Object.entries(jsonData).forEach((entry) => {
    const key = entry[0];
    const value = entry[1];
    analytics_array[key] = value;
  });
  return { ...analytics_array };
};

export const getName = (doc, maxLen = null) => {
  let name =
    doc.type === "1"
      ? `${doc.lastName} ${doc.firstName}`
      : doc.corporation_name;

  if (maxLen && name.length > maxLen) {
    name = doc.type === "1" ? doc.lastName : doc.corporation_name;
    if (name.length > maxLen) {
      name = `${name.slice(0, maxLen - 1)}...`;
    }
  }

  return name;
};

export const getNameByOwnerType = (doc) =>
  doc.owner_type === "1"
    ? `${doc.lastName} ${doc.firstName}`
    : doc.corporation_name;

export const getRepresentativeName = (doc) =>
  doc.type === "1"
    ? `${doc.lastName} ${doc.firstName}`
    : `${doc.corporation_representative_lastName} ${doc.corporation_representative_firstName}`;

export const getNameForEmail = (doc) =>
  doc.type === "1"
    ? `${doc.lastName} ${doc.firstName} 様`
    : `${doc.corporation_name}<br>${doc.corporation_personincharge_lastName} ${doc.corporation_personincharge_firstName} 様`;

export const hasActivePlan = (doc) =>
  doc.plan &&
  moment(
    new Timestamp(
      doc.planExpiryDate.seconds,
      doc.planExpiryDate.nanoseconds
    ).toDate()
  ).isAfter(moment());

export const mailFooter = () => `<hr>
<br/>
株式会社PETNEXT<br/>
メールアドレス　：petnext01@gmail.com<br/>
電話番号　　　　：03-6749-0967<br/>`;

export const date2monthAnal = (data) => {
  // console.log("data", data);
  let monthlyAnal = [];

  let mc = 0;
  let dm = 0;
  let df = 0;
  let cm = 0;
  let cf = 0;
  let mct = 0;
  let dmt = 0;
  let dft = 0;
  let cmt = 0;
  let cft = 0;
  let mcd = 0;
  let dmd = 0;
  let dfd = 0;
  let cmd = 0;
  let cfd = 0;
  let mny = 0;
  for (let mm = 1; mm <= 12; mm++) {
    mc = 0;
    dm = 0;
    df = 0;
    cm = 0;
    cf = 0;
    mct = 0;
    dmt = 0;
    dft = 0;
    cmt = 0;
    cft = 0;
    mcd = 0;
    dmd = 0;
    dfd = 0;
    cmd = 0;
    cfd = 0;
    mny = 0;
    for (let dd = 1; dd <= 31; dd++) {
      let nmm = mm < 10 ? `0${mm}` : mm;

      let ndd = dd < 10 ? `0${dd}` : dd;
      mc +=
        data[`${nmm}${ndd}`] && data[`${nmm}${ndd}`].mc
          ? data[`${nmm}${ndd}`].mc
          : 0;
      dm +=
        data[`${nmm}${ndd}`] && data[`${nmm}${ndd}`].dm
          ? data[`${nmm}${ndd}`].dm
          : 0;
      df +=
        data[`${nmm}${ndd}`] && data[`${nmm}${ndd}`].df
          ? data[`${nmm}${ndd}`].df
          : 0;
      cm +=
        data[`${nmm}${ndd}`] && data[`${nmm}${ndd}`].cm
          ? data[`${nmm}${ndd}`].cm
          : 0;
      cf +=
        data[`${nmm}${ndd}`] && data[`${nmm}${ndd}`].cf
          ? data[`${nmm}${ndd}`].cf
          : 0;
      mct +=
        data[`${nmm}${ndd}`] && data[`${nmm}${ndd}`].mct
          ? data[`${nmm}${ndd}`].mct
          : 0;
      dmt +=
        data[`${nmm}${ndd}`] && data[`${nmm}${ndd}`].dmt
          ? data[`${nmm}${ndd}`].dmt
          : 0;
      dft +=
        data[`${nmm}${ndd}`] && data[`${nmm}${ndd}`].dft
          ? data[`${nmm}${ndd}`].dft
          : 0;
      cmt +=
        data[`${nmm}${ndd}`] && data[`${nmm}${ndd}`].cmt
          ? data[`${nmm}${ndd}`].cmt
          : 0;
      cft +=
        data[`${nmm}${ndd}`] && data[`${nmm}${ndd}`].cft
          ? data[`${nmm}${ndd}`].cft
          : 0;
      mcd +=
        data[`${nmm}${ndd}`] && data[`${nmm}${ndd}`].mcd
          ? data[`${nmm}${ndd}`].mcd
          : 0;
      dmd +=
        data[`${nmm}${ndd}`] && data[`${nmm}${ndd}`].dmd
          ? data[`${nmm}${ndd}`].dmd
          : 0;
      dfd +=
        data[`${nmm}${ndd}`] && data[`${nmm}${ndd}`].dfd
          ? data[`${nmm}${ndd}`].dfd
          : 0;
      cmd +=
        data[`${nmm}${ndd}`] && data[`${nmm}${ndd}`].cmd
          ? data[`${nmm}${ndd}`].cmd
          : 0;
      cfd +=
        data[`${nmm}${ndd}`] && data[`${nmm}${ndd}`].cfd
          ? data[`${nmm}${ndd}`].cfd
          : 0;
      mny +=
        data[`${nmm}${ndd}`] && data[`${nmm}${ndd}`].mny
          ? data[`${nmm}${ndd}`].mny
          : 0;
    }
    monthlyAnal[mm] = {
      mc,
      dm,
      df,
      cm,
      cf,
      mct,
      dmt,
      dft,
      cmt,
      cft,
      mcd,
      dmd,
      dfd,
      cmd,
      cfd,
      mny,
    };
  }
  return monthlyAnal;
};

export const dateString = (data) => {
  if (!data) {
    return data;
  }

  let date = new Date(data);
  return (
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  );
};

export const customPaginationOptions = {
  rowsPerPageText: "1ページあたりの行数:",
};
