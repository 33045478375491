import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  faCreditCard,
  faGaugeHigh,
  faKey,
  faList,
  faMicrochip,
  faMoneyCheckDollar,
  faRightLong,
  faSignOut,
  faUserCircle,
  faFileCsv,
  faFileContract,
  faEnvelope,
  faSun,
  faShop,
  faStar,
  faPaw,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet, HelmetData } from "react-helmet-async";

import ChangePassword from "../../components/ChangePassword";
import Logout from "../../components/Logout";
import MyPageHeader from "../../components/MyPageHeader";
import SideBar from "../../components/SideBar";
import Dashboard from "./dashboard/Dashboard";
import styles from "./MyPage.module.css";
import MCRegistration from "../mcregistration/MCRegistration";
import MCInput from "../mcregistration/MCInput";
import MCInputCheck from "../mcregistration/MCInputCheck";
import PersonalInfo from "./personalinfo/PersonalInfo";
import PersonalInfoCheck from "./personalinfo/PersonalInfoCheck";
import PersonalInfoConfirm from "./personalinfo/PersonalInfoConfirm";
import CardInfo from "../creditcard/CardInfo";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import CardRegistration from "../creditcard/CardRegistration";
import MCPay from "../mcregistration/MCPay";
import MCRegistrationComplete from "../mcregistration/MCRegistrationComplete";
import MCList from "../MCList";
import TransferOpt from "../transfer/TransferOpt";
import Transfer from "../transfer/Transfer";
import TransferCheck from "../transfer/TransferCheck";
import TransferInput from "../transfer/TransferInput";
import OTPInput from "../transfer/OTPInput";
import TransferConfirm from "../transfer/TransferConfirm";
import TransferPay from "../transfer/TransferPay";
import TransferComplete from "../transfer/TransferComplete";
import PaymentList from "../PaymentList";
import CSVFile from "../../components/CSVFile";
import SS from "../../components/SS";
import MCEdit from "./MCEdit";
import MCEditCheck from "./MCEditCheck";
import MCEditComplete from "./MCEditComplete";
import TransferMCInput from "../transfer/TransferMCInput";
import CSVFileComplete from "../mcregistration/CSVFileComplete";
import CertificateList from "./CertificateList";
import { useCollectionDocs } from "../../hooks/useCollectionDocs";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useBreederCertificateContext } from "../../context/BreederCertificateContext";
import ChangeOwner from "../transfer/ChangeOwner";
import ChangeOwnerCSVDownload from "../transfer/ChangeOwnerCSVDownload";
import ChangeOwnerCSVUpload from "../transfer/ChangeOwnerCSVUpload";
import ChageOwnerComplete from "../transfer/ChageOwnerComplete";
import Report from "./Report";
import ChangeEmail from "../../components/ChangeEmail";
import OCR from "../../components/OCR";
import MCRegistrationFromPDF from "../mcregistration/MCRegistrationFromPDF";
import MCRegistrationFromPDFinput from "../mcregistration/MCRegistrationFromPDFinput";
import MCRegistrationFromPDFCheck from "../mcregistration/MCRegistrationFromPDFCheck";
import MCRegistrationFromPDFComplete from "../mcregistration/MCRegistrationFromPDFComplete";
import SubsDetails from "./subscribe/SubsDetails";
import SubsCard from "./subscribe/SubsCard";
import SubsCheck from "./subscribe/SubsCheck";
import AddToList from "./shop/AddToList";
import AddToListInput from "./shop/AddToListInput";
import AddToListConfirm from "./shop/AddToListConfirm";
import AddToListComplete from "./shop/AddToListComplete";
import Header from "../../components/Header";
import Plans from "../plan/Plans";
import Footer from "../../components/Footer";
import PlanPay from "../plan/PlanPay";
import PlanComplete from "../plan/PlanComplete";
import { hasActivePlan } from "../../utils/Utils";
import ActivePlanDetails from "./plan/ActivePlanDetails";
import SellingContract from "../transfer/SellingContract";
import SCpdf from "../../components/sellContract/SCpdf";
import Reviews from "./Reviews";
import ContactQuestion from "./Lookfor/ContactQuestion";
import SellingContractSettings from "./settings/SellingContractSettings";
import SellingContractSettingsEdit from "./settings/SellingContractSettingsEdit";
import AidScheme from "../plan/AidScheme";

export default function BreederMyPage({ setBg }) {
  const [searchParams] = useSearchParams();
  const { cerData } = useBreederCertificateContext();
  const { userData } = useUserDetailsContext();
  const navigate = useNavigate();

  const [sideBarShow, setSideBarShow] = useState(false);
  const [activeMenu, setActiveMenu] = useState("dashboard");
  const [pageTitle, setPageTitle] = useState("ダッシュボード");

  const menus = [
    { link: "dashboard", icon: faGaugeHigh, text: "ダッシュボード" },
    { link: "mcregistration", icon: faMicrochip, text: "MC・ペット情報登録" },
    { link: "mclist", icon: faList, text: "登録情報一覧" },
    { link: "transfer", icon: faRightLong, text: "譲渡先" },
    // { link: "cardinfo", icon: faCreditCard, text: "カード情報" },
    { link: "personalinfo", icon: faUserCircle, text: "プロフィール編集" },
    { link: "changepassword", icon: faKey, text: "パスワード変更" },
    { link: "changeemail", icon: faEnvelope, text: "メールアドレス変更" },
    {
      link: "breedershochakulist",
      icon: faFileContract,
      text: "装着証明書一覧",
    },
    {
      link: "csv",
      icon: faFileCsv,
      text: "<small >環境省登録済み</small><small class='fs-10'>CSVアップロード</small>",
      titleText: "CSVアップロード",
    },
    {
      link: "mcregistrationfrompdf",
      icon: faMicrochip,
      text: "登録証明書スキャン",
    },
    {
      link: "lookfor-settings",
      icon: faPaw,
      text: "お問い合わせ設定",
    },
    {
      link: "plansetting",
      icon: faCreditCard,
      text: "プランと請求",
    },
    {
      link: "paymenthistory",
      icon: faMoneyCheckDollar,
      text: "決済履歴・詳細",
    },
    {
      link: "hanbaikeiyakusho",
      icon: faFileContract,
      text: "&nbsp;&nbsp;販売契約書",
      titleText: "販売契約書",
    },
    {
      link: "reviews",
      icon: faStar,
      text: "レビューリスト",
    },
    // { link: "subscription", icon: faSun, text: "サポートお申し込み" },
    { link: "logout", icon: faSignOut, text: "ログアウト" },
  ];
  const sideBarShowHandlerFn = () => {
    setSideBarShow(!sideBarShow);
  };

  let location = useLocation();

  useEffect(() => {
    const loc = location.pathname.replace(/\/mypage\//g, "");
    if (loc && loc !== "/mypage") {
      setActiveMenu(loc);
      setBg("");
      menus.map((menu) => {
        if (menu.link === loc) {
          setPageTitle(menu.titleText || menu.text);
        } else if (
          loc === "mcinput" ||
          loc === "mcinputcheck" ||
          loc === "mcpay" ||
          loc === "mcregistrationcomplete"
        ) {
          setPageTitle("MC・ペット情報登録");
          setActiveMenu("mcregistration");
        } else if (
          loc === "personalinfocheck" ||
          loc === "personalinfoconfirm"
        ) {
          setPageTitle("プロフィール編集");
          setActiveMenu("personalinfo");
        } else if (loc === "changecardinfo") {
          setPageTitle("プランと請求");
          setActiveMenu("plansetting");
        } else if (
          loc === "transferstart" ||
          loc === "transfercheck" ||
          loc === "transferinput" ||
          loc === "transfermcinput" ||
          loc === "transferotp" ||
          loc === "transferconfirm" ||
          loc === "transferpay" ||
          loc === "transfercomplete"
        ) {
          setPageTitle("譲渡先");
          setActiveMenu("transfer");
        } else if (
          loc === "addtolistinput" ||
          loc === "addtolistconfirm" ||
          loc === "addtolistcomplete"
        ) {
          setPageTitle("KOINU");
          setActiveMenu("addtolist");
        } else if (loc === "csvfilecomplete") {
          setPageTitle("CSV Upload Complete");
          setActiveMenu("csv");
        }
        return true;
      });
    }

    if (
      hasActivePlan(userData) &&
      location.pathname !== "/mypage/plan/aid" &&
      location.pathname !== "/mypage/plan" &&
      location.pathname !== "/mypage/plan/lite" &&
      location.pathname !== "/mypage/plan/standard" &&
      location.pathname !== "/mypage/plan/premium" &&
      location.pathname !== "/mypage/plan/thankyou"
    ) {
      // setPlanShow(true);
      setBg(false);
    } else {
      // setPlanShow(false);
      setBg(true);
      // navigate("/mypage");
    }

    if (
      hasActivePlan(userData) &&
      (location.pathname === "/mypage/plan/aid" ||
        location.pathname === "/mypage/plan" ||
        location.pathname === "/mypage/plan/lite" ||
        location.pathname === "/mypage/plan/standard" ||
        location.pathname === "/mypage/plan/premium" ||
        location.pathname === "/mypage/plan/thankyou")
    ) {
      setPlanShow(false);
      setBg(true);
      navigate("/mypage");
    }
  }, [location]);

  // plan page controller
  const [planShow, setPlanShow] = useState(false);
  useEffect(() => {
    if (userData && !hasActivePlan(userData)) {
      setPlanShow(true);
      if(location.pathname !== "/mypage/plan/aid" &&
        location.pathname !== "/mypage/plan" &&
        location.pathname !== "/mypage/plan/lite" &&
        location.pathname !== "/mypage/plan/standard" &&
        location.pathname !== "/mypage/plan/premium" &&
        location.pathname !== "/mypage/plan/thankyou"){
          navigate("/mypage/plan/aid");
        }
    }
  }, [userData, location]);

  const helmetData = new HelmetData({});

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>{pageTitle}</title>
      </Helmet>
      {planShow && (
        <>
          <Routes>
            <Route
              path="/plan/aid"
              element={
                userData && !hasActivePlan(userData) ? (
                  <>
                    <Header setBg={setBg} />
                    <AidScheme />
                    <Footer />
                  </>
                ) : (
                  <Navigate to="/mypage" />
                )
              }
            />
          </Routes>
          <Routes>
            <Route
              path="/plan"
              element={
                userData && !hasActivePlan(userData) ? (
                  <>
                    <Header setBg={setBg} />
                    <Plans />
                    <Footer />
                  </>
                ) : (
                  <Navigate to="/mypage" />
                )
              }
            />
          </Routes>

          <Routes>
            <Route
              path="/plan/lite"
              element={
                userData && !hasActivePlan(userData) ? (
                  <>
                    <Header setBg={setBg} />
                    <PlanPay
                      komoju_customer_id={userData.komoju_customer_id || null}
                      planid={"LITE"}
                      planShowFn={setPlanShow}
                    />
                    <Footer />
                  </>
                ) : (
                  <Navigate to="/mypage/plan/thankyou" />
                )
              }
            />

            <Route
              path="/plan/standard"
              element={
                <>
                  <Header setBg={setBg} />
                  <PlanPay
                    komoju_customer_id={userData.komoju_customer_id || null}
                    planid={"STANDARD"}
                    planShowFn={setPlanShow}
                  />
                  <Footer />
                </>
              }
            />

            <Route
              path="/plan/premium"
              element={
                <>
                  <Header setBg={setBg} />
                  <PlanPay
                    komoju_customer_id={userData.komoju_customer_id || null}
                    planid={"PREMIUM"}
                    planShowFn={setPlanShow}
                  />
                  <Footer />
                </>
              }
            />
            {/* <Route path="*" element={<Navigate to="/404" />} /> */}
          </Routes>
        </>
      )}
      <Routes>
        <Route
          path="/plan/thankyou"
          element={
            <>
              <Header setBg={setBg} />
              <PlanComplete />
              <Footer />
            </>
          }
        />
      </Routes>

      {(!planShow || hasActivePlan(userData)) && (
        <>
          {location.pathname.replace(/\/mypage\//g, "") !== "report" &&
            location.pathname !== "/mypage/plan/aid" &&
            location.pathname !== "/mypage/plan" &&
            location.pathname !== "/mypage/plan/lite" &&
            location.pathname !== "/mypage/plan/standard" &&
            location.pathname !== "/mypage/plan/premium" &&
            location.pathname !== "/mypage/plan/thankyou" && (
              <>
                <MyPageHeader
                  sideBarShow={sideBarShow}
                  sideBarShowHandler={sideBarShowHandlerFn}
                  pageTitle={pageTitle}
                />
                <SideBar
                  sideBarShow={sideBarShow}
                  sideBarShowHandler={sideBarShowHandlerFn}
                  activeMenu={activeMenu}
                  menus={menus}
                  certificateRequests={cerData}
                  setBg={setBg}
                />
              </>
            )}
          <div
            className={`${
              location.pathname.replace(/\/mypage\//g, "") !== "report" &&
              location.pathname !== "/mypage/plan/aid" &&
              location.pathname !== "/mypage/plan" &&
              location.pathname !== "/mypage/plan/lite" &&
              location.pathname !== "/mypage/plan/standard" &&
              location.pathname !== "/mypage/plan/premium" &&
              location.pathname !== "/mypage/plan/thankyou" &&
              styles.mainBody
            }`}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="dashboard" element={<Dashboard />} />

              <Route path="mcregistration" element={<MCRegistration />} />
              <Route path="mcinput" element={<MCInput />} />
              <Route path="mcinputcheck" element={<MCInputCheck />} />
              <Route
                path="mcpay"
                element={
                  <MCPay
                    komoju_customer_id={userData.komoju_customer_id || null}
                  />
                }
              />
              <Route
                path="mcregistrationcomplete"
                element={<MCRegistrationComplete />}
              />

              <Route
                path="breedershochakulist"
                element={<CertificateList certificateRequests={cerData} />}
              />

              <Route path="mclist" element={<MCList />} />
              <Route path="mcedit/:mccode" element={<MCEdit />} />
              <Route path="mceditcheck" element={<MCEditCheck />} />
              <Route path="mceditcomplete" element={<MCEditComplete />} />
              <Route path="paymenthistory" element={<PaymentList />} />

              <Route path="transfer" element={<TransferOpt />} />
              <Route path="transferstart" element={<Transfer />} />
              <Route path="transferinput" element={<TransferInput />} />
              <Route path="transfermcinput" element={<TransferMCInput />} />
              <Route path="transfercheck" element={<TransferCheck />} />
              <Route path="sellingcontract" element={<SellingContract />} />
              <Route path="transferotp" element={<OTPInput />} />
              <Route path="transferconfirm" element={<TransferConfirm />} />
              <Route
                path="transferpay"
                element={
                  <TransferPay
                    komoju_customer_id={userData.komoju_customer_id || null}
                  />
                }
              />
              <Route path="transfercomplete" element={<TransferComplete />} />

              <Route path="changeOwner/:mccode" element={<ChangeOwner />} />
              <Route
                path="changeOwnercsvdownload"
                element={<ChangeOwnerCSVDownload />}
              />
              <Route
                path="ChangeOwnerCSVUpload"
                element={<ChangeOwnerCSVUpload />}
              />
              <Route
                path="chageownercomplete"
                element={<ChageOwnerComplete />}
              />

              <Route path="personalinfo" element={<PersonalInfo />} />
              <Route path="personalinfocheck" element={<PersonalInfoCheck />} />
              <Route
                path="personalinfoconfirm"
                element={<PersonalInfoConfirm />}
              />

              <Route
                path="cardinfo"
                element={
                  userData.komoju_customer_id ? (
                    <CardInfo
                      komoju_customer_id={userData.komoju_customer_id}
                    />
                  ) : (
                    <CardRegistration returnUrl="/mypage/cardinfo" />
                  )
                }
              />
              <Route
                path="changecardinfo"
                element={
                  <CardRegistration
                    komoju_customer_id={userData.komoju_customer_id}
                    returnUrl={
                      searchParams.get("returnUrl") || "/mypage/plansetting"
                    }
                  />
                }
              />

              <Route path="changepassword" element={<ChangePassword />} />
              <Route path="changeemail" element={<ChangeEmail />} />
              <Route path="logout" element={<Logout />} />
              <Route path="csv" element={<CSVFile />} />
              <Route path="csvfilecomplete" element={<CSVFileComplete />} />
              <Route path="ss" element={<SS />} />

              <Route path="report" element={<Report />} />
              <Route path="screport" element={<SCpdf />} />
              <Route path="ocr" element={<OCR />} />

              <Route
                path="mcregistrationfrompdf"
                element={<MCRegistrationFromPDF />}
              />
              <Route
                path="mcregistrationfrompdfinput"
                element={<MCRegistrationFromPDFinput />}
              />
              <Route
                path="mcregistrationfrompdfcheck"
                element={<MCRegistrationFromPDFCheck />}
              />
              <Route
                path="mcregistrationfrompdfcomplete"
                element={<MCRegistrationFromPDFComplete />}
              />

              {/* routes for listiong dogs for sale */}
              <Route path="addtolist" element={<AddToList />} />
              <Route path="addtolistinput" element={<AddToListInput />} />
              <Route path="addtolistconfirm" element={<AddToListConfirm />} />
              <Route path="addtolistcomplete" element={<AddToListComplete />} />

              {/* routes for plan settings */}
              <Route path="plansetting" element={<ActivePlanDetails />} />

              {/* lookfor settings */}
              <Route path="lookfor-settings" element={<ContactQuestion />} />

              {/* Selling contract settings */}
              <Route
                path="hanbaikeiyakusho"
                element={<SellingContractSettings />}
              />
              <Route
                path="hanbaikeiyakusho/edit"
                element={<SellingContractSettingsEdit />}
              />

              <Route path="reviews" element={<Reviews />} />

              <Route path="subscription" element={<SubsDetails />} />
              <Route path="subscription-check" element={<SubsCheck />} />
              <Route
                path="subscription-card"
                element={
                  <SubsCard
                    komoju_customer_id={userData.komoju_customer_id || null}
                  />
                }
              />
            </Routes>
          </div>
        </>
      )}
    </>
  );
}
