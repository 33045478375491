import React, { useState } from "react";
import * as yup from "yup";
import _ from "lodash";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Formik } from "formik";

import ProgressStep from "../../components/ProgressStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import MCInputAnimal from "../mcregistration/MCInputAnimal";

import {
  zip3Schema,
  zip4Schema,
  prefectureSchema,
  addressLine1Schema,
  addressLine2Schema,
  addressLine3Schema,
  allAnimalSchema
} from "../../utils/InputSchema";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import { getRandomString } from "../../utils/Utils";

export default function TransferMCInput() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { userData } = useUserDetailsContext()

  const data = JSON.parse(localStorage.getItem("mccode"));
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const fullData = JSON.parse(localStorage.getItem("mcData"));
  const mcData = fullData?.mcData;

  const [animalLocationZipError, setAnimalLocationZipError] = useState(true);

  // const [sectionError2, setSectionError2] = useState(false);

  let initialValues = {
    mccode: data.mccode,
    animalID: fullData.animalID || getRandomString(8),
    animal_name: "",
    animal_group: mcData.animal_group,
    animal_gender: mcData.animal_gender,
    animal_breed: mcData.animal_breed,
    animal_breed_other: mcData.animal_breed_other,
    animal_color: mcData.animal_color,
    animal_color_other: mcData.animal_color_other,
    animal_birthDay: mcData.animal_birthDay,
    animalAddressCheck: ["on"],
    animal_location_zip3: "",
    animal_location_zip4: "",
    animal_location_prefecture: "",
    animal_location_addressLine1: "",
    animal_location_addressLine2: "",
    animal_location_addressLine3: "",
    animal_note: mcData.animal_note,
    overseas: mcData.overseas || "",
    price: ""
  };

  const backData = localStorage.getItem("mcDataAnimalTransfer");
  if (backData) {
    initialValues = {
      ...JSON.parse(localStorage.getItem("mcDataAnimalTransfer")),
      mccode: data.mccode
    };
  }

  let schema = yup.object().shape(allAnimalSchema(animalLocationZipError));
  schema = schema.shape({
    // animal_name: yup
    //   .string()
    //   .required("この欄は必須です")
    //   .max(30, "最小30全角半角混在以上にてご入力ください"),
    // animal_group: yup.string().required("この欄は必須です"),
    // animal_gender: yup.string().required("この欄は必須です"),
    // animal_breed: yup.string().required("この欄は必須です"),
    // animal_breed_other: yup.string().when("animal_breed", {
    //   is: (animal_breed) => animal_breed === "999",
    //   then: yup
    //     .string()
    //     .required("この欄は必須です")
    //     .max(26, "最小26全角半角混在以上にてご入力ください")
    // }),
    // animal_color: yup.string().required("この欄は必須です"),
    // animal_color_other: yup.string().when("animal_color", {
    //   is: (animal_color) => animal_color === "99",
    //   then: yup
    //     .string()
    //     .required("この欄は必須です")
    //     .max(26, "最小26全角半角混在以上にてご入力ください")
    // }),
    // animal_birthDay: yup
    //   .date()
    //   .required("この欄は必須です")
    //   .max(new Date(), "生年月日は未来の日付であってはならない。"),

    // animal_note: yup
    //   .string()
    //   .max(200, "最小200全角半角混在以上にてご入力ください"),
    // animalAddressCheck: yup.array(),

    // animal_location_zip3: yup.string().when("animalAddressCheck", {
    //   is: (animalAddressCheck) => !_.includes(animalAddressCheck, "on"),
    //   then: zip3Schema(animalLocationZipError)
    // }),
    // animal_location_zip4: yup.string().when("animalAddressCheck", {
    //   is: (animalAddressCheck) => !_.includes(animalAddressCheck, "on"),
    //   then: zip4Schema(animalLocationZipError)
    // }),

    // animal_location_prefecture: yup.string().when("animalAddressCheck", {
    //   is: (animalAddressCheck) => !_.includes(animalAddressCheck, "on"),
    //   then: prefectureSchema()
    // }),
    // animal_location_addressLine1: yup.string().when("animalAddressCheck", {
    //   is: (animalAddressCheck) => !_.includes(animalAddressCheck, "on"),
    //   then: addressLine1Schema()
    // }),
    // animal_location_addressLine2: yup.string().when("animalAddressCheck", {
    //   is: (animalAddressCheck) => !_.includes(animalAddressCheck, "on"),
    //   then: addressLine2Schema()
    // }),
    // animal_location_addressLine3: addressLine3Schema(),
    overseas: yup
      .string()
      .required("この欄は必須です")
      .oneOf(["1", "2"], "海外出国をお選びください"),
    price: yup.number().required("この欄は必須です")
  });

  const handleSubmit = (values) => {
    try {
      const serializedState = JSON.stringify(values);
      localStorage.setItem("mcDataAnimalTransfer", serializedState);
      navigate("/mypage/transferconfirm");
    } catch (err) {
      return undefined;
    }
  };

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={
              userInfo.group === "owner"
                ? userData.plan === "LITE"
                  ? [
                      { value: "スキャン", link: "/mypage/transferstart" },
                      { value: "メール", link: "#" },
                      { value: "OTP", link: "#" },
                      { value: "入力", link: "#" },
                      { value: "確認", link: "#" },
                      { value: "支払", link: "#" },
                      { value: "終了", link: "#" }
                    ]
                  : [
                      { value: "スキャン", link: "/mypage/transferstart" },
                      { value: "メール", link: "#" },
                      { value: "OTP", link: "#" },
                      { value: "入力", link: "#" },
                      { value: "確認", link: "#" },
                      { value: "販売契約書", link: "#" },
                      { value: "支払", link: "#" },
                      { value: "終了", link: "#" }
                    ]
                : [
                    { value: "スキャン", link: "/mypage/transferstart" },
                    { value: "メール", link: "#" },
                    { value: "OTP", link: "#" },
                    { value: "入力", link: "#" },
                    { value: "確認", link: "#" },
                    { value: "終了", link: "#" }
                  ]
            }
            activeNo={4}
          />
        </Row>
        <Row className="justify-content-center mb-4">
          <Col lg={8} md={10}>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                handleSubmit
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <h4 className="my-3">犬・猫の情報入力{values.animalID}</h4>
                  <MCInputAnimal
                    setAnimalLocationZipError={setAnimalLocationZipError}
                    sectionError={() => {}}
                    PIN={true}
                    transfer={true}
                  />

                  <Row className="px-lg-3">
                    <Col
                      lg={3}
                      className={`bg-secondary bg-opacity-10 ${
                        width < 576 && `border`
                      } ${
                        width >= 576 && `border-bottom border-start `
                      } border-secondary p-2`}
                    >
                      海外出国<span className="text-danger">※</span>
                    </Col>
                    <Col
                      lg={9}
                      className={`${width < 576 && `border-start border-end`} ${
                        width >= 576 && `border-start border-bottom border-end`
                      } p-1 py-2 border-secondary`}
                    >
                      <Form.Check
                        inline
                        name="overseas"
                        id="overseas1"
                        label="国間移動無し／国内に帰国"
                        value="1"
                        type="radio"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.overseas === "1" ? true : false}
                        isInvalid={touched.overseas && errors.overseas}
                        isValid={touched.overseas && !errors.overseas}
                      />
                      <Form.Check
                        inline
                        name="overseas"
                        id="overseas2"
                        label="海外へ出国"
                        value="2"
                        type="radio"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.overseas === "2" ? true : false}
                        isInvalid={touched.overseas && errors.overseas}
                        isValid={touched.overseas && !errors.overseas}
                      />
                      {touched.overseas && errors.overseas && (
                        <div className="text-danger">{errors.overseas}</div>
                      )}
                    </Col>
                  </Row>
                  <Row className="px-lg-3">
                    <Col
                      lg={3}
                      className={`bg-secondary bg-opacity-10 ${
                        width < 576 && `border`
                      } ${
                        width >= 576 && `border-bottom border-start `
                      } border-secondary p-2`}
                    >
                      価格<span className="text-danger">※</span>
                    </Col>
                    <Col
                      lg={9}
                      className={`${width < 576 && `border-start border-end`} ${
                        width >= 576 && `border-start border-bottom border-end`
                      } p-1  border-secondary`}
                    >
                      <InputGroup hasValidation>
                        <Form.Control
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.price}
                          name="price"
                          maxLength={150}
                          required
                          isInvalid={touched.price && errors.price}
                          isValid={touched.price && !errors.price}
                        />
                        <InputGroup.Text>円(税込)</InputGroup.Text>

                        <Form.Control.Feedback type="invalid">
                          {errors.price}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                  </Row>

                  <div className="text-center mt-4">
                    <Button className="mt-1" variant="primary" type="submit">
                      <b>
                        次へ <FontAwesomeIcon icon={faCircleChevronRight} />
                      </b>
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
}
