import {
  faCircleChevronLeft,
  faCircleChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { increment } from "firebase/firestore";

import _ from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Alert, Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ProgressStep from "../../components/ProgressStep";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import { useAddDocs } from "../../hooks/useAddDocs";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useDeleteDocs } from "../../hooks/useDeleteDocs";
import { useFirebaseIncrement } from "../../hooks/useFirebaseIncrement";
import { useSetDoc } from "../../hooks/useSetDoc";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import {
  dog_breed,
  cat_breed,
  dog_color,
  cat_color
} from "../../utils/CatDogData";

export default function MCInputCheck() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("mcData")) || false
  );
  const [mcinstall, setMcinstall] = useState(
    JSON.parse(localStorage.getItem("mcinstal")) || false
  );

  const [unregistered, setUnregistered] = useState(
    localStorage.getItem("unregistered") || false
  );

  const [animalID, setanimalID] = useState(
    localStorage.getItem("animalID") || false
  );

  const [breed, setBreed] = useState(null);
  const [color, setColor] = useState(null);

  useEffect(() => {
    if (!data) {
      navigate("mypage/mcregistration");
    } else {
      if (data.animal_group === "犬") {
        setBreed(dog_breed);
        setColor(dog_color);
      } else if (data.animal_group === "猫") {
        setBreed(cat_breed);
        setColor(cat_color);
      }
    }
  }, []);

  const { user } = useAuthContext();
  const { userData } = useUserDetailsContext();

  const { addADoc, isPending: isPending2, error: error2 } = useAddDocs();
  const {
    firebaseSetDoc,
    firebaseSetDocQuery,
    isPending: isPending4,
    error: error4
  } = useSetDoc();
  const {
    deleteDocument,
    isPending: isPending5,
    error: error5
  } = useDeleteDocs();
  const { firebaseIncrementMany } = useFirebaseIncrement();
  const { userAnalytics } = useAnalytics();
  const type = localStorage.getItem("type");

  const mcregistrationHandler = async () => {
    let microchipData = JSON.parse(localStorage.getItem("mcData"));

    await firebaseSetDoc(
      "microchip",
      {
        ...microchipData,
        unregistered: unregistered,
        currentOwnerUID: user.uid,
        registeredUID: user.uid,
        owners: [
          {
            uid: user.uid,
            name: `${userData.firstName} ${userData.lastName}`,
            group: userData.group,
            time: new Date()
          }
        ]
      },
      !unregistered ? microchipData.mccode.toString() : animalID
    ).then(() => {
      let ownersData = {
        mccode: !unregistered ? microchipData.mccode.toString() : animalID,
        unregistered: unregistered,
        relation: "OWNER",
        animalID: animalID,
        mcData: { ...microchipData }
      };

      addADoc(`users/${user.uid}/microchip`, ownersData);
      if (!unregistered) {
        //increase the totalMC in users collenction
        firebaseIncrementMany("users", user.uid, {
          totalMC: increment(1)
        });

        const date = moment();
        const yyyy = moment(date).format("YYYY");
        const mm = moment(date).format("MM");
        const dd = moment(date).format("DD");

        userAnalytics(
          user.uid,
          JSON.parse(localStorage.getItem("mcData")),
          yyyy,
          mm,
          dd,
          "R"
        );
      }
      //delete vet data in mcinstall collection
      if (mcinstall && mcinstall.mcinstallId) {
        deleteDocument(
          `users/${user.uid}/mcinstall`,
          mcinstall.mcinstallId
        ).then(
          //delete localstorage mcinstal
          localStorage.removeItem("mcinstal")
        );
      }

      //save update trigger
      const updateList = JSON.parse(localStorage.getItem("MY_DATA_1_Up")) || [];
      updateList.push(microchipData.mccode || animalID);
      localStorage.setItem("MY_DATA_1_Up", JSON.stringify(updateList));

      //redirect to success page
      navigate("/mypage/mcregistrationcomplete");
    });
  };

  const addMCRegistrationHandler = async () => {
    let microchipData = JSON.parse(localStorage.getItem("mcData"));

    await firebaseSetDoc(
      "microchip",
      {
        ...microchipData,
        unregistered: false,
        currentOwnerUID: user.uid,
        registeredUID: user.uid,
        owners: [
          {
            uid: user.uid,
            name: `${userData.firstName} ${userData.lastName}`,
            group: userData.group,
            time: new Date()
          }
        ]
      },
      microchipData.mccode.toString()
    ).then(() => {
      let ownersData = {
        mccode: microchipData.mccode.toString(),
        unregistered: false,
        relation: "OWNER",
        animalID: animalID,
        mcData: { ...microchipData }
      };

      firebaseSetDocQuery(
        `users/${user.uid}/microchip`,
        ownersData,
        [["animalID", "==", animalID]],
        true
      ).then(() => {
        deleteDocument("microchip", animalID.toString());
      });
      if (!unregistered) {
        //increase the totalMC in users collenction
        firebaseIncrementMany("users", user.uid, {
          totalMC: increment(1)
        });

        const date = moment();
        const yyyy = moment(date).format("YYYY");
        const mm = moment(date).format("MM");
        const dd = moment(date).format("DD");

        userAnalytics(
          user.uid,
          JSON.parse(localStorage.getItem("mcData")),
          yyyy,
          mm,
          dd,
          "R"
        );
      }

      //save update trigger
      localStorage.removeItem("MY_DATA_1");
      localStorage.removeItem("MY_DATA_1_Ex");

      //redirect to success page
      navigate("/mypage/mcregistrationcomplete");
    });
  };

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "スキャン", link: "#" },
              { value: "入力", link: "#" },
              { value: "確認", link: "#" },
              { value: "終了", link: "#" }
            ]}
            activeNo={3}
          />
        </Row>

        {!unregistered && (
          <>
            <Row className="justify-content-center">
              <Col lg={8}>
                <h4 className="mt-4">所有者情報</h4>
                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-top border-start `
                    } border-secondary p-2`}
                  >
                    MCの装着日
                  </Col>
                  <Col
                    lg={9}
                    className={`${width < 576 && `border-start border-end`} ${
                      width >= 576 &&
                      `border-top border-start border-bottom border-end`
                    } p-1 border-end border-secondary`}
                  >
                    {data.mcdate}
                  </Col>
                </Row>
                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}
                  >
                    MCを装着した施設名
                  </Col>
                  <Col
                    lg={9}
                    className={`${
                      width < 576 && `border-start border-end border-bottom`
                    } ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}
                  >
                    {data.vet_institute}
                  </Col>
                </Row>

                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}
                  >
                    MCを装着した施設の
                    <span className="text-nowrap">所在地</span>
                  </Col>
                  <Col
                    lg={9}
                    className={`${width < 576 && `border-start border-end`} ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}
                  >
                    <>
                      <p className="mb-0">
                        〒{data.vet_location_zip3}-{data.vet_location_zip4}
                      </p>
                      <p className="mb-0">
                        {data.vet_location_prefecture}{" "}
                        {data.vet_location_addressLine1}
                      </p>
                      <p className="mb-0">{data.vet_location_addressLine2}</p>
                      <p className="mb-0">{data.vet_location_addressLine3}</p>
                    </>
                  </Col>
                </Row>

                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}
                  >
                    MCを装着した施設の
                    <span className="text-nowrap">電話番号</span>
                  </Col>
                  <Col
                    lg={9}
                    className={`${
                      width < 576 && `border-start border-end border-bottom`
                    } ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}
                  >
                    {data.vet_phone}
                  </Col>
                </Row>

                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}
                  >
                    獣医師の氏名
                  </Col>
                  <Col
                    lg={9}
                    className={`${
                      width < 576 && `border-start border-end border-bottom`
                    } ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}
                  >
                    {data.vet_lastName} {data.vet_firstName}
                  </Col>
                </Row>

                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}
                  >
                    装着証明書
                  </Col>
                  <Col
                    lg={9}
                    className={`${
                      width < 576 && `border-start border-end border-bottom`
                    } ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}
                  >
                    <Row className="justify-content-center mb-4">
                      <Col lg={3} md={4}>
                        <a href={data.certificateDownloadLink} target="_blank">
                          <img
                            src={data.certificateDownloadLink}
                            alt=""
                            className="w-100"
                          />
                        </a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg={8}>
                <h4 className="mt-4">所有者情報</h4>
                {/* <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-top border-start `
                } border-secondary p-2`}
              >
                所有者種別
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 &&
                  `border-top border-start border-bottom border-end`
                } p-1 border-end border-secondary`}
              >
                {data.ownership === "2" ? "動物取扱業者" : "一般の所有者"}
              </Col>
            </Row> */}

                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-top border-start `
                    } border-secondary p-2`}
                  >
                    個人または法人
                  </Col>
                  <Col
                    lg={9}
                    className={`${width < 576 && `border-start border-end`} ${
                      width >= 576 &&
                      `border-top border-start border-bottom border-end`
                    } p-1 border-end border-secondary`}
                  >
                    {data.owner_type === "1" ? "個人" : "法人"}
                  </Col>
                </Row>
                {data.owner_type === "1" && (
                  <>
                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}
                      >
                        氏名
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end border-bottom`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}
                      >
                        {data.lastName} {data.firstName}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}
                      >
                        氏名（フリガナ）
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end border-bottom`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}
                      >
                        {data.lastKana} {data.firstKana}
                      </Col>
                    </Row>
                  </>
                )}
                {data.owner_type === "2" && (
                  <>
                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}
                      >
                        法人名
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end border-bottom`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}
                      >
                        {data.corporation_name}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}
                      >
                        法人名(フリガナ)
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end border-bottom`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}
                      >
                        {data.corporation_kana_name}
                      </Col>
                    </Row>

                    <Row className="px-lg-3">
                      <Col
                        lg={3}
                        className={`bg-secondary bg-opacity-10 ${
                          width < 576 && `border`
                        } ${
                          width >= 576 && `border-bottom border-start `
                        } border-secondary p-2`}
                      >
                        代表者の氏名
                      </Col>
                      <Col
                        lg={9}
                        className={`${
                          width < 576 && `border-start border-end border-bottom`
                        } ${
                          width >= 576 &&
                          `border-start border-bottom border-end`
                        } p-1 border-secondary`}
                      >
                        {data.corporation_representative_lastName}{" "}
                        {data.corporation_representative_firstName}
                      </Col>
                    </Row>
                  </>
                )}

                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}
                  >
                    住所
                    <br />
                    (法人にあっては、事務所の所在地)
                  </Col>
                  <Col
                    lg={9}
                    className={`${width < 576 && `border-start border-end`} ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}
                  >
                    <>
                      <p className="mb-0">
                        〒{data.owner_location_zip3}-{data.owner_location_zip4}
                      </p>
                      <p className="mb-0">
                        {data.owner_location_prefecture}{" "}
                        {data.owner_location_addressLine1}
                      </p>
                      <p className="mb-0">{data.owner_location_addressLine2}</p>
                      <p className="mb-0">{data.owner_location_addressLine3}</p>
                    </>
                  </Col>
                </Row>
                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}
                  >
                    電話番号
                  </Col>
                  <Col
                    lg={9}
                    className={`${
                      width < 576 && `border-start border-end border-bottom`
                    } ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}
                  >
                    {data.owner_fixed_phone}
                  </Col>
                </Row>

                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}
                  >
                    携帯電話番号
                  </Col>
                  <Col
                    lg={9}
                    className={`${
                      width < 576 && `border-start border-end border-bottom`
                    } ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}
                  >
                    {data.owner_phone}
                  </Col>
                </Row>

                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}
                  >
                    電子メールアドレス
                  </Col>
                  <Col
                    lg={9}
                    className={`${
                      width < 576 && `border-start border-end border-bottom`
                    } ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}
                  >
                    {data.owner_email}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
        <Row className="justify-content-center">
          <Col lg={8}>
            <h4 className="mt-4">犬猫情報</h4>
            {!unregistered && (
              <Row className="px-lg-3">
                <Col
                  lg={3}
                  className={`bg-secondary bg-opacity-10 ${
                    width < 576 && `border`
                  } ${
                    width >= 576 && `border-bottom border-top border-start `
                  } border-secondary p-2`}
                >
                  MC番号
                </Col>
                <Col
                  lg={9}
                  className={`${width < 576 && `border-start border-end`} ${
                    width >= 576 &&
                    `border-top border-start border-bottom border-end`
                  } p-1 border-end border-secondary`}
                >
                  {data.mccode}
                </Col>
              </Row>
            )}

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 &&
                  `border-bottom border-start ${unregistered && `border-top`}`
                } border-secondary p-2`}
              >
                ペットID
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 &&
                  `border-start border-bottom border-end ${
                    unregistered && `border-top`
                  }`
                } p-1 border-secondary`}
              >
                {animalID}
              </Col>
            </Row>

            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}
              >
                名前
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}
              >
                {data.animal_name}
              </Col>
            </Row>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}
              >
                犬or猫
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 py-2 border-secondary`}
              >
                {data.animal_group}
              </Col>
            </Row>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}
              >
                性別
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 py-2 border-secondary`}
              >
                {data.animal_gender}
              </Col>
            </Row>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}
              >
                品種
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}
              >
                {breed &&
                  breed.map((item) => {
                    if (item.value == data.animal_breed) {
                      return item.label;
                    }
                  })}

                {data.animal_breed === "999" && <>{data.animal_breed_other}</>}
              </Col>
            </Row>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}
              >
                毛色
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}
              >
                {color &&
                  color.map((item) => {
                    if (item.value == data.animal_color) {
                      return item.label;
                    }
                  })}

                {data.animal_color === "99" && <>{data.animal_color_other}</>}
              </Col>
            </Row>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}
              >
                生年月日
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}
              >
                {data.animal_birthDay}
              </Col>
            </Row>
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}
              >
                動物の所在地
              </Col>
              <Col
                lg={9}
                className={`${width < 576 && `border-start border-end`} ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}
              >
                {data.animalAddressCheck &&
                  data.animalAddressCheck[0] !== "on" && (
                    <>
                      <p className="mb-0">
                        〒{data.animal_location_zip3}-
                        {data.animal_location_zip4}
                      </p>
                      <p className="mb-0">
                        {data.animal_location_prefecture}{" "}
                        {data.animal_location_addressLine1}
                      </p>
                      <p className="mb-0">
                        {data.animal_location_addressLine2}
                      </p>
                      <p className="mb-0">
                        {data.animal_location_addressLine3}
                      </p>
                    </>
                  )}

                {data.animalAddressCheck &&
                  data.animalAddressCheck[0] === "on" && (
                    <>
                      {!unregistered ? (
                        <>
                          <p className="mb-0">
                            〒{data.owner_location_zip3}-
                            {data.owner_location_zip4}
                          </p>
                          <p className="mb-0">
                            {data.owner_location_prefecture}{" "}
                            {data.owner_location_addressLine1}
                          </p>
                          <p className="mb-0">
                            {data.owner_location_addressLine2}
                          </p>
                          <p className="mb-0">
                            {data.owner_location_addressLine3}
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="mb-0">
                            〒{userData.zip3}-{userData.zip4}
                          </p>
                          <p className="mb-0">
                            {userData.prefecture} {userData.addressLine1}
                          </p>
                          <p className="mb-0">{userData.addressLine2}</p>
                          <p className="mb-0">{userData.addressLine3}</p>
                        </>
                      )}
                    </>
                  )}
              </Col>
            </Row>
            {data.animal_group === "犬" && (
              <Row className="px-lg-3">
                <Col
                  lg={3}
                  className={`bg-secondary bg-opacity-10 ${
                    width < 576 && `border`
                  } ${
                    width >= 576 && `border-bottom border-start `
                  } border-secondary p-2`}
                >
                  鑑札
                </Col>
                <Col
                  lg={9}
                  className={`${width < 576 && `border-start border-end`} ${
                    width >= 576 && `border-start border-bottom border-end`
                  } p-1 border-secondary`}
                >
                  登録年月日: {data.dog_registration_date} <br />
                  登録番号: {data.dog_registration_number}
                </Col>
              </Row>
            )}
            <Row className="px-lg-3">
              <Col
                lg={3}
                className={`bg-secondary bg-opacity-10 ${
                  width < 576 && `border`
                } ${
                  width >= 576 && `border-bottom border-start `
                } border-secondary p-2`}
              >
                備考
              </Col>
              <Col
                lg={9}
                className={`${
                  width < 576 && `border-start border-end border-bottom`
                } ${
                  width >= 576 && `border-start border-bottom border-end`
                } p-1 border-secondary`}
              >
                {data.animal_note}
              </Col>
            </Row>
          </Col>
        </Row>

        {!unregistered && data.ownership === "2" && (
          <>
            <Row className="justify-content-center">
              <Col lg={8}>
                <h4 className="mt-4">動物取扱業関連情報</h4>

                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-top border-start `
                    } border-secondary p-2`}
                  >
                    動物取扱業選択
                  </Col>
                  <Col
                    lg={9}
                    className={`${width < 576 && `border-start border-end`} ${
                      width >= 576 &&
                      `border-top border-start border-bottom border-end`
                    } p-1 border-end border-secondary`}
                  >
                    {data.animal_handler_business_type === "1"
                      ? "第一種動物取扱業者"
                      : "第二種動物取扱業者"}
                  </Col>
                </Row>

                <Row className="px-lg-3">
                  <Col
                    lg={3}
                    className={`bg-secondary bg-opacity-10 ${
                      width < 576 && `border`
                    } ${
                      width >= 576 && `border-bottom border-start `
                    } border-secondary p-2`}
                  >
                    動物取扱業の情報
                  </Col>
                  <Col
                    lg={9}
                    className={`${
                      width < 576 && `border-start border-end border-bottom`
                    } ${
                      width >= 576 && `border-start border-bottom border-end`
                    } p-1 border-secondary`}
                  >
                    {data.animal_handler_business_type === "1" && (
                      <>
                        {_.includes(data.animal_handle_1, "1") && (
                          <>
                            販売: {data.animal_handler_business_type1_slot1}
                            <br />
                          </>
                        )}

                        {_.includes(data.animal_handle_1, "2") && (
                          <>
                            保管: {data.animal_handler_business_type1_slot2}
                            <br />
                          </>
                        )}

                        {_.includes(data.animal_handle_1, "3") && (
                          <>
                            貸出し: {data.animal_handler_business_type1_slot3}
                            <br />
                          </>
                        )}

                        {_.includes(data.animal_handle_1, "4") && (
                          <>
                            訓練: {data.animal_handler_business_type1_slot4}
                            <br />
                          </>
                        )}

                        {_.includes(data.animal_handle_1, "5") && (
                          <>
                            展示: {data.animal_handler_business_type1_slot5}
                            <br />
                          </>
                        )}

                        {_.includes(data.animal_handle_1, "6") && (
                          <>
                            競りあっせん:{" "}
                            {data.animal_handler_business_type1_slot6}
                            <br />
                          </>
                        )}

                        {_.includes(data.animal_handle_1, "7") && (
                          <>
                            譲受飼養業:{" "}
                            {data.animal_handler_business_type1_slot7}
                            <br />
                          </>
                        )}
                      </>
                    )}

                    {data.animal_handler_business_type === "2" && (
                      <>
                        <Row>
                          {_.includes(data.animal_handle_2, "1") && (
                            <Col xs={12}>譲渡し</Col>
                          )}
                          {_.includes(data.animal_handle_2, "2") && (
                            <Col xs={12}>保管</Col>
                          )}
                          {_.includes(data.animal_handle_2, "3") && (
                            <Col xs={12}>貸出し</Col>
                          )}
                          {_.includes(data.animal_handle_2, "4") && (
                            <Col xs={12}>訓練</Col>
                          )}
                          {_.includes(data.animal_handle_2, "5") && (
                            <Col xs={12}>展示</Col>
                          )}
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            {data.animal_handler_business_type === "1" &&
              _.includes(data.animal_handle_1, "1") && (
                <>
                  <Row className="justify-content-center">
                    <Col lg={8}>
                      <h4 className="mt-4">メス親情報</h4>
                      <Row className="px-lg-3">
                        <Col
                          lg={3}
                          className={`bg-secondary bg-opacity-10 ${
                            width < 576 && `border`
                          } ${
                            width >= 576 &&
                            `border-bottom border-top border-start `
                          } border-secondary p-2`}
                        >
                          メス親の取得時期
                        </Col>
                        <Col
                          lg={9}
                          className={`${
                            width < 576 && `border-start border-end`
                          } ${
                            width >= 576 &&
                            `border-top border-start border-bottom border-end`
                          } p-1 border-end border-secondary`}
                        >
                          {data.animal_mother_time === "1"
                            ? "令和4年6月1日以降に取得したメス親"
                            : "令和4年5月31日以前から所有しているメス親"}
                        </Col>
                      </Row>

                      <Row className="px-lg-3">
                        <Col
                          lg={3}
                          className={`bg-secondary bg-opacity-10 ${
                            width < 576 && `border`
                          } ${
                            width >= 576 && `border-bottom border-start `
                          } border-secondary p-2`}
                        >
                          メス親のマイクロチップ識別番号
                        </Col>
                        <Col
                          lg={9}
                          className={`${
                            width < 576 &&
                            `border-start border-end border-bottom`
                          } ${
                            width >= 576 &&
                            `border-start border-bottom border-end`
                          } p-1 border-secondary`}
                        >
                          {data.animal_mother_mccode}

                          {data.animal_mother_mccode_not_reason}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
          </>
        )}
        {(error2 || error4) && (
          <div className="text-center my-4">
            <Alert variant="danger" className="text-center">
              {error2 || error4}
            </Alert>
          </div>
        )}
        <div className="text-center mt-4">
          <Link className="btn btn-secondary m-2" to="/mypage/mcinput">
            <FontAwesomeIcon icon={faCircleChevronLeft} /> 修正する
          </Link>

          {!isPending2 && !isPending4 && (
            <Button
              className="btn btn-primary m-2"
              onClick={() => {
                type === "addMCInfo"
                  ? addMCRegistrationHandler()
                  : mcregistrationHandler();
              }}
            >
              保存 <FontAwesomeIcon icon={faCircleChevronRight} />
            </Button>
          )}

          {(isPending2 || isPending4) && (
            <Button className="btn btn-primary m-2" disabled>
              保存 <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </Button>
          )}
        </div>
      </Container>
    </>
  );
}
