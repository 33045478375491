import React, { useEffect } from "react";

import { Spinner } from "react-bootstrap";
import { Helmet, HelmetData } from "react-helmet-async";
import { Navigate, useNavigate } from "react-router-dom";

import VetMyPage from "./VetMypge";
import OwnerMyPage from "./OwnerMyPage";
import BreederMyPage from "./BreederMyPage";
import AdminMyPage from "../admin/AdminMyPage";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import { BreederCertificateProvider } from "../../context/BreederCertificateContext";

export default function MyPage({ setBg }) {
  const helmetData = new HelmetData({});
  const { userData, isPending } = useUserDetailsContext();
  const navigate = useNavigate()
  // useEffect(() => {
  //   if(userData && userData.group !== "breederpet" ) {
  //     // console.log("setBg false")
  //     setBg(false);
  //   }
  // }, []);

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>マイページ</title>
      </Helmet>
      {isPending && (
        <div className="position-absolute top-50 w-100 text-center">
          <Spinner className="" animation="grow" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {userData && userData.group === "vet" && <VetMyPage />}
      {userData && userData.group === "breederpet" && (
        <BreederCertificateProvider>
          <BreederMyPage setBg={setBg}/>
        </BreederCertificateProvider>
      )}

      {userData && userData.group === "owner" && <OwnerMyPage setBg={setBg(false)} />}
      {userData && userData.group === "ADMIN" && <AdminMyPage setBg={setBg(false)} />}
    </>
  );
}
