import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik, useFormikContext } from "formik";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Card,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import BarChart from "../../../components/chart/BarChart";
import DoughnutChart from "../../../components/chart/DoughnutChart";
import { useGetDoc } from "../../../hooks/useGetDoc";
import moment from "moment";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useUserDetailsContext } from "../../../context/UserDetailsContext";
import { json2array, mcChart } from "../../../utils/Utils";
import { useDistributedCounter } from "../../../hooks/useDistributedCounter";
import { Link } from "react-router-dom";

export default function Dashboard() {
  const { user } = useAuthContext();
  const { userData } = useUserDetailsContext();
  const [dropShow, setDropShow] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY/MM/DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY/MM/DD")
  );

  const [mcChartData, setMcChartData] = useState({
    totalData: 0,
    cartData: [],
  });
  const [mctChartData, setMctChartData] = useState({
    totalData: 0,
    cartData: [],
  });
  const [mcdChartData, setMcdChartData] = useState({
    totalData: 0,
    cartData: [],
  });

  const [cmChartData, setCmChartData] = useState({
    totalData: 0,
    cartData: [],
  });
  const [cfChartData, setCfChartData] = useState({
    totalData: 0,
    cartData: [],
  });
  const [cmdChartData, setCmdChartData] = useState({
    totalData: 0,
    cartData: [],
  });
  const [cfdChartData, setCfdChartData] = useState({
    totalData: 0,
    cartData: [],
  });
  const [cmtChartData, setCmtChartData] = useState({
    totalData: 0,
    cartData: [],
  });
  const [cftChartData, setCftChartData] = useState({
    totalData: 0,
    cartData: [],
  });

  const [dmChartData, setDmChartData] = useState({
    totalData: 0,
    cartData: [],
  });
  const [dfChartData, setDfChartData] = useState({
    totalData: 0,
    cartData: [],
  });
  const [dmdChartData, setDmdChartData] = useState({
    totalData: 0,
    cartData: [],
  });
  const [dfdChartData, setDfdChartData] = useState({
    totalData: 0,
    cartData: [],
  });
  const [dmtChartData, setDmtChartData] = useState({
    totalData: 0,
    cartData: [],
  });
  const [dftChartData, setDftChartData] = useState({
    totalData: 0,
    cartData: [],
  });

  const [mnyChartData, setMnyChartData] = useState({
    totalData: 0,
    cartData: [],
  });
  const n = 23123;

  const [analyticsData, setAnalyticsData] = useState([]);
  const { firebaseGetDoc, data, isPending, error } = useGetDoc();

  const getanal = async (startDate, endDate) => {
    let startYear = moment(startDate).format("YYYY");
    let endYear = moment(endDate).format("YYYY");
    let analData = [];
    for (let year = startYear; year <= endYear; year++) {
      // console.log(year);
      await firebaseGetDoc(`users/${user.uid}/analytics`, year.toString()).then(
        (res) => {
          analData[year] = json2array(res);
          // setAnalyticsData(analyticsData)
          // console.log(year, analyticsData)
        }
      );
    }
    // console.log(startYear, endYear, json2array(analData));
    setAnalyticsData(analData);
  };

  useEffect(() => {
    setAnalyticsData([]);
    getanal(startDate, endDate);
  }, []);

  useEffect(() => {
    if (analyticsData.length) {
      setMcChartData(mcChart(analyticsData, startDate, endDate, "mc"));
      setMctChartData(mcChart(analyticsData, startDate, endDate, "mct"));
      setMcdChartData(mcChart(analyticsData, startDate, endDate, "mcd"));

      setMnyChartData(mcChart(analyticsData, startDate, endDate, "mny"));

      setCmChartData(mcChart(analyticsData, startDate, endDate, "cm"));
      setCfChartData(mcChart(analyticsData, startDate, endDate, "cf"));
      setCmdChartData(mcChart(analyticsData, startDate, endDate, "cmd"));
      setCfdChartData(mcChart(analyticsData, startDate, endDate, "cfd"));
      setCmtChartData(mcChart(analyticsData, startDate, endDate, "cmt"));
      setCftChartData(mcChart(analyticsData, startDate, endDate, "cft"));

      setDmChartData(mcChart(analyticsData, startDate, endDate, "dm"));
      setDfChartData(mcChart(analyticsData, startDate, endDate, "df"));
      setDmdChartData(mcChart(analyticsData, startDate, endDate, "dmd"));
      setDfdChartData(mcChart(analyticsData, startDate, endDate, "dfd"));
      setDmtChartData(mcChart(analyticsData, startDate, endDate, "dmt"));
      setDftChartData(mcChart(analyticsData, startDate, endDate, "dft"));
    }
  }, [analyticsData, startDate, endDate]);

  const handleSubmit = (values) => {
    setStartDate(moment(values.startDate).format("YYYY/MM/DD"));
    setEndDate(moment(values.endDate).format("YYYY/MM/DD"));
    setDropShow(false);
  };
  let initialValues = {
    startDate: startDate,
    endDate: endDate,
  };
  const schema = yup.object().shape({
    startDate: yup.date().required("この欄は必須です"),

    endDate: yup
      .date()
      .required("この欄は必須です")
      .min(
        yup.ref("startDate"),
        "終了日は開始日よりも前にすることはできません"
      ),
  });

  //test
  const { dcfunction } = useDistributedCounter();

  const incHandler = async (values) => {
    const payload = [
      {
        collection: "analytics",
        document: "202202",
        field: "04.mc",
        incrementBy: 1,
      },
      {
        collection: "analytics",
        document: "202202",
        field: "04.mct",
        incrementBy: 1,
      },
      {
        collection: "analytics",
        document: "202202",
        field: "03.mc",
        incrementBy: 2,
      },
      {
        collection: "analytics",
        document: "202102",
        field: "01.mcd",
        incrementBy: 1,
      },
    ];
    await dcfunction(payload);
  };

  return (
    <>
      <Container>
        <Row className="justify-content-end">
          <Col className="text-end">
            <a
              className="btn btn-success"
              href={"/mypage/report"}
              target="_blank" rel="noreferrer"
            >
              レポートダウンロード
            </a>
            <Dropdown align="end" show={dropShow}>
              <Dropdown.Toggle
                variant="primary-outline"
                id="dropdown-basic"
                onClick={() => setDropShow(true)}
              >
                <FontAwesomeIcon icon={faCalendar} /> {startDate}-{endDate}
              </Dropdown.Toggle>

              <Dropdown.Menu className="p-3">
                <Formik
                  initialValues={initialValues}
                  validationSchema={schema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="Date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.startDate}
                          name="startDate"
                          isInvalid={touched.startDate && errors.startDate}
                          isValid={touched.startDate && !errors.startDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.startDate}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Control
                          type="Date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.endDate}
                          name="endDate"
                          isInvalid={touched.endDate && errors.endDate}
                          isValid={touched.endDate && !errors.endDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.endDate}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Button variant="primary" size="sm" type="submit">
                        保存
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>

        <Row>
          <Col md={4} className="mb-4">
            <Card
              className=""
              style={{
                border: "1px solid rgb(75, 192, 192)",
                color: "rgb(75, 192, 192)",
              }}
            >
              <Card.Body className=" p-0">
                <div className="p-3">
                  <h5>MC情報登録数</h5>
                  <h4 className="mb-0">
                    {mcChartData.totalData.toLocaleString()}
                  </h4>
                  <small>
                    合計:{" "}
                    {(userData.totalMC ? userData.totalMC : 0).toLocaleString()}
                  </small>
                </div>
                <div
                  style={{
                    height: "95px",
                    margin: "-45px 0 0 0",
                  }}
                >
                  <BarChart
                    chartData={mcChartData.cartData}
                    // chartData={[]}
                    color={"75, 192, 192"}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="mb-4">
            <Card className=" border border-secondary">
              <Card.Body className=" text-secondary p-0">
                <div className="p-3">
                  <h5>譲渡先</h5>
                  <h4 className="mb-0">
                    {mctChartData.totalData.toLocaleString()}
                  </h4>
                  <small>
                    合計:{" "}
                    {(userData.transferMC
                      ? userData.transferMC
                      : 0
                    ).toLocaleString()}
                  </small>
                </div>
                <div
                  style={{
                    height: "95px",
                    margin: "-45px 0 0 0",
                  }}
                >
                  <BarChart
                    chartData={mctChartData.cartData}
                    color={" 108, 117, 125"}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="mb-4">
            <Card
              className=""
              style={{
                border: "1px solid rgb(255, 99, 132)",
                color: "rgb(255, 99, 132)",
              }}
            >
              <Card.Body className=" p-0">
                <div className="p-3">
                  <h5>死亡</h5>
                  <h4 className="mb-0">
                    {mcdChartData.totalData.toLocaleString()}
                  </h4>
                  <small>
                    合計:{" "}
                    {(userData.totalDeath
                      ? userData.totalDeath
                      : 0
                    ).toLocaleString()}
                  </small>
                </div>
                <div
                  style={{
                    height: "95px",
                    margin: "-45px 0 0 0",
                  }}
                >
                  <BarChart
                    chartData={mcdChartData.cartData}
                    // chartData={[]}
                    color={"255, 99, 132"}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={4} className="mb-4">
            <Card
              className=""
              style={{
                border: "1px solid rgb(54, 162, 235)",
                color: "rgb(54, 162, 235)",
              }}
            >
              <Card.Body className=" p-0">
                <div className="p-3">
                  <h5>決済情報</h5>
                  <h4 className="mb-0">
                    {mnyChartData.totalData.toLocaleString()}円
                  </h4>
                  <small>
                    合計:{" "}
                    {(
                      (userData.transferMNY ? parseInt(userData.transferMNY) : 0) +
                      (userData.registrationMNY ? parseInt(userData.registrationMNY) : 0) +
                      (userData.planMNY ? parseInt(userData.planMNY) : 0)
                    ).toLocaleString()}
                    円
                  </small>
                </div>
                <div
                  style={{
                    height: "95px",
                    margin: "-45px 0 0 0",
                  }}
                >
                  <BarChart
                    chartData={mnyChartData.cartData}
                    color={"54, 162, 235"}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className=" border border-secondary h-100">
              <Card.Body className=" text-secondary">
                <Row>
                  <Col xs={7} lg={6} xl={7} xxl={8}>
                    <h5>犬猫比</h5>
                    <h4
                      className="mb-0"
                      style={{ color: "rgba(255, 99, 132, 1)" }}
                    >
                      犬:
                      {(
                        dmChartData.totalData + dfChartData.totalData
                      ).toLocaleString()}
                    </h4>
                    <h4
                      className="mb-0"
                      style={{ color: "rgba(54, 162, 235, 1)" }}
                    >
                      猫:
                      {(
                        cmChartData.totalData + cfChartData.totalData
                      ).toLocaleString()}
                    </h4>
                    <small>
                      合計:{" "}
                      {(
                        dmChartData.totalData +
                        dfChartData.totalData +
                        (cmChartData.totalData + cfChartData.totalData)
                      ).toLocaleString()}
                    </small>
                  </Col>
                  <Col xs={5} lg={6} xl={5} xxl={4} className="">
                    <DoughnutChart
                      chartData={[
                        dmChartData.totalData + dfChartData.totalData,
                        cmChartData.totalData + cfChartData.totalData,
                      ]}
                      labels={["犬", "猫"]}
                      backgroundColor={[
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                      ]}
                      borderColor={[
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                      ]}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-4">
            <Card style={{ borderColor: "rgba(255, 99, 132, 1)" }}>
              <Card.Body className=" text-secondary">
                <Row>
                  <Col xs={7} lg={6} xl={7} xxl={8}>
                    <h5 style={{ color: "rgba(255, 99, 132, 1)" }}>犬</h5>
                    <h4 className="mb-0" style={{ color: "#495057" }}>
                      オス:
                      {dmChartData.totalData.toLocaleString()}
                    </h4>
                    <h4 className="mb-0" style={{ color: "#1aa179" }}>
                      メス:
                      {dfChartData.totalData.toLocaleString()}
                    </h4>
                    <small>
                      合計:{" "}
                      {(
                        dmChartData.totalData + dfChartData.totalData
                      ).toLocaleString()}
                    </small>
                  </Col>
                  <Col xs={5} lg={6} xl={5} xxl={4} className="">
                    <DoughnutChart
                      chartData={[dmChartData.totalData, dfChartData.totalData]}
                      labels={["オス", "メス"]}
                      backgroundColor={["#f8f9fa", "#d2f4ea"]}
                      borderColor={["#495057", "#1aa179"]}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card style={{ borderColor: "rgba(255, 99, 132, 1)" }}>
              <Card.Body className=" text-secondary">
                <Row>
                  <Col xs={7} lg={6} xl={7} xxl={8}>
                    <h5 style={{ color: "rgba(255, 99, 132, 1)" }}>犬</h5>
                    <h4 className="mb-0" style={{ color: "#495057" }}>
                      所有数:
                      {(
                        dmChartData.totalData +
                        dfChartData.totalData -
                        dmtChartData.totalData -
                        dftChartData.totalData
                      ).toLocaleString()}
                    </h4>
                    <h4 className="mb-0" style={{ color: "#1aa179" }}>
                      譲渡:
                      {(
                        dmtChartData.totalData + dftChartData.totalData
                      ).toLocaleString()}
                    </h4>
                    <small>
                      合計:{" "}
                      {(
                        dmChartData.totalData +
                        dfChartData.totalData +
                        dmtChartData.totalData +
                        dftChartData.totalData
                      ).toLocaleString()}
                    </small>
                  </Col>
                  <Col xs={5} lg={6} xl={5} xxl={4} className="">
                    <DoughnutChart
                      chartData={[
                        dmChartData.totalData + dfChartData.totalData,
                        dmtChartData.totalData + dftChartData.totalData,
                      ]}
                      labels={["所有数", "譲渡"]}
                      backgroundColor={["#f8f9fa", "#d2f4ea"]}
                      borderColor={["#495057", "#1aa179"]}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card style={{ borderColor: "rgba(255, 99, 132, 1)" }}>
              <Card.Body className=" text-secondary">
                <Row>
                  <Col xs={7} lg={6} xl={7} xxl={8}>
                    <h5 style={{ color: "rgba(255, 99, 132, 1)" }}>犬死亡</h5>
                    <h4 className="mb-0" style={{ color: "#495057" }}>
                      オス:
                      {dmdChartData.totalData.toLocaleString()}
                    </h4>
                    <h4 className="mb-0" style={{ color: "#1aa179" }}>
                      メス:
                      {dfdChartData.totalData.toLocaleString()}
                    </h4>
                    <small>
                      合計:{" "}
                      {(
                        dmdChartData.totalData + dfdChartData.totalData
                      ).toLocaleString()}
                    </small>
                  </Col>
                  <Col xs={5} lg={6} xl={5} xxl={4} className="">
                    <DoughnutChart
                      chartData={[
                        dmdChartData.totalData,
                        dfdChartData.totalData,
                      ]}
                      labels={["オス", "メス"]}
                      backgroundColor={["#f8f9fa", "#d2f4ea"]}
                      borderColor={["#495057", "#1aa179"]}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-4">
            <Card style={{ borderColor: "rgba(54, 162, 235, 1)" }}>
              <Card.Body className=" text-secondary">
                <Row>
                  <Col xs={7} lg={6} xl={7} xxl={8}>
                    <h5 style={{ color: "rgba(54, 162, 235, 1)" }}>猫</h5>
                    <h4 className="mb-0" style={{ color: "#495057" }}>
                      オス:
                      {cmChartData.totalData.toLocaleString()}
                    </h4>
                    <h4 className="mb-0" style={{ color: "#1aa179" }}>
                      メス:
                      {cfChartData.totalData.toLocaleString()}
                    </h4>
                    <small>
                      合計:{" "}
                      {(
                        cmChartData.totalData + cfChartData.totalData
                      ).toLocaleString()}
                    </small>
                  </Col>
                  <Col xs={5} lg={6} xl={5} xxl={4} className="">
                    <DoughnutChart
                      chartData={[cmChartData.totalData, cfChartData.totalData]}
                      labels={["オス", "メス"]}
                      backgroundColor={["#f8f9fa", "#d2f4ea"]}
                      borderColor={["#495057", "#1aa179"]}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="mb-4">
            <Card style={{ borderColor: "rgba(54, 162, 235, 1)" }}>
              <Card.Body className=" text-secondary">
                <Row>
                  <Col xs={7} lg={6} xl={7} xxl={8}>
                    <h5 style={{ color: "rgba(54, 162, 235, 1)" }}>猫</h5>
                    <h4 className="mb-0" style={{ color: "#495057" }}>
                      所有数:
                      {(
                        cmChartData.totalData +
                        cfChartData.totalData -
                        cmtChartData.totalData -
                        cftChartData.totalData
                      ).toLocaleString()}
                    </h4>
                    <h4 className="mb-0" style={{ color: "#1aa179" }}>
                      譲渡:
                      {(
                        cmtChartData.totalData + cftChartData.totalData
                      ).toLocaleString()}
                    </h4>
                    <small>
                      合計:{" "}
                      {(
                        cmChartData.totalData +
                        cfChartData.totalData +
                        cmtChartData.totalData +
                        cftChartData.totalData
                      ).toLocaleString()}
                    </small>
                  </Col>
                  <Col xs={5} lg={6} xl={5} xxl={4} className="">
                    <DoughnutChart
                      chartData={[
                        cmChartData.totalData + cfChartData.totalData,
                        cmtChartData.totalData + cftChartData.totalData,
                      ]}
                      labels={["所有数", "譲渡"]}
                      backgroundColor={["#f8f9fa", "#d2f4ea"]}
                      borderColor={["#495057", "#1aa179"]}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="mb-4">
            <Card style={{ borderColor: "rgba(54, 162, 235, 1)" }}>
              <Card.Body className=" text-secondary">
                <Row>
                  <Col xs={7} lg={6} xl={7} xxl={8}>
                    <h5 style={{ color: "rgba(54, 162, 235, 1)" }}>猫死亡</h5>
                    <h4 className="mb-0" style={{ color: "#495057" }}>
                      オス:
                      {cmdChartData.totalData.toLocaleString()}
                    </h4>
                    <h4 className="mb-0" style={{ color: "#1aa179" }}>
                      メス:
                      {cfdChartData.totalData.toLocaleString()}
                    </h4>
                    <small>
                      合計:{" "}
                      {(
                        cmdChartData.totalData + cfdChartData.totalData
                      ).toLocaleString()}
                    </small>
                  </Col>
                  <Col xs={5} lg={6} xl={5} xxl={4} className="">
                    <DoughnutChart
                      chartData={[
                        cmdChartData.totalData,
                        cfdChartData.totalData,
                      ]}
                      labels={["オス", "メス"]}
                      backgroundColor={["#f8f9fa", "#d2f4ea"]}
                      borderColor={["#495057", "#1aa179"]}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
