import React, { useEffect, useState } from "react";

import moment from "moment";
import StarRatings from "react-star-ratings";
import { Badge, Button, Form, Modal, Spinner } from "react-bootstrap";

import { Timestamp } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";

import styles from "./SideBar.module.css";
import { useGetDoc } from "../hooks/useGetDoc";
import { useSetDoc } from "../hooks/useSetDoc";
import { useAddDocs } from "../hooks/useAddDocs";
import { useAuthContext } from "../hooks/useAuthContext";
import reviewStyles from "./review/ReviewItem.module.css";

function ReviewModal(props) {
  const { firebaseGetDoc, getSingleDocWithQuery, data, isPending, error } =
    useGetDoc();
  const { addADoc, isPending: isPend, error: error2 } = useAddDocs();
  const { firebaseSetDoc, isPending: isPending4, error: error4 } = useSetDoc();

  const { user } = useAuthContext();

  const [breederDetails, setBreederDetails] = useState({});
  const [ratings, setRatings] = useState(0);
  const [review, setReview] = useState("");

  useEffect(() => {
    getBreederDetails();
  }, []);

  async function getBreederDetails() {
    if (props.data.prevTransferDetails) {
      let breeder = await firebaseGetDoc(
        "users",
        props.data.prevTransferDetails.prevOwnerId
      );
      if (breeder) {
        setBreederDetails(breeder);
      }
    }
  }

  async function handleSubmit() {
    const reviewData = {
      rating: ratings,
      review
    };
    // console.log(reviewData);
    await addADoc(
      `users/${props.data.prevTransferDetails.prevOwnerId}/reviews`,
      { ...reviewData, mccode: props.data?.mccode, owner_id: user.uid }
    ).then(async () => {
      await firebaseSetDoc(
        "microchip",
        {
          review: {
            ...reviewData,
            owner_id: user.uid,
            breeder_id: props.data.prevTransferDetails.prevOwnerId
          },
          reviewAt: new Date()
        },
        props.data?.mccode.toString(),
        true
      ).then(async () => {
        const ownersData = {
          review: {
            ...reviewData,
            reviewAt: new Date()
          }
        };
        await firebaseSetDoc(
          `users/${user.uid}/microchip`,
          ownersData,
          props.data?.id.toString(),
          true
        ).then(() => {
          props.getUpdatedMCDatabyID(props.data?.id);
        });

        props.onHide();
      });
    });
  }
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          MC番号
          <span className="text-nowrap">#{props.data.mccode}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="my-2 d-flex justify-content-center">
          <img
            src={
              breederDetails.img ? breederDetails.img : "/assets/img/art/p2.png"
            }
            alt="profile"
            className={`${styles.avater} img-fluid rounded-circle mx-3 border shadow-sm`}
          />
          <div>
            <Badge pill bg="primary">
              ブリーダー/ペットショップ
            </Badge>
            <p className="mb-0 fw-bold fs-4">
              {props?.data?.prevTransferDetails?.prevOwnerName}様
            </p>
          </div>
        </div>
        <br />

        <div className="text-center">
          <Form.Label>満足度</Form.Label>
          <br />

          {props.data.review ? (
            <StarRatings
              rating={props.data.review.rating}
              starDimension="40px"
              starSpacing="2px"
              starRatedColor="#FFC417"
              starHoverColor="#FFC417"
            />
          ) : (
            <StarRatings
              rating={ratings}
              changeRating={setRatings}
              starDimension="40px"
              starSpacing="2px"
              starRatedColor="#FFC417"
              starHoverColor="#FFC417"
            />
          )}
        </div>
        <br />

        <Form.Group>
          <Form.Label>レビュー{!props.data.review && "本文"}</Form.Label>
          {!props.data.review ? (
            <Form.Control
              as="textarea"
              rows={3}
              onChange={(e) => {
                setReview(e?.target.value);
              }}
              value={review}
            />
          ) : (
            <div className={reviewStyles.reviewTextDetails}>
              <p className={reviewStyles.reviewText}>
                {props.data.review.review}
              </p>
              <p className={reviewStyles.reviewDate}>
                {moment(
                  new Timestamp(
                    props.data.review.reviewAt.seconds,
                    props.data.review.reviewAt.nanoseconds
                  ).toDate()
                ).format("YYYY/MM/DD HH:mm")}
              </p>

              <ul className={reviewStyles.listItem}>
                <li className="d-flex flex-column">
                  <div className={`w-100 ${reviewStyles.ReviewReplyText}`}>
                    <p className={`${reviewStyles.reviewText} mb-1`}>
                      ブリーダーからの返信
                    </p>
                    <p className={reviewStyles.reviewText}>
                      {props.data.review.replay[0]?.comment}
                    </p>
                  </div>
                  <p className={`${reviewStyles.reviewDate} ms-auto mb-0`}>
                    {moment(
                      new Timestamp(
                        props.data.review.replay[0]?.replayAt.seconds,
                        props.data.review.replay[0]?.replayAt.nanoseconds
                      ).toDate()
                    ).format("YYYY/MM/DD HH:mm")}
                  </p>
                </li>
              </ul>
            </div>
          )}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>閉じる</Button>

        {!props.data.review && (
          <>
            {!isPend && (
              <>
                {ratings > 0 ? (
                  <Button variant="warning" onClick={handleSubmit}>
                    投稿 <FontAwesomeIcon icon={faCircleChevronRight} />
                  </Button>
                ) : (
                  <Button variant="warning" disabled type="button">
                    投稿 <FontAwesomeIcon icon={faCircleChevronRight} />
                  </Button>
                )}
              </>
            )}

            {isPend && (
              <Button variant="warning" disabled type="button">
                投稿 <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ReviewModal;
