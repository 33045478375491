import React, { useEffect, useState } from "react";

import _ from "lodash";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import { arrayUnion, increment } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faEdit,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import CardDiv from "../../components/CardDiv";
import { storage } from "../../firebase/config";
import { useSetDoc } from "../../hooks/useSetDoc";
import { useAddDocs } from "../../hooks/useAddDocs";
import ProgressStep from "../../components/ProgressStep";
import { useDeleteDocs } from "../../hooks/useDeleteDocs";
import { useAuthContext } from "../../hooks/useAuthContext";
import CardRegistration from "../creditcard/CardRegistration";
import { useCreatePayment } from "../../hooks/komoju/useCreatePayment";
import { useFirebaseIncrement } from "../../hooks/useFirebaseIncrement";
import { useUserDetailsContext } from "../../context/UserDetailsContext";

function TransferPay({ komoju_customer_id }) {
  const { userData } = useUserDetailsContext();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [changeCardDiv, setChangeCardDiv] = useState(false);
  const [isCustomPending, setIsCustomPending] = useState(false);

  //TODO: have to get payment cost from db
  const paymentCost = userData.planWithAid ? 12000 : 1000;

  const { makePayment, isPending, error } = useCreatePayment();
  const { addADoc, isPending: isPending2, error: error2 } = useAddDocs();

  const { firebaseSetDoc, isPending: isPending4, error: error4 } = useSetDoc();
  const { firebaseIncrement, firebaseIncrementMany } = useFirebaseIncrement();
  const { deleteDocument } = useDeleteDocs();

  const mcregistrationHandler = async () => {
    setIsCustomPending(true);

    let downloadLink = false;
    let sellingContractData =
      JSON.parse(localStorage.getItem("sellingContractData")) || false;
    let microchipData = JSON.parse(localStorage.getItem("mcData"));
    let ownerData = JSON.parse(localStorage.getItem("userInfo"));
    let mcDataAnimalTransfer = JSON.parse(
      localStorage.getItem("mcDataAnimalTransfer")
    );
    let paymentsData = {};

    //check the user plan and upload the selling contract papers sign image

    if (
      (userData.plan === "STANDARD" || userData.plan === "PREMIUM") &&
      sellingContractData &&
      sellingContractData.signature
    ) {
      const imgRef = ref(
        storage,
        `signature/${microchipData.mccode}_${moment(new Date()).format(
          "YYYYMMDD_HHMMSS"
        )}.png`
      );

      await uploadString(imgRef, sellingContractData.signature, "data_url");
      downloadLink = await getDownloadURL(imgRef);
    }

    const ownersData = {
      mccode: microchipData.mccode.toString(),
      relation: "TRANSFERING",
      mcData: {
        ...microchipData.mcData,
        ..._.omit(mcDataAnimalTransfer, ["price", "mccode"]),
      },
      prevTransferDetails: {
        prevOwnerId: user.uid,
        prevMCId: microchipData.id,
        prevOwnerName:
          userData.type === "1"
            ? `${userData.lastName} ${userData.firstName}`
            : `${userData.corporation_name}`,
        prevOwnerGroup: userData.group,
        transferAt: new Date(),
      },
      purchasePrice: mcDataAnimalTransfer.price,
      sellingContractData: { ...sellingContractData, signature: downloadLink },
    };

    //make payment and get payment ID
    await makePayment(komoju_customer_id, paymentCost).then((res) => {
      //get the mc datas and attached the payment id
      let transfer_type = null;
      if (ownerData.group === "breederpet") {
        transfer_type = "B2B";
      } else if (ownerData.group === "owner") {
        transfer_type = "B2C";
      }
      // else if (userData.group === "3") {
      //   transfer_type = "C2C";
      // }
      if (res.id) {
        paymentsData = {
          uid: user.uid,
          amount: paymentCost,
          payment_id: res.id,
          payment_case: "MC_TRANSFER",
          mccode: microchipData.mccode.toString(),
          transfer_from_uid: user.uid,
          transfer_to_uid: ownerData.id,
          transfer_type: transfer_type,
          next_owner_name:
            ownerData.type === "1"
              ? `${ownerData.lastName} ${ownerData.firstName}`
              : `${ownerData.corporation_name}`,
        };
      }
    });

    //write paymentsData in firestore /payments
    await addADoc("payments", paymentsData);

    //add mc in owner list
    await addADoc(`users/${ownerData.id}/microchip`, ownersData).then(
      async (res) => {
        firebaseIncrement("users", ownerData.id, "totalMC", 1);

        //update relation to TRANSFER in breeder list
        await firebaseSetDoc(
          `users/${user.uid}/microchip`,
          {
            relation: "TRANSFERING",
            transferAt: new Date(),
            nextTransferDetails: {
              nextOwnerId: ownerData.id,
              nextMCId: res,
              nextOwnerName:
                ownerData.type === "1"
                  ? `${ownerData.lastName} ${ownerData.firstName}`
                  : `${ownerData.corporation_name}`,
              nextOwnerGroup: ownerData.group,
              transferAt: new Date(),
            },
            sellingPrice: mcDataAnimalTransfer.price,
            paymentsData: arrayUnion({ ...paymentsData }),
            sellingContractData: {
              ...sellingContractData,
              signature: downloadLink,
            },
            sellPlatform: false,
          },
          microchipData.id,
          true
        ).then(() => {
          firebaseIncrementMany("users", user.uid, {
            transferMNY: increment(paymentCost),
          });
        });

        //change Current owner in microchip collection
        await firebaseSetDoc(
          "microchip",
          {
            transferToUID: ownerData.id,
            transferMCData: mcDataAnimalTransfer,
            transferWhiteList: {
              mccode: microchipData.mccode.toString(),
              email: ownerData.email,
              prevMcData: microchipData.mcData,
              prevOwnerId: user.uid,
              prevMCId: microchipData.id,
              nextOwnerId: ownerData.id,
              nextMCId: res,
            },
            transferStatus: "TRANSFERING",
            transferAt: new Date(),
            paymentsData: arrayUnion({ ...paymentsData }),
            sellPlatform: false,
          },
          microchipData.mccode.toString(),
          true
        );
      }
    );

    //TODO: later
    // const date = moment();
    // const yyyy = moment(date).format("YYYY");
    // const mm = moment(date).format("MM");
    // const dd = moment(date).format("DD");
    //TODO: later
    // userAnalytics(
    //   user.uid,
    //   JSON.parse(localStorage.getItem("mcData")),
    //   yyyy,
    //   mm,
    //   dd,
    //   "T",
    //   paymentCost
    // );
    //TODO:delete
    // localStorage.removeItem("mcData");
    // // localStorage.removeItem("userInfo");
    // localStorage.removeItem("mccode");
    // localStorage.removeItem("mcDataAnimalTransfer");

    //save update trigger
    const updateList = JSON.parse(localStorage.getItem("MY_DATA_1_Up")) || [];
    updateList.push(microchipData.mccode);
    localStorage.setItem("MY_DATA_1_Up", JSON.stringify(updateList));

    //navigate
    await deleteDocument("temp_otp", user.uid).then(() => {
      navigate("/mypage/transfercomplete");
    });
  };


  useEffect(() => {

    if(isPending || isPending2 || isPending4){
      setIsCustomPending(true);
    }

    if(error || error2 || error4){
      setIsCustomPending(false);
    }
    
  }, [isPending, isPending2, isPending4, error, error2, error4]);

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={
              userData.plan === "LITE"
                ? [
                    { value: "スキャン", link: "/mypage/transferstart" },
                    { value: "メール", link: "#" },
                    { value: "OTP", link: "#" },
                    { value: "入力", link: "#" },
                    { value: "確認", link: "#" },
                    { value: "支払", link: "#" },
                    { value: "終了", link: "#" },
                  ]
                : [
                    { value: "スキャン", link: "/mypage/transferstart" },
                    { value: "メール", link: "#" },
                    { value: "OTP", link: "#" },
                    { value: "入力", link: "#" },
                    { value: "確認", link: "#" },
                    { value: "販売契約書", link: "#" },
                    { value: "支払", link: "#" },
                    { value: "終了", link: "#" },
                  ]
            }
            activeNo={userData.plan === "LITE" ? 6 : 7}
          />
        </Row>
        <Row className="justify-content-center">
          {error && <p className="text-danger">{error}</p>}
          {error2 && <p className="text-danger">{error2}</p>}
          {/* <h1 className="text-center my-5">円</h1> */}
          <br />
          <br />
          <Col lg={5} md={6} className="my-4">
            <Card className="bg-light" border="light">
              <Card.Body>
                <Table borderless>
                  <tbody className="">
                    <tr>
                      <td className="text-end bg-light">
                        マイクロチップ登録料
                      </td>
                      <td className="text-end bg-light">￥300</td>
                    </tr>
                    <tr>
                      <td className="text-end bg-light">瑕疵保証料</td>
                      <td className="text-end bg-light">￥500</td>
                    </tr>
                    <tr>
                      <td className="text-end bg-light">代行手数料</td>
                      <td className="text-end bg-light">￥150</td>
                    </tr>

                    <tr>
                      <td className="text-end bg-light">集金事務費</td>
                      <td className="text-end bg-light">￥50</td>
                    </tr>

                    {userData.planWithAid && (
                      <tr>
                        <td className="text-end bg-light">瑕疵保証互助制度</td>
                        <td className="text-end bg-light">￥11,000</td>
                      </tr>
                    )}

                    <tr>
                      <td className="text-end bg-light border-top fw-bold fs-5">
                        合計金額 <small>（税込）</small>
                      </td>
                      <td className="text-end bg-light border-top fw-bold fs-5">
                        ￥{paymentCost.toLocaleString()}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {komoju_customer_id && !changeCardDiv && (
            <Col lg={5} md={6}>
              <Card>
                <Card.Header>
                  <h5 className=" mb-0 text-center">支払い方法</h5>
                </Card.Header>
                <Card.Body>
                  <CardDiv komoju_customer_id={komoju_customer_id} />
                  <div className="text-center">
                    <Button
                      className="mt-3"
                      variant="warning"
                      onClick={() => setChangeCardDiv(!changeCardDiv)}>
                      編集 <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )}

          {(!komoju_customer_id || changeCardDiv) && (
            <>
              <CardRegistration
                komoju_customer_id={komoju_customer_id}
                returnUrl={null}
                isFinish={(status) => setChangeCardDiv(!status)}
              />
              {changeCardDiv && (
                <div className="text-center">
                  <Button
                    className="mt-3"
                    variant="danger"
                    onClick={() => setChangeCardDiv(!changeCardDiv)}>
                    <FontAwesomeIcon icon={faTimesCircle} /> 取り消し
                  </Button>
                </div>
              )}
            </>
          )}
          <div className="text-center mt-4">
            <Link
              className="btn btn-secondary m-2"
              to={
                userData.plan === "LITE"
                  ? "/mypage/transferconfirm"
                  : "/mypage/SellingContract"
              }>
              <FontAwesomeIcon icon={faCircleChevronLeft} /> 戻る
            </Link>
            {komoju_customer_id && !isCustomPending && (
              <Button
                className="btn btn-primary m-2"
                onClick={mcregistrationHandler}>
                お支払 <FontAwesomeIcon icon={faCircleChevronRight} />
              </Button>
            )}

            {(!komoju_customer_id || isCustomPending) && (
              <Button className="btn btn-primary m-2" disabled>
                お支払 <FontAwesomeIcon icon={faCircleChevronRight} />{" "}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            )}

            {(error || error2 || error4) && (
              <Alert variant="danger" className="text-center">
                {error || error2 || error4}
              </Alert>
            )}
          </div>
        </Row>
      </Container>
    </>
  );
}

export default TransferPay;
