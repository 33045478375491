import React, { Fragment } from "react";

import { Link } from "react-router-dom";
import TinySegmenter from "tiny-segmenter";

import styles from "./Plans.module.css";

export default function PlanItem({ planData }) {
  var segmenter = new TinySegmenter();

  return (
    <>
      {planData && (
        <div className={`${styles.plan} shadow rounded border`}>
          <div className={`${styles.planHeader} text-center p-3`}>
            <h4>{planData.name}</h4>
            <p>
              <span className="fs-2">¥</span>
              <span className="fs-1">
                <strong>{parseInt(planData.monthly).toLocaleString()}</strong>
              </span>
              <span className="fs-4">/月</span>
            </p>
            <Link
              to={`/mypage/plan/${planData.linkid}`}
              className="btn btn-secondary"
            >
              選択する
            </Link>
          </div>
          <div className={`${styles.planBody} p-3`}>
            <ul className="fs-6">
              {planData.details &&
                planData.details.map((item, i) => (
                  <Fragment key={i}>
                    <li>
                      <strong>
                        {segmenter.segment(item.main).map((e, i2) => (
                          <span className="text-nowrap" key={i2}>
                            {e}
                          </span>
                        ))}
                      </strong>
                      {item.sub ? (
                        <>
                          <br />
                          {segmenter.segment(item.sub).map((e, i3) => (
                            <span className="text-nowrap" key={i3}>
                              {e}
                            </span>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                    </li>
                    {planData.details.length - 1 !== i && <hr />}
                  </Fragment>
                ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}
