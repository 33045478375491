import React, { useState } from "react";
import {
  Accordion,
  Badge,
  Button,
  Col,
  Container,
  Form,
  Row,
  useAccordionButton
} from "react-bootstrap";
import { Formik } from "formik";

import ProgressStep from "../../components/ProgressStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useUserDetailsContext } from "../../context/UserDetailsContext";
import _ from "lodash";
import MCInputVet from "./MCInputVet";
import MCInputAnimal from "./MCInputAnimal";
import MCInputOwner from "./MCInputOwner";
import MCInputOwnerDetails from "./MCInputOwnerDetails";
import MCInputAnimalMother from "./MCInputAnimalMother";
import MCInputSchema from "./MCInputSchema";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MCInputSchemaAnimal from "./MCInputSchemaAnimal";

export default function MCInput() {
  const navigate = useNavigate();
  const { userData } = useUserDetailsContext();
  const { width } = useWindowDimensions();

  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("mccode")) || false
  );

  const [mcinstall, setMcinstall] = useState(
    JSON.parse(localStorage.getItem("mcinstal")) || false
  );

  const [unregistered, setUnregistered] = useState(
    localStorage.getItem("unregistered") || false
  );

  const [animalID, setanimalID] = useState(
    localStorage.getItem("animalID") || false
  );

  const [vetLocationZipError, setVetLocationZipError] = useState(true);
  const [ownerLocationZipError, setOwnerLocationZipError] = useState(true);
  const [animalLocationZipError, setAnimalLocationZipError] = useState(true);

  const [sectionError1, setSectionError1] = useState(false);
  const [sectionError2, setSectionError2] = useState(false);
  const [sectionError3, setSectionError3] = useState(false);
  const [sectionError4, setSectionError4] = useState(false);
  const [sectionError5, setSectionError5] = useState(false);

  let initialValues = unregistered
    ? {
        animalID: animalID,
        animal_name: "",
        animal_group: "",
        animal_gender: "",
        animal_breed: "",
        animal_breed_other: "",
        animal_color: "",
        animal_color_other: "",
        animal_birthDay: "",
        animalAddressCheck: [],
        animal_location_zip3: "",
        animal_location_zip4: "",
        animal_location_prefecture: "",
        animal_location_addressLine1: "",
        animal_location_addressLine2: "",
        animal_location_addressLine3: ""
      }
    : {
        mcdate: "",
        vet_institute: "",
        vet_lastName: "",
        vet_firstName: "",
        vet_location_zip3: "",
        vet_location_zip4: "",
        vet_location_prefecture: "",
        vet_location_addressLine1: "",
        vet_location_addressLine2: "",
        vet_location_addressLine3: "",
        vet_phone: "",
        certificateDownloadLink: "",

        ownership: "2",
        owner_type: userData.type,
        lastName: userData.type === "1" ? userData.lastName : "",
        firstName: userData.type === "1" ? userData.firstName : "",
        lastKana: userData.type === "1" ? userData.lastKana : "",
        firstKana: userData.type === "1" ? userData.firstKana : "",
        corporation_name:
          userData.type === "2" ? userData.corporation_name : "",
        corporation_kana_name:
          userData.type === "2" ? userData.corporation_kana_name : "",
        corporation_representative_firstName:
          userData.type === "2"
            ? userData.corporation_representative_firstName
            : "",
        corporation_representative_lastName:
          userData.type === "2"
            ? userData.corporation_representative_lastName
            : "",
        owner_location_zip3: userData.zip3,
        owner_location_zip4: userData.zip4,
        owner_location_prefecture: userData.prefecture,
        owner_location_addressLine1: userData.addressLine1,
        owner_location_addressLine2: userData.addressLine2,
        owner_location_addressLine3: userData.addressLine3,
        owner_fixed_phone: userData.fixed_phone,
        owner_phone: userData.phone,
        owner_email: userData.email,

        mccode: data.mccode,
        animalID: animalID,
        animal_name: "",
        animal_group: "",
        animal_gender: "",
        animal_breed: "",
        animal_breed_other: "",
        animal_color: "",
        animal_color_other: "",
        animal_birthDay: "",
        animalAddressCheck: [],
        animal_location_zip3: "",
        animal_location_zip4: "",
        animal_location_prefecture: "",
        animal_location_addressLine1: "",
        animal_location_addressLine2: "",
        animal_location_addressLine3: "",
        animal_note: "",
        animal_handler_business_type: userData.animal_handler_business_type,
        animal_handle_1: [...userData.animal_handle_1],
        animal_handle_2: [...userData.animal_handle_2],
        animal_handler_business_type1_slot1:
          userData.animal_handler_business_type1_slot1
            ? userData.animal_handler_business_type1_slot1
            : "",
        animal_handler_business_type1_slot2:
          userData.animal_handler_business_type1_slot2
            ? userData.animal_handler_business_type1_slot2
            : "",
        animal_handler_business_type1_slot3:
          userData.animal_handler_business_type1_slot3
            ? userData.animal_handler_business_type1_slot3
            : "",
        animal_handler_business_type1_slot4:
          userData.animal_handler_business_type1_slot4
            ? userData.animal_handler_business_type1_slot4
            : "",
        animal_handler_business_type1_slot5:
          userData.animal_handler_business_type1_slot5
            ? userData.animal_handler_business_type1_slot5
            : "",
        animal_handler_business_type1_slot6:
          userData.animal_handler_business_type1_slot6
            ? userData.animal_handler_business_type1_slot6
            : "",
        animal_handler_business_type1_slot7:
          userData.animal_handler_business_type1_slot7
            ? userData.animal_handler_business_type1_slot7
            : "",
        animal_mother_time: "",
        animal_mother_mccode: "",
        animal_mother_mccode_not_reason: ""
      };

  const backData = localStorage.getItem("mcData");
  if (backData) {
    initialValues = {
      ...initialValues,
      ...JSON.parse(localStorage.getItem("mcData")),
      mccode: data.mccode
    };
  }
  const { schema } = unregistered
    ? MCInputSchemaAnimal(animalLocationZipError)
    : MCInputSchema(
        vetLocationZipError,
        ownerLocationZipError,
        animalLocationZipError
      );

  const handleSubmit = (values) => {
    try {
      const serializedState = JSON.stringify(values);
      localStorage.setItem("mcData", serializedState);
      navigate("/mypage/mcinputcheck");
    } catch (err) {
      return undefined;
    }
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {});

    return (
      <div className="text-center mt-4">
        <Button
          className="mt-1"
          variant="primary"
          type="button"
          onClick={decoratedOnClick}
        >
          {children}
        </Button>
      </div>
    );
  }

  const SectionErrorMgs = () => {
    return (
      <Badge className="ms-2" bg="danger">
        エラー
      </Badge>
    );
  };

  return (
    <>
      <Container className="mt-3">
        <Row className="justify-content-center mb-4">
          <ProgressStep
            props={[
              { value: "スキャン", link: "#" },
              { value: "入力", link: "#" },
              { value: "確認", link: "#" },
              { value: "終了", link: "#" }
            ]}
            activeNo={2}
          />
        </Row>
        <Row className="justify-content-center mb-4">
          <Col lg={10} md={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, handleSubmit }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Accordion defaultActiveKey={!unregistered ? "1" : "2"}>
                    {!unregistered && (
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          1. マイクロチップ装着証明書の入力{" "}
                          {sectionError1 && (
                            <>
                              <SectionErrorMgs />
                            </>
                          )}
                        </Accordion.Header>
                        <Accordion.Body>
                          {!mcinstall && !mcinstall.status && (
                            <MCInputVet
                              setVetLocationZipError={setVetLocationZipError}
                              sectionError={setSectionError1}
                            />
                          )}

                          {mcinstall && mcinstall.status && (
                            <>
                              <Row className="px-lg-3">
                                <Col
                                  lg={3}
                                  className={`bg-secondary bg-opacity-10 ${
                                    width < 576 && `border`
                                  } ${
                                    width >= 576 &&
                                    `border-bottom border-top border-start `
                                  } border-secondary p-2`}
                                >
                                  MCの装着日
                                </Col>
                                <Col
                                  lg={9}
                                  className={`${
                                    width < 576 && `border-start border-end`
                                  } ${
                                    width >= 576 &&
                                    `border-top border-start border-bottom border-end`
                                  } p-1 border-end border-secondary`}
                                >
                                  {values.mcdate} <br />
                                  {touched.animal_birthDay && errors.mcdate && (
                                    <small className="text-danger">
                                      {errors.mcdate}
                                    </small>
                                  )}
                                </Col>
                              </Row>
                              <Row className="px-lg-3">
                                <Col
                                  lg={3}
                                  className={`bg-secondary bg-opacity-10 ${
                                    width < 576 && `border`
                                  } ${
                                    width >= 576 &&
                                    `border-bottom border-start `
                                  } border-secondary p-2`}
                                >
                                  MCを装着した施設名
                                </Col>
                                <Col
                                  lg={9}
                                  className={`${
                                    width < 576 &&
                                    `border-start border-end border-bottom`
                                  } ${
                                    width >= 576 &&
                                    `border-start border-bottom border-end`
                                  } p-1 border-secondary`}
                                >
                                  {values.vet_institute}
                                </Col>
                              </Row>

                              <Row className="px-lg-3">
                                <Col
                                  lg={3}
                                  className={`bg-secondary bg-opacity-10 ${
                                    width < 576 && `border`
                                  } ${
                                    width >= 576 &&
                                    `border-bottom border-start `
                                  } border-secondary p-2`}
                                >
                                  MCを装着した施設の
                                  <span className="text-nowrap">所在地</span>
                                </Col>
                                <Col
                                  lg={9}
                                  className={`${
                                    width < 576 && `border-start border-end`
                                  } ${
                                    width >= 576 &&
                                    `border-start border-bottom border-end`
                                  } p-1 border-secondary`}
                                >
                                  <>
                                    <p className="mb-0">
                                      〒{values.vet_location_zip3}-
                                      {values.vet_location_zip4}
                                    </p>
                                    <p className="mb-0">
                                      {values.vet_location_prefecture}{" "}
                                      {values.vet_location_addressLine1}
                                    </p>
                                    <p className="mb-0">
                                      {values.vet_location_addressLine2}
                                    </p>
                                    <p className="mb-0">
                                      {values.vet_location_addressLine3}
                                    </p>
                                  </>
                                </Col>
                              </Row>

                              <Row className="px-lg-3">
                                <Col
                                  lg={3}
                                  className={`bg-secondary bg-opacity-10 ${
                                    width < 576 && `border`
                                  } ${
                                    width >= 576 &&
                                    `border-bottom border-start `
                                  } border-secondary p-2`}
                                >
                                  MCを装着した施設の
                                  <span className="text-nowrap">電話番号</span>
                                </Col>
                                <Col
                                  lg={9}
                                  className={`${
                                    width < 576 &&
                                    `border-start border-end border-bottom`
                                  } ${
                                    width >= 576 &&
                                    `border-start border-bottom border-end`
                                  } p-1 border-secondary`}
                                >
                                  {values.vet_phone}
                                </Col>
                              </Row>

                              <Row className="px-lg-3">
                                <Col
                                  lg={3}
                                  className={`bg-secondary bg-opacity-10 ${
                                    width < 576 && `border`
                                  } ${
                                    width >= 576 &&
                                    `border-bottom border-start `
                                  } border-secondary p-2`}
                                >
                                  獣医師の氏名
                                </Col>
                                <Col
                                  lg={9}
                                  className={`${
                                    width < 576 &&
                                    `border-start border-end border-bottom`
                                  } ${
                                    width >= 576 &&
                                    `border-start border-bottom border-end`
                                  } p-1 border-secondary`}
                                >
                                  {values.vet_lastName} {values.vet_firstName}
                                </Col>
                              </Row>

                              <Row className="px-lg-3">
                                <Col
                                  lg={3}
                                  className={`bg-secondary bg-opacity-10 ${
                                    width < 576 && `border`
                                  } ${
                                    width >= 576 &&
                                    `border-bottom border-start `
                                  } border-secondary p-2`}
                                >
                                  装着証明書
                                </Col>
                                <Col
                                  lg={9}
                                  className={`${
                                    width < 576 &&
                                    `border-start border-end border-bottom`
                                  } ${
                                    width >= 576 &&
                                    `border-start border-bottom border-end`
                                  } p-1 border-secondary`}
                                >
                                  <Row className="justify-content-center mb-4">
                                    <Col lg={3} md={4}>
                                      <a
                                        href={values.certificateDownloadLink}
                                        target="_blank"
                                      >
                                        <img
                                          src={values.certificateDownloadLink}
                                          alt=""
                                          className="w-100"
                                        />
                                      </a>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </>
                          )}
                          <CustomToggle eventKey="2">
                            <b>
                              次へ{" "}
                              <FontAwesomeIcon icon={faCircleChevronRight} />
                            </b>
                          </CustomToggle>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        {!unregistered && "2. "}犬・猫の情報入力{" "}
                        {sectionError2 && (
                          <>
                            <SectionErrorMgs />
                          </>
                        )}
                      </Accordion.Header>
                      <Accordion.Body>
                        <MCInputAnimal
                          setAnimalLocationZipError={setAnimalLocationZipError}
                          sectionError={setSectionError2}
                          unregistered={unregistered}
                        />

                        {!unregistered ? (
                          <CustomToggle eventKey="3">
                            <b>
                              次へ{" "}
                              <FontAwesomeIcon icon={faCircleChevronRight} />
                            </b>
                          </CustomToggle>
                        ) : (
                          <div className="text-center mt-4">
                            <Button
                              className="mt-1"
                              variant="primary"
                              type="submit"
                            >
                              <b>
                                次へ{" "}
                                <FontAwesomeIcon icon={faCircleChevronRight} />
                              </b>
                            </Button>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                    {!unregistered && (
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          3. 所有者情報の入力{" "}
                          {sectionError3 && (
                            <>
                              <SectionErrorMgs />
                            </>
                          )}
                        </Accordion.Header>
                        <Accordion.Body>
                          <MCInputOwner
                            setOwnerLocationZipError={setOwnerLocationZipError}
                            sectionError={setSectionError3}
                          />

                          {values.ownership === "2" ? (
                            <CustomToggle eventKey="4">
                              <b>
                                次へ{" "}
                                <FontAwesomeIcon icon={faCircleChevronRight} />
                              </b>
                            </CustomToggle>
                          ) : (
                            <div className="text-center mt-4">
                              <Button
                                className="mt-1"
                                variant="primary"
                                type="submit"
                              >
                                <b>
                                  次へ{" "}
                                  <FontAwesomeIcon
                                    icon={faCircleChevronRight}
                                  />
                                </b>
                              </Button>
                            </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                    {!unregistered && values.ownership === "2" && (
                      <>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>
                            4. 動物取扱業関連情報の入力{" "}
                            {sectionError4 && (
                              <>
                                <SectionErrorMgs />
                              </>
                            )}
                          </Accordion.Header>
                          <Accordion.Body>
                            <MCInputOwnerDetails
                              sectionError={setSectionError4}
                            />
                            {values.animal_handler_business_type === "1" &&
                            _.includes(values.animal_handle_1, "1") ? (
                              <CustomToggle eventKey="5">
                                <b>
                                  次へ{" "}
                                  <FontAwesomeIcon
                                    icon={faCircleChevronRight}
                                  />
                                </b>
                              </CustomToggle>
                            ) : (
                              <div className="text-center mt-4">
                                <Button
                                  className="mt-1"
                                  variant="primary"
                                  type="submit"
                                >
                                  <b>
                                    次へ{" "}
                                    <FontAwesomeIcon
                                      icon={faCircleChevronRight}
                                    />
                                  </b>
                                </Button>
                              </div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                        {values.animal_handler_business_type === "1" &&
                          _.includes(values.animal_handle_1, "1") && (
                            <>
                              <Accordion.Item eventKey="5">
                                <Accordion.Header>
                                  5. メス親情報の入力{" "}
                                  {sectionError5 && (
                                    <>
                                      <SectionErrorMgs />
                                    </>
                                  )}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <MCInputAnimalMother
                                    sectionError={setSectionError5}
                                  />

                                  <div className="text-center mt-4">
                                    <Button
                                      className="mt-1"
                                      variant="primary"
                                      type="submit"
                                    >
                                      <b>
                                        次へ{" "}
                                        <FontAwesomeIcon
                                          icon={faCircleChevronRight}
                                        />
                                      </b>
                                    </Button>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </>
                          )}
                      </>
                    )}
                  </Accordion>
                  {/* <pre>{JSON.stringify(errors, null, 2)}</pre>
                  <pre>{JSON.stringify(values, null, 2)}</pre> */}
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
}
