import React, { useEffect, useState } from "react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import _ from "lodash";

import Select from "react-select";
import AddressInput from "../../components/AddressInput";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  dog_breed,
  cat_breed,
  dog_color,
  cat_color
} from "../../utils/CatDogData";
import { useUserDetailsContext } from "../../context/UserDetailsContext";

function MCInputAnimal({
  setAnimalLocationZipError,
  sectionError,
  PIN = false,
  transfer = false,
  cerPDF = false,
  unregistered = false
}) {
  const { width } = useWindowDimensions();
  const { userData } = useUserDetailsContext();

  const [breed, setBreed] = useState(null);
  const [color, setColor] = useState(null);

  const {
    initialValues,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched
  } = useFormikContext();

  const [valiable, setValiable] = useState(initialValues.animal_group);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      boxShadow: "none"
    }),
    menu: (provided) => ({ ...provided, zIndex: 3 })
  };

  const FormikField = () => {
    useEffect(() => {
      if (values.animal_group === "犬") {
        setBreed(dog_breed);
        setColor(dog_color);
      } else if (values.animal_group === "猫") {
        setBreed(cat_breed);
        setColor(cat_color);
      }

      if (valiable !== values.animal_group) {
        setValiable(values.animal_group);
        setFieldValue("animal_breed", "");
        setFieldValue("animal_color", "");
      }
    }, [values.animal_group]);

    return <></>;
  };

  useEffect(() => {
    if (
      (touched.mccode && errors.mccode) ||
      (touched.animal_name && errors.animal_name) ||
      (touched.animal_group && errors.animal_group) ||
      (touched.animal_gender && errors.animal_gender) ||
      (touched.animal_birthDay && errors.animal_birthDay) ||
      (touched.animal_note && errors.animal_note) ||
      (touched.animal_breed && errors.animal_breed) ||
      (touched.animal_breed_other && errors.animal_breed_other) ||
      (touched.animal_color && errors.animal_color) ||
      (touched.animal_color_other && errors.animal_color_other) ||
      (touched.dog_registration_date && errors.dog_registration_date) ||
      (touched.dog_registration_number && errors.dog_registration_number) ||
      (touched.animal_location_zip3 && errors.animal_location_zip3) ||
      (touched.animal_location_zip4 && errors.animal_location_zip4) ||
      (touched.animal_location_prefecture &&
        errors.animal_location_prefecture) ||
      (touched.animal_location_addressLine1 &&
        errors.animal_location_addressLine1) ||
      (touched.animal_location_addressLine2 &&
        errors.animal_location_addressLine2) ||
      (touched.animal_location_addressLine3 &&
        errors.animal_location_addressLine3) ||
      (PIN && touched.overseas && errors.overseas) ||
      (cerPDF && touched.PIN && errors.PIN)
    ) {
      sectionError(true);
    } else {
      sectionError(false);
    }
  }, [errors, touched]);

  return (
    <>
      <FormikField />
      {!unregistered && (
        <Row className="px-lg-3">
          <Col
            lg={3}
            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
              width >= 576 && `border-bottom border-top border-start `
            } border-secondary p-2`}
          >
            MC番号<span className="text-danger">※</span>
          </Col>
          <Col
            lg={9}
            className={`${width < 576 && `border-start border-end`} ${
              width >= 576 && `border-top border-start border-bottom border-end`
            } p-1 border-end border-secondary`}
          >
            {PIN ? (
              <p className="mb-0 p-1">{values.mccode}</p>
            ) : (
              <Form.Group>
                <Form.Control
                  type="number"
                  placeholder="半角"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mccode}
                  name="mccode"
                  required
                  readOnly={!cerPDF}
                  isInvalid={touched.mccode && errors.mccode}
                  isValid={touched.mccode && !errors.mccode}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.mccode}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Col>
        </Row>
      )}
      {cerPDF && (
        <Row className="px-lg-3">
          <Col
            lg={3}
            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
              width >= 576 && `border-bottom border-start `
            } border-secondary p-2`}
          >
            暗証記号<span className="text-danger">※</span>
          </Col>
          <Col
            lg={9}
            className={`${width < 576 && `border-start border-end`} ${
              width >= 576 && `border-start border-bottom border-end`
            } p-1 border-secondary`}
          >
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="半角"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.PIN}
                name="PIN"
                maxLength={160}
                required
                isInvalid={touched.PIN && errors.PIN}
                isValid={touched.PIN && !errors.PIN}
              />
              <Form.Control.Feedback type="invalid">
                {errors.PIN}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      )}

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 &&
            `border-bottom border-start ${unregistered && `border-top`} `
          } border-secondary p-2`}
        >
          ペットID<span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 &&
            `border-start border-bottom border-end ${
              unregistered && `border-top`
            }`
          } p-1 border-secondary`}
        >
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="半角"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.animalID}
              name="animalID"
              maxLength={160}
              required
              readOnly={true}
              isInvalid={touched.animalID && errors.animalID}
              isValid={touched.animalID && !errors.animalID}
            />
            <Form.Control.Feedback type="invalid">
              {errors.animalID}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          名前<span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="半角"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.animal_name}
              name="animal_name"
              maxLength={160}
              required
              isInvalid={touched.animal_name && errors.animal_name}
              isValid={touched.animal_name && !errors.animal_name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.animal_name}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          犬or猫<span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 py-2 border-secondary`}
        >
          {PIN ? (
            <p className="mb-0 p-1">
              {values.animal_group === "犬" ? "犬" : "猫"}
            </p>
          ) : (
            <>
              <Form.Check
                inline
                id="animal_group1"
                name="animal_group"
                label="犬"
                type="radio"
                value="犬"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.animal_group === "犬" ? true : false}
                isInvalid={touched.animal_group && errors.animal_group}
                isValid={touched.animal_group && !errors.animal_group}
              />
              <Form.Check
                inline
                id="animal_group2"
                name="animal_group"
                label="猫"
                type="radio"
                value="猫"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.animal_group === "猫" ? true : false}
                isInvalid={touched.animal_group && errors.animal_group}
                isValid={touched.animal_group && !errors.animal_group}
              />
              {touched.animal_group && errors.animal_group && (
                <div className="text-danger">{errors.animal_group}</div>
              )}
            </>
          )}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          性別<span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 py-2 border-secondary`}
        >
          {PIN ? (
            <p className="mb-0 p-1">
              {values.animal_gender === "オス" ? "オス" : "メス"}
            </p>
          ) : (
            <>
              <Form.Check
                inline
                name="animal_gender"
                id="animal_gender1"
                label="オス"
                value="オス"
                type="radio"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.animal_gender === "オス" ? true : false}
                isInvalid={touched.animal_gender && errors.animal_gender}
                isValid={touched.animal_gender && !errors.animal_gender}
              />
              <Form.Check
                inline
                name="animal_gender"
                id="animal_gender2"
                label="メス"
                value="メス"
                type="radio"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.animal_gender === "メス" ? true : false}
                isInvalid={touched.animal_gender && errors.animal_gender}
                isValid={touched.animal_gender && !errors.animal_gender}
              />
              {touched.animal_gender && errors.animal_gender && (
                <div className="text-danger">{errors.animal_gender}</div>
              )}
            </>
          )}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          品種<span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <Select
            className={`form-control py-0 ps-0 ${
              touched.animal_breed ? "" : "pe-0"
            } ${
              touched.animal_breed && errors.animal_breed ? "is-invalid" : ""
            } ${
              touched.animal_breed && !errors.animal_breed ? "is-valid" : ""
            }`}
            styles={customStyles}
            isDisabled={breed === null ? true : false}
            options={breed}
            value={
              breed &&
              breed.map((item) => {
                if (item.value == values.animal_breed) {
                  return item;
                }
              })
            }
            name="animal_breed"
            onChange={(e) => {
              setFieldValue("animal_breed", e.value ? e.value : "");
            }}
            onBlur={() => {
              setFieldTouched("animal_breed", true);
            }}
            placeholder="▼選択してください"
          />

          {touched.animal_breed && errors.animal_breed && (
            <small className="text-danger">
              {errors.animal_breed}
              <br />
            </small>
          )}

          {values.animal_breed === "999" && (
            <>
              <Form.Group className="mt-1">
                <Form.Control
                  type="text"
                  placeholder="半角"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.animal_breed_other}
                  name="animal_breed_other"
                  maxLength={160}
                  required
                  isInvalid={
                    touched.animal_breed_other && errors.animal_breed_other
                  }
                  isValid={
                    touched.animal_breed_other && !errors.animal_breed_other
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.animal_breed_other}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          )}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          毛色<span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <Select
            className={`form-control py-0 ps-0 ${
              touched.animal_color ? "" : "pe-0"
            } ${
              touched.animal_color && errors.animal_color ? "is-invalid" : ""
            }  ${
              touched.animal_color && !errors.animal_color ? "is-valid" : ""
            }`}
            styles={customStyles}
            isDisabled={color === null ? true : false}
            options={color}
            value={
              color &&
              color.map((item) => {
                if (item.value == values.animal_color) {
                  return item;
                }
              })
            }
            name="animal_color"
            onChange={(e) => {
              setFieldValue("animal_color", e.value ? e.value : "");
            }}
            onBlur={() => {
              setFieldTouched("animal_color", true);
            }}
            placeholder="▼選択してください"
          />

          {touched.animal_color && errors.animal_color && (
            <small className="text-danger">
              {errors.animal_color}
              <br />
            </small>
          )}

          {values.animal_color === "99" && (
            <>
              <Form.Group className="mt-1">
                <Form.Control
                  type="text"
                  placeholder="半角"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.animal_color_other}
                  name="animal_color_other"
                  maxLength={160}
                  required
                  isInvalid={
                    touched.animal_color_other && errors.animal_color_other
                  }
                  isValid={
                    touched.animal_color_other && !errors.animal_color_other
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.animal_color_other}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          )}
        </Col>
      </Row>
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          生年月日<span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          {PIN ? (
            <p className="mb-0 p-1">{values.animal_birthDay}</p>
          ) : (
            <>
              <Form.Group>
                <Form.Control
                  type="Date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.animal_birthDay}
                  name="animal_birthDay"
                  required
                  isInvalid={touched.animal_birthDay && errors.animal_birthDay}
                  isValid={touched.animal_birthDay && !errors.animal_birthDay}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.animal_birthDay}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          )}
        </Col>
      </Row>

      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          動物の所在地<span className="text-danger">※</span>
        </Col>
        <Col
          lg={9}
          className={`${width < 576 && `border-start border-end`} ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              id="animalAddressCheck"
              type="checkbox"
              name="animalAddressCheck"
              label="所有者と同じ所在"
              checked={_.includes(values.animalAddressCheck, "on")}
              onChange={handleChange}
              onBlur={handleBlur}
              onClick={(e) => {
                if (e.target.value === "on") {
                  setAnimalLocationZipError(true);
                }
              }}
            />
          </Form.Group>
          {/* {JSON.stringify(values.animalAddressCheck)} */}
          {values.animalAddressCheck &&
            values.animalAddressCheck[0] !== "on" && (
              <AddressInput
                prefix="animal_location_"
                setZipError={setAnimalLocationZipError}
              />
            )}

          {values.animalAddressCheck &&
            values.animalAddressCheck[0] === "on" &&
            !transfer && (
              <>
                {!unregistered ? (
                  <>
                    <p className="mb-0">
                      〒{values.owner_location_zip3}-
                      {values.owner_location_zip4}
                    </p>
                    <p className="mb-0">
                      {values.owner_location_prefecture}{" "}
                      {values.owner_location_addressLine1}
                    </p>
                    <p className="mb-0">{values.owner_location_addressLine2}</p>
                    <p className="mb-0">{values.owner_location_addressLine3}</p>
                  </>
                ) : (
                  <>
                    <p className="mb-0">
                      〒{userData.zip3}-{userData.zip4}
                    </p>
                    <p className="mb-0">
                      {userData.prefecture} {userData.addressLine1}
                    </p>
                    <p className="mb-0">{userData.addressLine2}</p>
                    <p className="mb-0">{userData.addressLine3}</p>
                  </>
                )}
              </>
            )}
        </Col>
      </Row>
      {values.animal_group === "犬" && (
        <Row className="px-lg-3">
          <Col
            lg={3}
            className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
              width >= 576 && `border-bottom border-start `
            } border-secondary p-2`}
          >
            鑑札
          </Col>
          <Col
            lg={9}
            className={`${width < 576 && `border-start border-end`} ${
              width >= 576 && `border-start border-bottom border-end`
            } p-1 border-secondary`}
          >
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                id="dog_tag_check"
                type="checkbox"
                name="dog_tag_check"
                label="市町村に犬を登録している方登録"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>

            <Form.Group className="mt-1">
              <Form.Label className="m-0">
                登録年月日
                {/* <span className="text-danger">※</span> */}
              </Form.Label>
              <Form.Control
                type="date"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dog_registration_date}
                name="dog_registration_date"
                maxLength={150}
                isInvalid={
                  touched.dog_registration_date && errors.dog_registration_date
                }
                isValid={
                  touched.dog_registration_date && !errors.dog_registration_date
                }
                disabled={
                  values.dog_tag_check && values.dog_tag_check[0] === "on"
                    ? false
                    : true
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.dog_registration_date}
              </Form.Control.Feedback>

              <Form.Text id="passwordHelpBlock" muted>
                <FontAwesomeIcon icon={faInfoCircle} className="text-primary" />{" "}
                年日付がわからない場合は「4月1日」と入力してください。
              </Form.Text>
            </Form.Group>

            <Form.Group className="mt-1">
              <Form.Label className="m-0">登録番号</Form.Label>
              <Form.Control
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dog_registration_number}
                name="dog_registration_number"
                maxLength={150}
                isInvalid={
                  touched.dog_registration_number &&
                  errors.dog_registration_number
                }
                isValid={
                  touched.dog_registration_number &&
                  !errors.dog_registration_number
                }
                disabled={
                  values.dog_tag_check && values.dog_tag_check[0] === "on"
                    ? false
                    : true
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.dog_registration_number}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      )}
      <Row className="px-lg-3">
        <Col
          lg={3}
          className={`bg-secondary bg-opacity-10 ${width < 576 && `border`} ${
            width >= 576 && `border-bottom border-start `
          } border-secondary p-2`}
        >
          備考
        </Col>
        <Col
          lg={9}
          className={`${
            width < 576 && `border-start border-end border-bottom`
          } ${
            width >= 576 && `border-start border-bottom border-end`
          } p-1 border-secondary`}
        >
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={3}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.animal_note}
              name="animal_note"
              isInvalid={touched.animal_note && errors.animal_note}
              isValid={touched.animal_note && !errors.animal_note}
            />
            <Form.Control.Feedback type="invalid">
              {errors.animal_note}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      {(PIN || cerPDF) && !transfer && (
        <>
          <Row className="px-lg-3">
            <Col
              lg={3}
              className={`bg-secondary bg-opacity-10 ${
                width < 576 && `border-start border-bottom border-end`
              } ${
                width >= 576 && `border-bottom border-start `
              } border-secondary p-2`}
            >
              海外出国<span className="text-danger">※</span>
            </Col>
            <Col
              lg={9}
              className={`${
                width < 576 && `border-start border-bottom border-end`
              } ${
                width >= 576 && `border-start border-bottom border-end`
              } p-1 py-2 border-secondary`}
            >
              <Form.Check
                inline
                name="overseas"
                id="overseas1"
                label="国間移動無し／国内に帰国"
                value="1"
                type="radio"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.overseas === "1" ? true : false}
                isInvalid={touched.overseas && errors.overseas}
                isValid={touched.overseas && !errors.overseas}
              />
              <Form.Check
                inline
                name="overseas"
                id="overseas2"
                label="海外へ出国"
                value="2"
                type="radio"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.overseas === "2" ? true : false}
                isInvalid={touched.overseas && errors.overseas}
                isValid={touched.overseas && !errors.overseas}
              />
              {touched.overseas && errors.overseas && (
                <div className="text-danger">{errors.overseas}</div>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default MCInputAnimal;
