import { Timestamp } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal, Placeholder, Table } from "react-bootstrap";
import useWindowDimensions from "../hooks/useWindowDimensions";
import CardDivPaymentID from "./CardDivPaymentID";
import { usePlan } from "../hooks/usePlan";

function PaymentDetailsModal(props) {
  const { width } = useWindowDimensions();
  const [showCard, setShowCard] = useState(false);
  useEffect(() => {
    setShowCard(false);
  }, []);
  // console.log(props.mcData);
  const { planDetails, isPending: isPlanPending, planDetail } = usePlan();

  useEffect(() => {
    if (props && props.data && props.data.payment_case && props.data.payment_case === "PLAN_PAY") {
      planDetails(props.data.planid);
    }
  }, [props]);
  return (
    props.data && (
      <Modal
        show={props.show}
        onHide={() => {
          setShowCard(false);
          props.onHide();
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            支払詳細
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered>
            <tbody>
              {props.data.payment_case === "PLAN_PAY" ? (
                <tr>
                  <th>プラン</th>
                  <td>
                    {isPlanPending && (
                      <Placeholder  animation="glow">
                        <Placeholder className="rounded" xs={6} />
                      </Placeholder>
                    )}
                    {!isPlanPending && planDetail && (
                      <span className="fw-bold">{planDetail.name}</span>
                    )}
                  </td>
                </tr>
              ) : (
                <tr>
                  <th>MC番号</th>
                  <td>
                    <Button
                      variant="link"
                      onClick={() => {
                        setShowCard(false);
                        props.onHide();
                        props.modalFun(props.mcData, props.data.mccode);
                      }}
                    >
                      {props.data.mccode}
                    </Button>
                  </td>
                </tr>
              )}
              {props.data.createdAt && (
                <tr>
                  <th>支払日時</th>
                  <td>
                    {moment(
                      new Timestamp(
                        props.data.createdAt.seconds,
                        props.data.createdAt.nanoseconds
                      ).toDate()
                    ).format("YYYY/MM/DD HH:mm")}
                  </td>
                </tr>
              )}

              <tr>
                <th>金額</th>
                <td>{parseInt(props.data.amount).toLocaleString()}円</td>
              </tr>
              {width >= 576 && (
                <tr className="d-none d-sm-table-row">
                  <th>支払い方法</th>
                  <td>
                    <div style={{ margin: "0" }}></div>
                    {!showCard && (
                      <Button
                        variant="secondary"
                        onClick={() => setShowCard(true)}
                      >
                        カード情報を表示する
                      </Button>
                    )}
                    {showCard && (
                      <CardDivPaymentID payment_id={props.data.payment_id} />
                    )}
                  </td>
                </tr>
              )}
              {width < 576 && (
                <>
                  <tr>
                    <th className="text-center" colSpan={2}>
                      支払い方法
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={2} className="text-center">
                      {!showCard && (
                        <Button
                          variant="secondary"
                          onClick={() => setShowCard(true)}
                        >
                          カード情報を表示する
                        </Button>
                      )}
                      {showCard && (
                        <CardDivPaymentID payment_id={props.data.payment_id} />
                      )}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
          {/* <pre>{JSON.stringify(props.data, null, 2)}</pre> */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowCard(false);
              props.onHide();
            }}
          >
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    )
  );
}

export default PaymentDetailsModal;
